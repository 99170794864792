import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "./bluejay-ui";
import { apps, contexts } from "neoco/backoffice";
import theme from "utils/theme";
import { headers } from "pages";
import reportWebVitals from "./reportWebVitals";
import "languages/i18next";
import "./Global.css";
import "alertifyjs/build/css/alertify.css";
import "alertifyjs/build/css/themes/semantic.min.css";

const { App } = apps;
const { AuthProvider } = contexts;

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider
      sidebarProps={{
        permanent: true,
        defaultOpen: true,
        style: { minWidth: 250 },
      }}
    >
      <ThemeProvider theme={theme}>
        <App headers={headers} />
      </ThemeProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
