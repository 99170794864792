import React from "react";
import DayPicker from "react-day-picker";

const MONTHS = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Marzo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

const WEEKDAYS_LONG = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
];

const WEEKDAYS_SHORT = ["Do", "Lu", "Ma", "Mie", "Jue", "Vie", "Sa"];

const Calendar = ({ onChange, value = new Date(), ...props }) => {
  const modifiers = {
    highlighted: value,
    ...props.modifiers,
  };

  return (
    <DayPicker
      locale="es"
      months={MONTHS}
      weekdaysLong={WEEKDAYS_LONG}
      weekdaysShort={WEEKDAYS_SHORT}
      firstDayOfWeek={1}
      onDayClick={onChange}
      modifiers={modifiers}
      selectedDays={value}
      {...props}
    />
  );
};

export default Calendar;
