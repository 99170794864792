import { zip } from "lodash";
import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 20px;
  padding-top: 10px;
`;

const ImageContainer = styled.div`
  position: relative;
  min-height: 180px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  min-height: 180px;
`;

const HoverContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${({ hasImage = false }) =>
    hasImage ? "transparent" : "#f0f0f0"};

  &:hover .placeholder {
    display: flex;
  }
`;

const ImagePlaceholder = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  color: #fff;
  background: hsla(0, 0%, 55%, 0.55);
  user-select: none;
  cursor: pointer;
  letter-spacing: 1.4px;
`;


const getSrcLibrary = (source) => {
  if (source && source.uri) {
    return source.uri;
  }
  return null;
};

const getName = (source) => {
  if(typeof source === "string") {
    var splitimage = source.split("\/");
    return splitimage[splitimage.length-1];
  } else {
    var x = [];
    for (const key in source) {
        x.push(getName(source[key]))
    }
    return x;
  }
};

export default function Imagelibrary({
  onChange = () => {},
  canEdit = true,
  editTitle = "CAMBIAR IMAGEN",
  source = {},
  containerStyle = {},
  imageFit = "cover",
  setDataOpen = () => {},
  multiple=false
}) {


  const [isEditing, setIsEditing] = useState(false);
  const [nextImage, setNextImage] = useState(null);

  const image = nextImage || {
    src: getSrcLibrary(source),
  };

  const callback = (data) => {
    setNextImage({src:data})
    onChange(getName(data));
  }


  const hasImage = !!image.src;

  const onClickImage = (e) => {
    setDataOpen({
      callback: callback,
      value: hasImage?image.src:"",
      meta: "No se  que es",
      open:true,
      multiple:multiple
    })
  }


    return (
      <Container style={containerStyle}>
        <ImageContainer style={containerStyle}>
          {canEdit && (
            <HoverContainer
              hasImage={hasImage}
              onClick={onClickImage}
            >
              <ImagePlaceholder className="placeholder">
                {editTitle}
              </ImagePlaceholder>
            </HoverContainer>
          )}
          {hasImage && (
            <Image src={image.src} style={{ objectFit: imageFit }} />
          )}
        </ImageContainer>
      </Container>

    )
};
