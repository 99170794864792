import React from "react";
import styled from "styled-components";
import { getRecalculatedWidth } from "../utils";
import { defaultTheme, themable } from "../Theme";
import Label from "../Label";
import { ReactComponent as RadioButtonChecked } from "../utils/images/radioButtonChecked.svg";
import { ReactComponent as RadioButtonUnchecked } from "../utils/images/radioButtonUnchecked.svg";

const Container = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  cursor: pointer;
`;

const RadioButtonInput = styled.input`
  top: 0;
  left: 0;
  width: 100%;
  cursor: inherit;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  z-index: 1;
  position: absolute;
`;

const RadioButtonSVG = styled.svg`
  ${({ theme, isChecked, disabled, color }) => {
    const state = disabled ? "disabled" : "active";
    const checkedProps = isChecked
      ? {
          height: getRecalculatedWidth({
            value: themable({
              theme,
              props: ["input.radio.width", "16px"],
            }),
            operation: "sum",
            increment: 1,
          }),
        }
      : {
          height: themable({
            theme,
            props: ["input.radio.width", "15px"],
          }),
        };
    return ` 
  height: ${checkedProps.height}; 
  background-color: ${themable({
    theme,
    props: [`input.${state}.backgroundColor`, "secondary"],
  })}; 
  
  path {
    fill: ${themable({
      theme,
      props: [color, `input.${state}.borderColor`, "default"],
    })};
  }
  `;
  }}

  display: inline-block;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
`;

RadioButtonSVG.defaultProps = {
  theme: defaultTheme,
};

const RadioButton = ({
  label,
  Label: CustomLabel = Label,
  name,
  style,
  color,
  disabled,
  ...props
}) => {
  const isChecked = props.checked;

  return (
    <Container style={style}>
      <RadioButtonInput type="radio" id={name} name={name} {...props} />
      <RadioButtonSVG
        focusable="false"
        aria-hidden="true"
        onClick={props.onClick}
        color={color}
        isChecked={isChecked}
        as={isChecked ? RadioButtonChecked : RadioButtonUnchecked}
        disabled={disabled}
      />
      <CustomLabel
        style={{ marginLeft: 5, marginBottom: 0 }}
        color={color}
        name={name}
      >
        {label}
      </CustomLabel>
    </Container>
  );
};

RadioButtonSVG.defaultProps = {
  theme: defaultTheme,
};

export default RadioButton;
