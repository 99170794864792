import { getRequests, findRequest, uploadFileRequest, mediolibraryrequest } from "utils/requests";
import { image,imageLibrary } from "utils/fields";

const requests = getRequests("team");

const headers = {
  type: "CRUD",
  options: {
    name: "Equipo",
    route: "/team",
    requests: {
      ...requests,
      uploadFileRequest,
      mediolibraryrequest: mediolibraryrequest,
      medialibrary: getRequests("medialibrary"),
    },
    tableOptions: {
      isSearchable: true,
      isEditable: true,
      isDeletable: true,
      displayItemsPerPage: false,
    },
    upsertOptions: {
      onMount: () =>
        Promise.all([findRequest("admin/teamCategory")()]).then(
          ([{ data: category }]) => ({
            category,
          })
        ),
    },
  },
  sections: [
    {
      fields: [
        imageLibrary({ label: "Imagen", property: "imageUrl" }),
        { property: "name", label: "Nombre",
          tableOptions: {
            isSortable: true,
            sortProperty :"nameOrder"
          }
        },
        { property: "jobPosition", label: "Posición",
          tableOptions: {
            isSortable: true,
          }
        },
        { property: "email", label: "Email",
          tableOptions: {
            isSortable: true,
          }
        },
        { property: "phone", label: "Teléfono",
          tableOptions: {
            isSortable: true,
          }
        },
        { property: "extension", label: "Extensión",
          tableOptions: {
            isSortable: true,
          }
        },
        {
          label: "Departamento",
          property: "category",
          type: "multiselect",
          isClearable: true,
          tableOptions: { show: false },
          relation: {
            name: "category",
            isMulti: false,
            sort: true,
            forceSendItem: true,
            primaryKey: "id",
            nameProps: ["name"],
          },
        },
        { property: "orderTeam", label: "Posicion en la web" },
      ],
    },
  ],
};

export default headers;
