import { getRequests } from "utils/requests";

const requests = getRequests("bono");

const headers = {
  type: "CRUD",
  options: {
    name: "Bonos",
    route: "/bono",
    requests,
    tableOptions: {
      isEditable: true,
      isDeletable: true,
      displayItemsPerPage: false,
    },
  },
  sections: [
    {
      fields: [
        { property: "name", label: "Nombre" },
        {
          property: "price",
          label: "Precio",
          type: "number",
          upsertOptions: {
            beforeSave: ({ state, field }) => {
              return parseInt(state?.price) || 0;
            },
          },
        },
        { property: "numNews", label: "Número noticias", type: "number" },
      ],
    },
  ],
};

export default headers;
