import { DateTime } from "luxon";
import { getRequests } from "utils/requests";
import FilterInputs from "./FilterInputs";

const requests = getRequests("socialnews");

/*const options = [
  { value: "DULCESNOTICIAS", label: "Dulces Noticias" },
  { value: "GUIADULCESAPERITIVOS", label: "Guia dulces y aperitivos" },
];*/

const headers = {
  type: "CRUD",
  options: {
    name: "Noticias en RRSS",
    route: { path: "/socialnews" },
    requests,
    tableOptions: {
      isCreatable: false,
      isEditable: false,
      isDeletable: false,
      displayItemsPerPage: false,
      isSearchable: false,
      isFilterable: false,
      customFilters: ({ updateFilter }) => (
        <FilterInputs updateFilter={updateFilter} />
      ),
    },
  },
  sections: [
    {
      fields: [
        {
          property: "text",
          label: "Texto",
          upsertOptions: {
            isEditable: false,
          },
          tableOptions: { isSortable: true} 
        },
        {
          property: "dateToPublish",
          label: "Fecha Publicacion",
          tableOptions: {
            isSortable: true,
            format: (field, state) => {
              return state
                ? DateTime.fromISO(state, { zone: "utc" }).toFormat(
                    "dd/MM/yyyy HH:mm"
                  )
                : "";
            },
          },
        },
        {
          property: "new",
          label: "Noticia",
          tableOptions: {
            isSortable: true,
            sortProperty: "news.titleOrder",
            format: (item, property) =>
              item?.news?.id ? (
                <a taget="_blank" href={"/news/" + item?.news?.id}>
                  {item?.news?.title}
                </a>
              ) : (
                ""
              ),
          },
        },
        {
          property: "username",
          label: "Usuario",
          tableOptions: {
            isSortable: true,
            format: (item, property) => item?.socialAccount?.name,
          },
        },
        {
          property: "rrss",
          label: "Red Social",
          tableOptions: {
            isSortable: true,
            sortProperty: "socialAccount.type",
            format: (item, property) => item?.socialAccount?.type,
          },
        },
        {
          property: "published",
          label: "Publicada Social",
          tableOptions: {
            isSortable: true,
            sortProperty: "isPublished",
            format: (item, property) => (item?.isPublished ? "Si" : "No"),
          },
        },
      ],
    },
  ],
};

export default headers;
