import React, { useState } from "react";
import styled from "styled-components";
import { defaultTheme, themable } from "../Theme/";
import { ReactComponent as OpenIcon } from "../utils/images/burger.svg";
import { ReactComponent as CloseIcon } from "../utils/images/cross.svg";

function Topbar(props) {
  const { defaultOpen = false, buttonColor, topbarChildren = "" } = props;

  const [open, setOpen] = useState(defaultOpen);

  return (
    <>
      {!open ? (
        <StyledTopbar {...props}>
          <Spacer />
          {typeof topbarChildren === "function" && topbarChildren()}
          <ToggleIcon onClick={() => setOpen(!open)}>
            <StyledIcon as={OpenIcon} color={buttonColor} />
          </ToggleIcon>
        </StyledTopbar>
      ) : (
        <ChildContainer>
          <ToggleIcon onClick={() => setOpen(!open)}>
            <StyledIcon as={CloseIcon} color={buttonColor} />
          </ToggleIcon>
          {props.children(setOpen)}
        </ChildContainer>
      )}
    </>
  );
}

const StyledTopbar = styled.div`
  display: flex;
  ${({ theme, color }) => `  
    background-color: ${themable({
      theme,
      props: [`button.${color}.text`, color, "default"],
    })};  
  `}
  padding: 10px;
  height: 50px;
  position: relative;
  overflow-x: hidden;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
`;

const ChildContainer = styled.div`
  background-color: white;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1;
  padding-top: 50px;
`;

const Spacer = styled.div`
  height: 64px;
`;

const StyledIcon = styled.svg`
  ${({ theme, color }) => `  
  path {
    fill: ${themable({
      theme,
      props: [`button.${color}.background`, color, "default"],
    })}; 
  }
  `}
  cursor: pointer;
  width: auto;
  height: 18px;
`;

const ToggleIcon = styled.span`
  position: absolute;
  right: 35px;
  top: 25px;
`;

StyledIcon.defaultProps = {
  theme: defaultTheme,
};

StyledTopbar.defaultProps = {
  theme: defaultTheme,
};

export default Topbar;
