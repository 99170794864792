import React from "react";
import styled from "styled-components";
import { DateTime } from "luxon";
import Calendar from "../Calendar";
import Picker from "../Picker";

const CustomPicker = styled(Picker)`
  .DayPicker-Day:not(.DayPicker-Day--disabled) {
    cursor: pointer;
  }

  .DayPicker-Day--highlighted {
    background-color: orange;
    color: white;
  }
`;

const DatePicker = ({
  defaultEmptyValue = "",
  defaultEmptyDate = "",
  onChange = () => {},
  value = new Date(),
  ...props
}) => {
  const formatDate = (date) => DateTime.fromJSDate(date).toLocaleString();
  const currentDate = formatDate(new Date());

  return (
    <>
      <CustomPicker
        {...props}
        value={
          (defaultEmptyValue !== "" &&
            defaultEmptyDate !== "" &&
            formatDate(value) === defaultEmptyDate) ||
          (defaultEmptyValue !== "" &&
            defaultEmptyDate === "" &&
            currentDate === formatDate(value))
            ? defaultEmptyValue
            : formatDate(value)
        }
      >
        <Calendar onChange={onChange} value={value} {...props} />
      </CustomPicker>
    </>
  );
};

export default DatePicker;
