import HomeBuilder from "./HomeBuilder";

const headers = {
  type: "Page",
  options: {
    name: "Home noticias al detalle",
    route: {
      to: "/homesubnew",
      path: "/homesubnew",
      unAuth: false,
      auth: true,
      exact: true,
      component: (props) => <HomeBuilder {...props} />,
    },
  },
};

export default headers;
