import { getRequests,findAllRequest,findAllRequestOrderName } from "utils/requests";

const requests = getRequests("zds");

const headers = {
  type: "CRUD",
  options: {
    name: "Concurso ZDS",
    route: "/zds",
    requests,
    tableOptions: {
      isEditable: false,
      isDeletable: false,
      displayItemsPerPage: false,
      isSearchable: true,
      isCreatable: false,
    },
    upsertOptions: {
      onMount: () =>
        Promise.all([
          findAllRequestOrderName('course', true)(),
        ]).then(
          ([
            { data: course },
          ]) => ({
            course,
          }),
        )
      },
  },
  sections: [
    {
      fields: [
        { property: "name", label: "Nombre",
          tableOptions: {
              isSortable: true,
          }  
        },
        { property: "job", label: "Puesto",
          tableOptions: {
              isSortable: true,
          }  
        },
        { property: "company", label: "Compañia",
          tableOptions: {
              isSortable: true,
          }  
        },
        { property: "street", label: "Domicilio",
          tableOptions: {
              isSortable: true,
          }  
        },
        { property: "location", label: "Población",
          tableOptions: {
              isSortable: true,
          }  
        },
        { property: "state", label: "Provincia",
          tableOptions: {
              isSortable: true,
          }  
        },
        { property: "phone", label: "Teléfono",
          tableOptions: {
              isSortable: true,
          }  
        },
        { property: "email", label: "Email",
          tableOptions: {
              isSortable: true,
          }  
        },
        {
          label: "Seminario/Curso",
          property: "course",
          type: "multiselect",
          required: true,
          isClearable: true,
          tableOptions: {
            format: (item, type) => item?.course?.name,
          },
          relation: {
            name: "course",
            isMulti: false,
            sort: true,
            forceSendItem: true,
            nameProps: ["name"],
          },
        },
      ],
    },
  ],
};

export default headers;
