import React from "react";
import styled from "styled-components";
import { getRecalculatedWidth } from "../utils";
import { defaultTheme, themable } from "../Theme";
import Label from "../Label";
import { ReactComponent as CheckboxChecked } from "../utils/images/checkboxChecked.svg";
import { ReactComponent as CheckboxUnchecked } from "../utils/images/checkboxUnchecked.svg";

const Container = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  cursor: pointer;
`;

const CheckboxInput = styled.input`
  top: 0;
  left: 0;
  width: 100%;
  cursor: inherit;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  z-index: 1;
  position: absolute;
`;

const CheckboxSVG = styled.svg`
  ${({ theme, isChecked, disabled, color }) => {
    const state = disabled ? "disabled" : "active";
    const checkedProps = isChecked
      ? {
          borderWidth: getRecalculatedWidth({
            value: themable({
              theme,
              props: [`input.checkbox.borderWidth`, "1px"],
            }),
          }),
          borderColor: "transparent",
          borderRadius: getRecalculatedWidth({
            value: themable({
              theme,
              props: [`input.checkbox.borderRadius`, "borderRadius", "10px"],
            }),
          }),
          height: getRecalculatedWidth({
            value: themable({
              theme,
              props: [`input.checkbox.width`, "16px"],
            }),
            operation: "sum",
            increment: 1,
          }),
        }
      : {
          borderWidth: themable({
            theme,
            props: [`input.checkbox.borderWidth`, "2px"],
          }),
          borderColor: themable({
            theme,
            props: [`input.${state}.borderColor`, "default"],
          }),
          borderRadius: themable({
            theme,
            props: [`input.checkbox.borderRadius`, "borderRadius", "10px"],
          }),
          height: getRecalculatedWidth({
            value: themable({
              theme,
              props: [`input.checkbox.width`, "15px"],
            }),
            increment: 1,
          }),
        };

    return `
    border: ${checkedProps.borderWidth} solid ${checkedProps.borderColor};
    border-radius: ${checkedProps.borderRadius};
    height: ${checkedProps.height};
    width: auto;  
    background-color: ${themable({
      theme,
      props: [`input.${state}.backgroundColor`, "secondary"],
    })}; 
    
    path {
      fill: ${themable({
        theme,
        props: [color, `input.${state}.borderColor`],
      })};
    }
  `;
  }}

  display: inline-block;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
`;

const StyledLabel = styled(Label)`
  margin-bottom: 0;
  margin-left: 5px;
`;

CheckboxSVG.defaultProps = {
  theme: defaultTheme,
};

const Checkbox = ({
  label,
  Label: CustomLabel = StyledLabel,
  name,
  style,
  color,
  disabled,
  ...props
}) => {
  const isChecked = props.checked;

  return (
    <Container style={style}>
      <CheckboxInput type="checkbox" id={name} name={name} {...props} />
      <CheckboxSVG
        focusable="false"
        aria-hidden="true"
        onClick={props.onClick}
        color={color}
        isChecked={isChecked}
        as={isChecked ? CheckboxChecked : CheckboxUnchecked}
        disabled={disabled}
      />
      <CustomLabel color="secondary" name={name}>
        {label}
      </CustomLabel>
    </Container>
  );
};

CheckboxSVG.defaultProps = {
  theme: defaultTheme,
};

export default Checkbox;
