import { getRequests, findRequest, getNewsWithSearch,
mediolibraryrequest,getNewsWithSubSearch } from "utils/requests";
import { DateTime } from "luxon";

const requests = getRequests("floatingnewsnad");

const headers = {
  type: "CRUD",
  options: {
    name: "Noticias Flotantes - NAD",
    route: "/subfloatingnews",
    requests: {
      ...requests,
      mediolibraryrequest: mediolibraryrequest
    },
    tableOptions: {
      isEditable: true,
      isDeletable: true,
      displayItemsPerPage: false,
    },
    upsertOptions: {
      onMount: () =>
        Promise.all([findRequest("subnews", true)()]).then(([{ data: news }]) => ({
          news,
        })),
    },
  },
  sections: [
    {
      fields: [
        { property: "title", label: "Título",
          tableOptions: {
            isSortable: true,
            sortProperty: "titleOrder"
          } 
        },
        { property: "shortDescription", label: "Descripción", tableOptions: { isSortable: true} },
        {
          label: "Fecha de publicación",
          property: "dateCreate",
          type: "date",
          isSortable: true,
          tableOptions: {
            isSortable: true,
            format: (field, state) => {
              return state
                ? DateTime.fromISO(state).toFormat("dd/MM/yyyy")
                : "";
            },
          },
          upsertOptions: {
            beforeSave: ({ state, field }) => {
              return state?.dateCreate
                ? DateTime.fromISO(state?.dateCreate).toFormat(
                    "yyyy-MM-dd'T'00:00:00'Z"
                  )
                : "";
            },
          },
        },
        {
          label: "Fecha visible hasta",
          property: "visibleTo",
          type: "date",
          tableOptions: {
            isSortable: true,
            format: (field, state) => {
              return state
                ? DateTime.fromISO(state).toFormat("dd/MM/yyyy")
                : "";
            },
          },
          upsertOptions: {
            beforeSave: ({ state, field }) => {
              return state?.visibleTo
                ? DateTime.fromISO(state?.visibleTo).toFormat(
                    "yyyy-MM-dd'T'00:00:00'Z"
                  )
                : "";
            },
          },
        },
        {
          property: "color",
          label: "Color hexadecimal",
          type: "color",
          tableOptions: { show: false },
          style: { padding: 0 },
        },
        { property: "url", label: "Link externo", tableOptions: { isSortable: true} },
        {
          label: "Noticia asociada",
          property: "news",
          type: "multiselect-paginated",
          isClearable: true,
          isCreatable: false,
          fetchAction: ({ page, search }) =>
            getNewsWithSubSearch()({
              pagination: { page },
              filter: { searchValue: search },
            }).then((res) =>
              res?.data.map((item) => ({
                label: item.title,
                value: item.slug,
                ...item,
              }))
            ),
          tableOptions: { format: (item, value) => item?.news?.title },
          relation: {
            name: "news",
            isMulti: false,
            sort: true,
            forceSendItem: true,
            primaryKey: "id",
            nameProps: ["title"],
          },
        },
        {
          tableOptions: { show: false },
          label: 'translate',
          type: 'translate',
          entity: 'floatingnews'
        },
      ],
    },
  ],
};

export default headers;
