import React from "react";
import styled from "styled-components";
import { defaultTheme, themable } from "../Theme/";
import Label from "../Label";

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled.textarea`
  ${({ theme, disabled }) => {
    const state = disabled ? "disabled" : "active";
    return `
    border: ${themable({
      theme,
      props: [`input.${state}.borderWidth`, "1px"],
    })} solid ${themable({
      theme,
      props: [`input.${state}.borderColor`, "default"],
    })};

    background-color: ${themable({
      theme,
      props: [`input.${state}.backgroundColor`, "secondary"],
    })};

    color: ${themable({
      theme,
      props: [`input.${state}.color`, "default"],
    })};

    font-size: ${themable({
      theme,
      props: [`input.fontSize`, "16px"],
    })};

    border-radius: ${themable({
      theme,
      props: [`input.borderRadius`, "borderRadius", "10px"],
    })};

    font-family: ${themable({
      theme,
      props: [`input.fontFamily`, "fontFamily"],
    })};

    padding: ${themable({
      theme,
      props: [`input.padding`, "10px 20px"],
    })}; 

    ::placeholder {
      color: ${themable({
        theme,
        props: [`input.${state}.placeholderColor`, "default"],
      })};
      font-size: ${themable({
        theme,
        props: [`input.placeholderFontSize`, "16px"],
      })}; 
    }
    :focus {
      border-color: ${themable({
        theme,
        props: [`input.borderFocusColor`, "primary"],
      })}; 
    }
  `;
  }}

  margin-bottom: 10px;
  outline: none;
`;

const TextArea = (props) => {
  const { id, label, showLabel = true, Label: CustomLabel = Label } = props;

  return (
    <InputContainer>
      {showLabel && label && <CustomLabel htmlFor={id} />}
      <StyledInput {...props} />
    </InputContainer>
  );
};

StyledInput.defaultProps = {
  theme: defaultTheme,
};

export default TextArea;
