import { Label } from "../bluejay-ui";
import { useEffect, useState } from "react";
import { findAllRequest,
findAllRequestOrderTitle,
findAllRequestOrderName } from "utils/requests";
import request from "utils/request";
import { ReactComponent as Trash } from "../images/trash.svg";
import { DateTime as Luxon } from 'luxon';

const SocialAccountsTable = ({ newId = null, onChange, initialText = "" }) => {
  const [apiSocialAccounts, setApiSocialAccounts] = useState([]);
  const [items, setItems] = useState([]);
  const [idsDeleted, setIdsDeleted] = useState([]);

  useEffect(() => {
    getSocialAccounts();
  }, []);

  useEffect(() => {
    if (newId) {
      request(`admin/socialnews/search/` + newId).then(({ data }) => {
          setItems(data);
      });
    }
  }, [newId]);

  useEffect(() => {
    onChange({
      new: items.filter(({ id }) => id === -1),
      modify: items.filter(({ id }) => id !== -1),
      delete: idsDeleted,
    });
  }, [items]);

  const getSocialAccounts = async () => {
    const { data } = await findAllRequestOrderName("socialaccounts", true)();
    setApiSocialAccounts(data);
  };

  const addSocialAccount = () => {
    setItems([
      ...items,
      {
        id: -1,
        text: initialText,
        socialAccount: "",
        dateToPublish: Luxon.now().toUTC().toFormat(`yyyy-MM-dd'T'HH:mm:00'Z'`),
        dateToPublishTwo:null
      },
    ]);
  };

  const onChangeItem = (newItem, oldItem) => {
    setItems(items.map((i) => (i !== oldItem ? i : newItem)));
  };

  const deleteSocialAccount = (item) => {
    if (item.id !== -1) {
      setIdsDeleted([...idsDeleted, item.id]);
    }
    setItems(items.filter((i) => i !== item));
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Label>Publicaciones RRSS</Label>
        <button
          type="button"
          onClick={addSocialAccount}
          disabled={
            apiSocialAccounts?.length
              ? apiSocialAccounts.length === items.length
              : false
          }
        >
          Añadir red social
        </button>
      </div>

      <div style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        padding: "8px 0",
      }}>

      <label style={{ width: "21%", display: "inline-block;" }}> Red Social</label>
      <label style={{ width: "31%", display: "inline-block;",  margin: "0 0.5%" }}> Texto a compartir</label>
      <label style={{ width: "22.5%", display: "inline-block;",  marginRight: "0.5%" }}> 1º Fecha</label>
      <label style={{ width: "22.5%", display: "inline-block;", marginRight: "0.5%" }}> 2º Fecha</label>


      </div>

      {items.map((item) => (
        <NewSocialPublication
          socialAccounts={apiSocialAccounts}
          item={item}
          items={items}
          onChange={(data) => onChangeItem({ ...item, ...data }, item)}
          onDelete={() => deleteSocialAccount(item)}
        />
      ))}
    </div>
  );
};

const NewSocialPublication = ({
  item,
  onChange = () => {},
  onDelete = () => {},
  socialAccounts = [],
  items = [],
}) => {
  const {
    text,
    dateToPublish = Luxon.now().toLocal().toFormat(`yyyy-MM-dd'T'HH:mm:00'Z'`),
    socialAccount = null,
    dateToPublishTwo = null
  } = item;

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        padding: "8px 0",
      }}
    >
    <select
      style={{ border: "1px solid #BFBFBF", padding: "0 8px", width: "21%"}}
      onChange={(event) => {
        const selectedAccount = socialAccounts.filter(
          ({ name }) => name === event.target.value
        );
        if (selectedAccount && selectedAccount.length) {
          onChange({ ...item, socialAccount: selectedAccount[0] });
        }
      }}
    required >
        <option></option>
        {socialAccounts.map((account) => (
          <option
            selected={socialAccount ? socialAccount.id === account.id : false}
            disabled={items.some((i) => i.socialAccount?.id == account.id)}
          >
            {account.name}
          </option>
        ))}
      </select>
        <textarea
          style={{ width: "31%", border: "1px solid #BFBFBF", margin: "0 0.5%" }}
          numberOfLines={3}
          onChange={({ target }) => {
            onChange({ ...item, text: target?.value });
          }}
          value={item.text}
        />
      <input
        type="datetime-local"
        id="dateToPublish"
        name="dateToPublish"
        style={{ backgroundColor: "white", border: "1px solid #BFBFBF" }}
        value={dateToPublish && dateToPublish != "" ? Luxon.fromISO(dateToPublish).toLocal().toFormat(`yyyy-MM-dd'T'HH:mm`):""}
        onChange={(event) => {
          onChange({ ...item, dateToPublish: event.target.value == "" ? null :event.target.value});
        }}
        style={{ width: "22.5%", paddingLeft: 8 , marginRight: "0.5%"}}
      required ></input>
      <input
        type="datetime-local"
        id="dateToPublishTwo"
        name="dateToPublishTwo"
        style={{ backgroundColor: "white", border: "1px solid #BFBFBF" }}
        value={dateToPublishTwo && dateToPublishTwo != "" ?Luxon.fromISO(dateToPublishTwo).toLocal().toFormat(`yyyy-MM-dd'T'HH:mm`):""}
        onChange={(event) => {
          onChange({ ...item, dateToPublishTwo: event.target.value == "" ? null : event.target.value});
        }}
        style={{ width: "22.5%", paddingLeft: 8 , marginRight: "0.5%"}}
      ></input>

      <Trash
        style={{
          cursor: "pointer",
          marginTop: 10,
        }}
        onClick={onDelete}
      />
    </div>
  );
};
export default SocialAccountsTable;
