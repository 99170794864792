import {
  getRequests,
  findRequest,
  findAllRequest,
  findAllRequestOrderTitle,
  findAllRequestOrderName,
  uploadFileRequest,
  getBMPWithSearch,
  getAllBuyGuideCategorieRequest,
  getModelWithPaginationSearchText,
  mediolibraryrequest,
} from 'utils/requests';
import { image, imageLibrary } from 'utils/fields';

image['property'] = 'imageUrl';

const requests = getRequests('businessMeetingPoint');

const headers = {
  type: 'CRUD',
  options: {
    name: 'Business Meeting Point',
    route: '/businessmeetingpoint',
    requests: {
      ...requests,
      uploadFileRequest,
      findRequest: getBMPWithSearch(),
      mediolibraryrequest: mediolibraryrequest,
      medialibrary: getRequests("medialibrary"),
    },
    tableOptions: {
      isEditable: true,
      isDeletable: true,
      displayItemsPerPage: false,
      isSearchable: true,
    },
    upsertOptions: {
      onMount: () =>
        Promise.all([
          getAllBuyGuideCategorieRequest(),
          findAllRequest('product', true)(),
          findAllRequest('brand', true)(),
        ]).then(
          ([{ data: categories }, { data: products }, { data: brands }]) => ({
            categories,
            products,
            brands,
          }),
        ),
    },
  },
  sections: [
    {
      fields: [
        { property: 'name', label: 'Nombre',
          tableOptions: {
            isSortable: true,
            sortProperty: "nameOrder"
          }
        },
        { property: 'url', label: 'Url',
          tableOptions: {
            isSortable: true,
          }
        },
        imageLibrary({
          label: 'Imagen',
          property: 'imageUrl',
          showInTable: false,
        }),
        {
          label: 'Productos',
          property: 'products',
          type: 'multiselect',
          isCreatable: true,
          tableOptions: { show: false },
          relation: {
            name: 'products',
            isMulti: true,
            sort: true,
            primaryKey: 'id',
            nameProps: ['name'],
          },
        },
        {
          label: 'Marcas',
          property: 'brands',
          type: 'multiselect',
          tableOptions: { show: false },
          isCreatable: true,
          relation: {
            name: 'brands',
            isMulti: true,
            sort: false,
            primaryKey: 'id',
            nameProps: ['name'],
          },
        },
        {
          label: 'Categorías Directorio',
          property: 'categories',
          type: 'multiselect',
          isCreatable: false,
          tableOptions: { show: false },
          relation: {
            name: 'categories',
            isMulti: true,
            sort: true,
            primaryKey: 'id',
            nameProps: ['name'],
          },
        },
      ],
    },
  ],
};

export default headers;
