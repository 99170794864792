import {
  getRequests,
  uploadFileRequest,
} from "utils/requests";
import { image } from "utils/fields";

image["property"] = "url";

const requests = getRequests("medialibrary");

const headers = {
  type: "CRUD",
  options: {
    name: "Librería de medios",
    route: "/medialibrary",
    requests: {
      ...requests,
      uploadFileRequest,
    },
    tableOptions: {
      isEditable: true,
      isDeletable: true,
      displayItemsPerPage: false,
      isSearchable: true,
    },
  },
  sections: [
    {
      fields: [
        { property: "name", label: "Nombre", tableOptions: {isSortable: true, sortProperty:"nameOrder"} },
        { property: "category", label: "Categoría", tableOptions: {isSortable: true} },
        image({ property: "url" }),
      ],
    },
  ],
};

export default headers;
