import React from "react";
import styled from "styled-components";
import { defaultTheme, themable } from "../Theme/";
import { ContentWithIcon } from "../Icon";

const StyledListItem = styled.li`
  ${({ theme, color }) => ` 
    color: ${themable({
      theme,
      props: [color, "default"],
    })}; 
  `}
  padding: 10px;
  display: flex;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const CustomContentWithIcon = styled(ContentWithIcon)`
  span {
    min-width: 56px;
    margin: 0;
    margin-right: 20px;
  }
`;

const ListItem = (props) => {
  const { ListItem: CustomListItem = StyledListItem } = props;
  return (
    <CustomListItem {...props}>
      <CustomContentWithIcon {...props} />
    </CustomListItem>
  );
};

StyledListItem.defaultProps = {
  theme: defaultTheme,
};

export default ListItem;
