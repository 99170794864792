import { getRequests, findAllRequest,
findAllRequestOrderTitle,
findAllRequestOrderName  } from "utils/requests";

const requests = getRequests("sidebar");

const options = [
  { value: "HOME", label: "Home" },
  { value: "NEWS", label: "Noticias" },
  { value: "SECTION", label: "Secciones" },
  { value: "SIDEBARDULCESNOTICIAS", label: "Dulces Noticias" },
  { value: "SIDEBARNOTICIASDETALLES", label: "Noticias al Detalle" },
  { value: "SIDEBARNOTICIASDETALLESNAD", label: "Noticias al Detalle en papel" },
  { value: "SIDEBARGUIADULCES", label: "Guía Dulces y Aperitivos" },
  { value: "HOMENOTICIASALDETALLE", label: "Home noticias al detalle" },
  { value: "NEWSNOTICIASALDETALLE", label: "Noticias de Noticias al detalle" },
  { value: "SECCIONNOTICIASALDETALLE", label: "Secciones de Noticias al detalle" },

  
];

const headers = {
  type: "CRUD",
  options: {
    name: "Sidebar",
    route: "/sidebar",
    requests,
    tableOptions: {
      isEditable: true,
      isDeletable: true,
      displayItemsPerPage: false,
      isSearchable: true,
    },
    upsertOptions: {
      onMount: () =>
        Promise.all([
          findAllRequest("module", true)(),
          findAllRequestOrderTitle("ads", true)(),
        ]).then(([{ data: modules }, { data: ads }]) => ({
          modules,
          ads,
        })),
      itemBeforeSave: (data) =>
        Promise.resolve({
          ...data,
          id: data?.id || -1,
        }),
    },
  },
  sections: [
    {
      fields: [
        { property: "order", label: "Orden", tableOptions:{isSortable: true} },
        {
          label: "Módulos",
          property: "module",
          type: "multiselect",
          isClearable: true,
          relation: {
            name: "modules",
            isMulti: false,
            sort: true,
            forceSendItem: true,
            primaryKey: "id",
            nameProps: ["type"],
          },
          tableOptions: {
            isSortable: true,
            sortProperty: "module.type",
            format: (state, field) => field?.type,
          },
        },
        // {
        //   label: "Anuncio",
        //   property: "ads",
        //   type: "multiselect",
        //   isClearable: true,
        //   relation: {
        //     name: "ads",
        //     isMulti: false,
        //     forceSendItem: true,
        //     primaryKey: "id",
        //     nameProps: ["title"],
        //   },
        //   tableOptions: {
        //     format: (state, field) => field?.title,
        //   },
        // },
        {
          label: "Página",
          property: "page",
          type: "multiselect",
          isClearable: true,
          tableOptions: {
            isSortable: true,
            sortProperty: "page",
            format: (item, type) =>
              options.find((option) => option.value === type)?.label,
          },
          upsertOptions: {
            onChange: ({ value }) => {
              return value ? { page: value.value } : { page: null };
            },
          },
          relation: {
            name: "page",
            options,
            forceSendItem: true,
            primaryKey: "value",
            isMulti: false,
            sort: true,
          },
        },
      ],
    },
  ],
};

export default headers;

/*
import HomeBuilder from "./HomeBuilder";

const headers = {
  type: "Page",
  options: {
    name: "Sidebar",
    route: {
      to: "/sidebar",
      path: "/sidebar",
      unAuth: false,
      auth: true,
      exact: true,
      component: (props) => <HomeBuilder {...props} />,
    },
  },
};

export default headers;

*/
