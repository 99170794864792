const request = (url, options) =>
  // console.log(url, options);
  fetch(`${process.env.REACT_APP_API_URL}${url}`, {
    ...options,
    headers: getHeaders(options?.headers),
  }).then((res) => {
    switch (res.status) {
      case 200:
        const url = window.location;
        if (url === "/" || url === "") {
          window.location = "/news";
        }
        return res.json();
      case 403:
        localStorage.removeItem("token");
        localStorage.setItem("isLoggedIn", false);
        localStorage.setItem("user", {});
        window.location = "/";
        return;
      default:
        return res
          .json()
          .then((error) => Promise.reject({ status: res.status, error }));
    }
  });


const getHeaders = (headers) => {
  const nextHeaders = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    ...headers,
  };

  return Object.keys(nextHeaders)
    .filter((key) => typeof nextHeaders[key] !== "undefined")
    .reduce((reducer, key) => ({ ...reducer, [key]: nextHeaders[key] }), {});
};

export default request;
