import { DateTime } from "luxon";
import { findAllRequest,findAllRequestOrderName, getRequests } from "utils/requests";
import { changePassword } from "utils/requests";

const requests = getRequests("user");

const headers = {
  type: "CRUD",
  options: {
    name: "Usuarios",
    route: "/users",
    requests: {
      ...requests,
      upsertRequest: (data) => {
        const { oldpass, newpass } = data;
        if (oldpass && newpass) {
          changePassword(data);
        }
        const res = requests.upsertRequest(data);
        if (res["error"]) {
          return Promise.reject(res.error);
        }
        return Promise.resolve(res);
      },
    },
    tableOptions: {
      isEditable: true,
      isDeletable: true,
      displayItemsPerPage: false,
      isSearchable: true,
    },
    upsertOptions: {
      onMount: () =>
        Promise.all([
          findAllRequestOrderName("suscriptiontype", true)(),
          findAllRequestOrderName("bono", true)(),
        ]).then(([{ data: suscriptiontype }, { data: bono }]) => ({
          suscriptiontype,
          bono,
        })),
      onLoadMapping: (data) => {
        const rol = {
          ...data.rol,
          label:
            data.rol?.code === "CLIENT"
              ? "Cliente"
              : data.rol?.code === "ADMIN"
              ? "Equipo SweetPress"
              : "",
        };
        return Promise.resolve({
          ...data,
          rol,
          bono: data?.susUser?.bono,
          suscription: data?.susUser?.suscription,
          numBonusUse: data?.susUser?.numBonusUse,
          bonusAvailable: data?.susUser?.bonusAvailable,
          finishSuscription: data?.susUser?.finishSuscription,
        });
      },
      itemBeforeSave: (data) => {
        let item = {
          ...data,
          susUser: {
            ...data.susUser,
            suscription: data.suscription,
            numBonusUse: data.numBonusUse,
            bonusAvailable: data.bonusAvailable,
            bono: data.bono,
            finishSuscription: data.finishSuscription,
          },
        };
        delete item.suscription;
        delete item.numBonusUse;
        delete item.bonusAvailable;
        delete item.finishSuscription;
        delete item.bono;

        return Promise.resolve(item);
      },
    },
  },
  sections: [
    {
      title: "General",
      fields: [
        { property: "email", label: "Email", tableOptions: {isSortable: true}  },
        { property: "name", label: "Nombre", tableOptions: {isSortable: true}  },
        { property: "lastname", label: "Apellido", tableOptions: {isSortable: true} },
        { property: "street", label: "Calle", tableOptions: { show: false } },
        { property: "phone", label: "Telefono", tableOptions: { show: false } },
        { property: "number", label: "Número", tableOptions: { show: false } },
        { property: "door", label: "Puerta", tableOptions: { show: false } },
        { property: "floor", label: "Piso", tableOptions: { show: false } },
        { property: "city", label: "Ciudad", tableOptions: { show: false } },
        {
          property: "pass",
          type: "password",
          label: "Contraseña",
          tableOptions: { show: false },
        },
        {
          property: "state",
          label: "Provincia",
          tableOptions: { show: false },
        },
        { property: "country", label: "País", tableOptions: { show: false } },
        {
          property: "postalcode",
          label: "Código Postal",
          tableOptions: { show: false },
        },
        {
          label: "Rol",
          property: "rol",
          type: "multiselect",
          tableOptions: { show: false },
          relation: {
            name: "rol",
            property: "rol",
            forceSendItem: true,
            isMulti: false,
            sort: true,
            primaryKey: "id",
            nameProps: ["code"],
            options: [
              {
                value: "Cliente",
                label: "Cliente",
                code: "CLIENT",
                name: "CLIENT",
              },
              {
                value: "Equipo SweetPress",
                label: "Equipo SweetPress",
                code: "ADMIN",
                name: "ADMIN",
              },
            ],
          },
        },
        {
          label: "Subscripción",
          property: "suscription",
          type: "multiselect",
          tableOptions: { show: false },
          relation: {
            name: "suscriptiontype",
            property: "suscription",
            forceSendItem: true,
            forceBeforeSave: true,
            isMulti: false,
            sort: true,
            primaryKey: "id",
            nameProps: ["name"],
          },
        },
        {
          label: "Fecha finalizacion subscripción",
          property: "finishSuscription",
          type: "date",
          tableOptions: { show: false },
          upsertOptions: {
            beforeSave: ({ state, field }) => {
              return state?.finishSuscription
                ? DateTime.fromISO(state?.finishSuscription).toFormat(
                    "yyyy-MM-dd'T'00:00:00'Z'"
                  )
                : "";
            },
          },
        },
        {
          label: "Bono Noticias",
          property: "bono",
          type: "multiselect",
          tableOptions: { show: false },
          relation: {
            name: "bono",
            property: "bono",
            forceSendItem: true,
            forceBeforeSave: true,
            isMulti: false,
            sort: true,
            primaryKey: "id",
            nameProps: ["name"],
          },
        },
        {
          label: "Noticias Pendientes del bono",
          property: "bonusAvailable",
          type: "number",
          tableOptions: { show: false },
        },
        { property: "numread", label: "Nº Entradas", tableOptions: { show: true }, readOnly: true },
      ],
    },
    {
      title: "Cambiar contraseña",
      fields: [
        {
          property: "oldpass",
          type: "password",
          label: "Contraseña antigua",
          tableOptions: { show: false },
        },
        {
          property: "newpass",
          type: "password",
          label: "Contraseña nueva",
          tableOptions: { show: false },
        },
      ],
    },
  ],
};

export default headers;
