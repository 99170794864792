import { getRequests } from "utils/requests";
import UpsertGallery  from "./UpsertGallery";

const requests = getRequests("gallery");

const headers = {
  type: "CRUD",
  options: {
    name: "Galería",
    route: {
      path: "/gallery",
    },
    requests: requests,
    tableOptions: {
      isSearchable: true,
      isEditable: true,
      isDeletable: true,
      displayItemsPerPage: false,
    },
    upsertOptions: {
      component: UpsertGallery,
    },
  },
  sections: [
    {
      fields: [
        {
          label: "Nombre",
          property: "name",
          tableOptions: {
            isSortable: true
          }
        },
      ],
    },
  ],
};

export default headers;
