import styled from "styled-components";
import { getRequests,findAllRequest, findAllRequestOrderName, exportOrdersExcel } from "utils/requests";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { Table, Modal, Text, Button, Loader } from "../../bluejay-ui";
import { reduce } from "lodash";

const PanelModalBill = styled.div`
  display: flex;
  color: black;
  flex: 0 1 100%;
  min-height: 30px;
`;

const TitleColumn = styled.div`
  display: flex;
  color: black;
  text-align: left;
  flex: 0 1 30%;
  font-weight: bold;
`;

const ValueColumn = styled.div`
  display: flex;
  color: black;
`;


const requests = getRequests("orders");


const renderModel = (props,DefaultComponent) => {
  return <DefaultComponent {...props} header={props.headers} renderActions={renderButtonShowBillData}/>
}

const renderButtonShowBillData =  ({  item, state: remoteData,   updateState, refreshState,setItemSelected}) => {
    return  <Link onClick={(evt) => setItemSelected(item)} style={{ display: "inline-block",  padding: "0.2em 1em",textDecoration: "none", color: "black", border: "1px solid black", borderRadius: "3px", marginRight: "4px"}}>
          Ver
      </Link>
}

const clickCloseModalSHowBill = (setItemSelected) => {
  setItemSelected(null);
}

const showError = (text,statechild,setStatechild,refreshState) => {
    statechild['exporterror'] = text;
    setStatechild(statechild);
    refreshState();
}

const exportOrders = (statechild,setStatechild,refreshState) => {
  console.log("funcion exportar");
  console.log(statechild);
  showError("",statechild,setStatechild,refreshState);
  if(statechild['dataToexport']){
    if(statechild['dataToexport']['to'] && statechild['dataToexport']['to'] != null && statechild['dataToexport']['to'] != '' && statechild['dataToexport']['from'] && statechild['dataToexport']['from'] != null && statechild['dataToexport']['from'] != '' ) {
        if(statechild['dataToexport']['to'] > statechild['dataToexport']['from']){

          var filter = {
            from: statechild['dataToexport']['from']+"T00:00:00Z",
            to: statechild['dataToexport']['to']+"T23:59:59Z",
          }

          exportOrdersExcel()(filter).then((data) => {
            var url = window.URL.createObjectURL(data);
            var a = document.createElement('a');
            a.href = url;
            a.download = "Compras."+statechild['dataToexport']['from']+"."+statechild['dataToexport']['to']+".xls";
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();


            statechild['openexport'] = false; 
            statechild['dataToexport'] = {from: "", to: ""}; 
            statechild['exporterror'] = ''; 
            setStatechild(statechild); refreshState();
          });

        } else {
          showError("La fecha hasta debe ser mayor que la fecha desde",statechild,setStatechild,refreshState);
        }
    } else {
      showError("Debes indicar el rango de fechas",statechild,setStatechild,refreshState);
    }
  } else {
    showError("Debes indicar el rango de fechas",statechild,setStatechild,refreshState);
  }
}


const modalView = (state,refreshState,getData,itemSelected,setItemSelected,statechild, setStatechild) => {
  console.log(state);
  var to = '';
  var from = '';
  if(!statechild['dataToexport']){
    statechild['dataToexport'] = {from: "", to: ""};
  } else {
    to = statechild['dataToexport']['to'];
    from = statechild['dataToexport']['from'];
  }
  return <>
    {itemSelected && (
    <Modal title={"Datos de la compra"}>
      <Text style={{ textAlign: "center" }}>
      <PanelModalBill>
              <TitleColumn>
                Fecha:
              </TitleColumn>
              <ValueColumn>
                {itemSelected?.createDate ? DateTime.fromISO(itemSelected?.createDate).toFormat("dd/MM/yyyy HH:mm:ss")  : ""}
              </ValueColumn>
      </PanelModalBill>
      <PanelModalBill>
              <TitleColumn>
                Cliente:
              </TitleColumn>
              <ValueColumn>
                {itemSelected?.user?.name + " " + itemSelected?.user?.lastname}
              </ValueColumn>
      </PanelModalBill>
      <PanelModalBill>
              <TitleColumn>
                CIF:
              </TitleColumn>
              <ValueColumn>
                {itemSelected?.taxIdentificationNumberInvoice }
              </ValueColumn>
      </PanelModalBill>
      <PanelModalBill>
              <TitleColumn>
                Email:
              </TitleColumn>
              <ValueColumn>
                {itemSelected?.emailSendInvoice}
              </ValueColumn>
      </PanelModalBill>
      <PanelModalBill>
              <TitleColumn>
                Direccion:
              </TitleColumn>
              <ValueColumn>
                {(itemSelected?.streetInvoice ? itemSelected?.streetInvoice : "") + ", " + (itemSelected?.numberInvoice ? itemSelected?.numberInvoice : "" )+ ", " + (itemSelected?.floorInvoice ? itemSelected?.floorInvoice : "" )}
                <br/>
                {(itemSelected?.townInvoice ? itemSelected?.townInvoice : "") + ", " + (itemSelected?.stateInvoice ? itemSelected?.stateInvoice : "" )+ ", " + (itemSelected?.postalCodeInvoice ? itemSelected?.postalCodeInvoice : "" )}

              </ValueColumn>
      </PanelModalBill>
      <PanelModalBill>
              <TitleColumn>
                Suscripcion:
              </TitleColumn>
              <ValueColumn>
                {(itemSelected?.suscriptionType?.name ? itemSelected?.suscriptionType?.name : "")}
              </ValueColumn>
      </PanelModalBill>
      <PanelModalBill>
              <TitleColumn>
                Bono:
              </TitleColumn>
              <ValueColumn>
                {(itemSelected?.bono?.name ? itemSelected?.bono?.name : "")}
              </ValueColumn>
      </PanelModalBill>
      <PanelModalBill>
              <TitleColumn>
                Noticia:
              </TitleColumn>
              <ValueColumn>
                {(itemSelected?.news?.title ? itemSelected?.news?.title : "")}
              </ValueColumn>
      </PanelModalBill>
      <PanelModalBill>
              <TitleColumn>
                Importe:
              </TitleColumn>
              <ValueColumn>
                {(itemSelected?.prize ? itemSelected?.prize / 100.0 : "") + "€"}
              </ValueColumn>
      </PanelModalBill>
      </Text>
      <div style={{textAlign: "center"}}>
        <Button color="danger" onClick={(evt) => clickCloseModalSHowBill(setItemSelected)} style={{display:"inline-block"}}>
          Cerrar
        </Button>
      </div>
    </Modal>
  )}

  {statechild['openexport'] && 
    <Modal title={"Exportar compras"}>
      <Text style={{ textAlign: "center" }}>
      <PanelModalBill>
              <TitleColumn>
                Desde:
              </TitleColumn>
              <ValueColumn>
                <input type="date" onChange={(evt) => {from = evt.target.value; statechild['dataToexport']['from'] = from; setStatechild(statechild);}} />
              </ValueColumn>
      </PanelModalBill>
      <PanelModalBill style={{margin: "5px 0"}}>
              <TitleColumn>
                Hasta:
              </TitleColumn>
              <ValueColumn>
                <input type="date" onChange={(evt) => {to = evt.target.value; statechild['dataToexport']['to'] = to; setStatechild(statechild);}} />
              </ValueColumn>
      </PanelModalBill>

      {statechild['exporterror'] && <p style={{color: "darkred"}}>{statechild['exporterror']}</p>}
     
      </Text>
      <div style={{textAlign: "center"}}>
       <Button color="danger" onClick={(evt) => {exportOrders(statechild,setStatechild,refreshState)}} style={{display:"inline-block", margin:"0 5px"}}>
          Export
        </Button>
        <Button color="danger" onClick={(evt) => {statechild['openexport'] = false; statechild['dataToexport'] = {from: "", to: ""}; statechild['exporterror'] = ''; setStatechild(statechild); refreshState();}} style={{display:"inline-block", margin:"0 5px"}}>
          Cerrar
        </Button>
      </div>
    </Modal>}
  <Button color="danger" onClick={(evt) => {statechild['openexport'] = true; setStatechild(statechild); refreshState();}} style={{display:"inline-block", marginTop: "2em"}}>
          Exportar
  </Button>
  </>;
}

const headers = {
  type: "CRUD",
  options: {
    name: "Compras",
    route: "/orders",
    requests,
    tableOptions: {
      isEditable: false,
      isDeletable: true,
      displayItemsPerPage: false,
      isSearchable: true,
      isCreatable: false,
      component: renderModel,
      children: modalView
    },
    upsertOptions: {
      onMount: () =>
        Promise.all([
          findAllRequestOrderName('course', true)(),
        ]).then(
          ([
            { data: course },
          ]) => ({
            course,
          }),
        )
      },
  },
  sections: [
    {
      fields: [
        { property: "createDate", label: "Fecha",tableOptions: { isSortable: true, format: (field, state) => {  return state? DateTime.fromISO(state).toFormat("dd/MM/yyyy HH:mm:ss")  : "";  } }},
        { property: "user", label: "Cliente", tableOptions: { isSortable: true, sortProperty: "user.name", format: (item, value) => (value.name + " - " +value.email) } },
        { property: "isBonus", label: "Bono", tableOptions: { isSortable: true,format: (item, value) => (value ? 'Si' : 'No') } },
        { property: "isNews", label: "Noticia", tableOptions: {isSortable: true, format: (item, value) => (value ? 'Si' : 'No') }  },
        { property: "isPdfAsociadoNoticia", label: "PDF Asociado", tableOptions: {isSortable: true, format: (item, value) => (value ? 'Si' : 'No') } },
        { property: "bono", label: "Bono",tableOptions: {isSortable: true, format: (item, value) => (value?.name ?value?.name:'' ) }  },
        { property: "suscriptionType", label: "Suscripcion",tableOptions: {isSortable: true, format: (item, value) => (value?.name ?value?.name:'' )} },
        { property: "news", label: "Noticia",tableOptions: {isSortable: true, format: (item, value) => (value?.title ?value?.title:'' )}},
        { property: "state", label: "Estado", tableOptions: {isSortable: true, format: (item, value) => (value ? value : 'PENDIENTE DE PAGO') } },
        { property: "prize", label: "Precio",tableOptions: {isSortable: true,sortProperty: "prizeNum", format: (item, value) => (value? value/100.0:0.0 )}},
        { property: "needInvoice", label: "Necesita Factura", tableOptions: {isSortable: true, format: (item, value) => (value ? 'Si' : 'No') }  },


      ],
    },
  ],
};

export default headers;
