import React from "react";
import styled from "styled-components";
import { defaultTheme, themable } from "../Theme/";

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme, color }) => `
    svg { 
      fill: ${themable({
        theme,
        props: [color, "default"],
      })};
      width: 25px;
      height: 25px;
    }
  `}

  cursor: pointer;
  margin: 0 16px;
`;

IconWrapper.defaultProps = {
  theme: defaultTheme,
};

const Icon = (props) => {
  const {
    iconSrc: InnerIcon,
    color,
    IconWrapper: CustomIconWrapper = IconWrapper,
  } = props;
  return (
    <CustomIconWrapper color={color} {...props}>
      <InnerIcon />
    </CustomIconWrapper>
  );
};

Icon.defaultProps = {
  theme: defaultTheme,
};

export default Icon;
