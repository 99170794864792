import React from "react";
import styled from "styled-components";
import { defaultTheme, themable } from "../Theme";
import Text from "../Text";

export const StyledTr = styled.tr`
  background: white;
  :nth-child(even) {
    background: #edf0f4;
  }
`;

const TableCell = styled.div`
  border: 1px solid #9da1a7;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
`;

const TableRow = styled(Text)`
  display: flex;
`;

const RowHeader = styled.span`
  width: 40%;
  margin-left: 15px;
  margin-right: 18px;
  font-weight: 500;
  line-height: 21px;
  color: ${({ theme, color }) =>
    themable({
      theme,
      props: [color, "default"],
    })};
`;

RowHeader.defaultProps = {
  theme: defaultTheme,
};

const RowText = styled.span`
  width: 40%;
  color: ${({ theme, color }) =>
    themable({
      theme,
      props: [color, "black"],
    })};
`;

RowText.defaultProps = {
  theme: defaultTheme,
};

const ResponsiveTable = ({
  headers = [],
  renderCustomHeader = () => {},
  renderCustomCell = () => {},
  onRowClick = false,
  data = [],
  emptyTableText = "No hay resultados",
  className,
}) => {
  return data.length > 0 ? (
    data.map((dataRow, key) => (
      <TableCell
        key={key}
        className={className}
        onClick={onRowClick ? () => onRowClick(dataRow) : () => {}}
      >
        <RowText>{renderCustomCell(dataRow, key)}</RowText>
        {headers.map((header, HKey) => (
          <TableRow key={HKey}>
            <RowHeader>{header.name}</RowHeader>
            <RowText>{dataRow[header.property]}</RowText>
          </TableRow>
        ))}
      </TableCell>
    ))
  ) : (
    <RowText colSpan={headers.length + 1}>{emptyTableText}</RowText>
  );
};

export default ResponsiveTable;
