import React from "react";
import Icon from "../Icon/Icon";
import styled from "styled-components";
import { defaultTheme, themable } from "../Theme/";

const ContentWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentWithIcon = ({
  icon,
  children,
  iconPosition = "start",
  onIconClick = () => {},
  color,
  theme,
  ...props
}) => {
  if (!icon) {
    return children;
  }

  const IconStyled = () => (
    <Icon
      iconSrc={icon}
      onClick={onIconClick}
      color={themable({
        theme,
        props: [`button.${color}.text`, color, "default"],
      })}
    />
  );

  return iconPosition === "start" ? (
    <ContentWrapper {...props}>
      <IconStyled /> {children}
    </ContentWrapper>
  ) : (
    <ContentWrapper {...props}>
      {children} <IconStyled />
    </ContentWrapper>
  );
};

ContentWithIcon.defaultProps = {
  theme: defaultTheme,
};

export default ContentWithIcon;
