import React from "react";
import styled from "styled-components";
import { ContentWithIcon } from "../Icon";
import { defaultTheme, themable } from "../Theme/";

const StyledLink = styled.a`
  ${({ theme, color }) => `  
    color: ${themable({
      theme,
      props: [`link.${color}.text`, "default"],
    })};
    font-size: ${themable({
      theme,
      props: ["link.fontSize", "16px"],
    })};
    font-family: ${themable({
      theme,
      props: ["link.fontFamily", "fontFamily"],
    })}; 
    padding: ${themable({
      theme,
      props: ["link.padding", "10px 16px"],
    })}; 
  `}
  cursor: pointer;
`;

const Link = React.forwardRef(({ style, ...props }, ref) => (
  <StyledLink ref={ref} style={style} {...props}>
    <ContentWithIcon {...props} />
  </StyledLink>
));

StyledLink.defaultProps = {
  theme: defaultTheme,
};

export default Link;
