import { headers as adsHeaders } from "./Ads";
import { headers as categoriesHeaders } from "./Categories";
import { headers as companyHeaders } from "./Companies";
import { headers as homeHeaders } from "./Home";
import { headers as loginHeaders } from "./Login";
import { headers as magazineHeaders } from "./Magazines";
import { headers as newsHeaders } from "./News";
import { headers as shortNewsHeaders } from "./ShortNews";
import { headers as tvNewsHeaders } from "./TvNews";
import { headers as subscriptionsHeaders } from "./Subscriptions";
import { headers as usersHeaders } from "./Users";
import { headers as bonosHeaders } from "./Bonos";
import { headers as tagsHeaders } from "./Tags";
import { headers as galleryHeaders } from "./Gallery";
import { headers as teamHeaders } from "./Team";
import { headers as teamCategory } from "./TeamCategory";
import { headers as pricetypeHeaders } from "./PriceType";
import { headers as eventsHeaders } from "./Events";
import { headers as bmpHeaders } from "./BusinessMeetingPoint";
//import { headers as impHeaders } from "./InnovationMeetingPoint";
import { headers as impNewHeaders } from "./InnovationNews";
import { headers as productsHeaders } from "./Products";
import { headers as sweetandsnackHeaders } from "./Sweet&Snack";
import { headers as sidebarHeaders } from "./Sidebar";
import { headers as floatinNewsHeaders } from "./FloatingNews";
import { headers as mediaLibrary } from "./MediaLibrary";
import { headers as adsPosition } from "./AdsPos";
import { headers as socialaccounts } from "./SocialAccounts";
import { headers as socialNews } from "./SocialNews";
import { headers as buyGuideCategoryHeaders } from "./BuyGuideCategory";
import { headers as brandsHeaders } from "./Brands";
import { headers as courseHeaders } from "./Course";
import { headers as zdsHeaders } from "./Zds";
import { headers as ordersHeaders } from "./Orders";
import { headers as linkedinHeaders } from "./Linkedin";
import { headers as twitterHeaders } from "./Twitter";
import { headers as logoHeaders } from "./Logo";
import { headers as hewsletterHeaders } from "./Newsletter";
import { headers as categoriesCompanyHeaders } from "./CategoriesCompany";
import { headers as categoriesSubNewsHeaders } from "./CategoriesSubNews";
import { headers as subNewsHeaders } from "./SubNews";
import { headers as subHomeSubNew } from "./HomeSubNew";
import { headers as subInnovationNewsHeaders } from "./SubInnovationNews";
import { headers as subTvNewsHeaders } from "./SubTvNews";
import { headers as subShortNewsHeaders } from "./SubShortNews";
import { headers as subFloatingNewsHeaders } from "./SubFloatingNews";
import { headers as subBusinessMeetingPointHeaders } from "./SubBusinessMeetingPoint";
import { headers as subadsHeader } from "./AdsNad";
import { headers as contactHeaders } from "./Contact";


var h = []

if (process.env.REACT_APP_SUBWEB) {
  h = [
    loginHeaders,
    eventsHeaders,
    adsHeaders,
    subadsHeader,
    bonosHeaders,
    bmpHeaders,
    subBusinessMeetingPointHeaders,
    buyGuideCategoryHeaders,
    categoriesCompanyHeaders,
    categoriesSubNewsHeaders,
    categoriesHeaders,
    ordersHeaders,
    zdsHeaders,
    courseHeaders,
    contactHeaders,
    teamCategory,
    sweetandsnackHeaders,
    companyHeaders,
    teamHeaders,
    tagsHeaders,
    galleryHeaders,
    socialaccounts,
    homeHeaders,
    subHomeSubNew,
    //impHeaders,
    impNewHeaders,
    subInnovationNewsHeaders,
    mediaLibrary,
    logoHeaders,
    brandsHeaders,
    hewsletterHeaders,
    newsHeaders,
    subNewsHeaders,
    subTvNewsHeaders,
    shortNewsHeaders,
    subShortNewsHeaders,
    floatinNewsHeaders,
    subFloatingNewsHeaders,
    socialNews,
    adsPosition,
    productsHeaders,
    magazineHeaders,
    sidebarHeaders,
    subscriptionsHeaders,
    tvNewsHeaders,
    pricetypeHeaders,
    usersHeaders,
    linkedinHeaders,
    twitterHeaders
  ];
} else {
  h = [
    loginHeaders,
    eventsHeaders,
    adsHeaders,
    bonosHeaders,
    bmpHeaders,
    buyGuideCategoryHeaders,
    categoriesCompanyHeaders,
    categoriesHeaders,
    ordersHeaders,
    zdsHeaders,
    courseHeaders,
    teamCategory,
    sweetandsnackHeaders,
    companyHeaders,
    teamHeaders,
    tagsHeaders,
    galleryHeaders,
    socialaccounts,
    homeHeaders,
    //impHeaders,
    impNewHeaders,
    mediaLibrary,
    logoHeaders,
    brandsHeaders,
    hewsletterHeaders,
    newsHeaders,
    shortNewsHeaders,
    floatinNewsHeaders,
    socialNews,
    adsPosition,
    productsHeaders,
    magazineHeaders,
    sidebarHeaders,
    subscriptionsHeaders,
    tvNewsHeaders,
    pricetypeHeaders,
    usersHeaders,
    linkedinHeaders,
    twitterHeaders
  ];
}

export const headers = h;
