import React, { useState, useEffect, useRef } from "react";
import { Button as DefaultButton, Text, Label } from "../../../bluejay-ui";
import styled from "styled-components";
import { InputMapper } from "../utils/inputs/";
import MediaLibrary from "neoco/form/utils/inputs/mediolibrary/MediaLibrary";

const FormGenerator = ({
  headers = {},
  state = {},
  updateState = () => {},
  handleChange = () => {},
  onSubmit = () => Promise.resolve({}),
  children = <></>,
  Button = DefaultButton,
  ...props
}) => {

  const [error, setError] = useState({});
  const [dataOpen, setDataOpen] = useState({
    callback: null,
    value: null,
    meta: null,
    open:false
  });

  const btnRef = useRef();

  const onLocalSubmit = (e) => {
    e.preventDefault();

    onSubmit(e).catch(setError);
  };

  var intervalAutoSave = null;

  useEffect(() => {
    if (headers.autosave) {

        intervalAutoSave = setInterval(() => {
          var allowautosave =  !state.disabled;
          if(allowautosave){
              document.getElementById("btnsaveform").click();
          }
        }, 180000);

      return () => {
        //UNMOUNT
        if (intervalAutoSave) {
          clearInterval(intervalAutoSave);
        }
      }
    }
  },[]);

  return (
    <form onSubmit={onLocalSubmit}>
      {headers.sections.map((section, index) =>
        sectionMapper({
          section,
          index,
          state,
          updateState,
          handleChange,
          setDataOpen,
          ...props,
        })
      )}

      {typeof children === "function" ? children({ state }) : children}

      {error.message && <Text color="danger">{error.message}</Text>}

      <MediaLibrary mediolibraryrequest={headers?.options?.requests?.mediolibraryrequest} dataOpen={dataOpen} uploadmedia={headers?.options?.requests?.medialibrary} uploadFileRequest={headers?.options?.requests?.uploadFileRequest}/>

      <Button id="btnsaveform" style={{ marginTop: 20 }} color="primary" ref={btnRef} disabled={state.disabled}>
        Guardar
      </Button>
    </form>
  );
};

const sectionMapper = ({
  section,
  index,
  state,
  updateState = () => {},
  handleChange,
  setDataOpen,
  Title = DefaultTitle,
  Subtitle = DefaultSubtitle,
}) => {
  const { FieldsContainer = BaseFieldsContainer } = section;

  return (
    <React.Fragment key={index}>
      <Section>
        {section.title ? <Title>{section.title}</Title> : ""}
        {section.subtitle ? <Subtitle>{section.subtitle}</Subtitle> : ""}
        <FieldsContainer
          style={{ marginTop: 20, ...section.fieldsContainerStyles }}
        >
          {typeof section.render === "function"
            ? section.render({ state, updateState })
            : section.fields.map(
                (
                  { FieldContainer = BaseFieldContainer, ...field },
                  fieldIndex
                ) => (
                  <FieldContainer key={fieldIndex} style={field.style}>
                    {InputMapper({
                      field,
                      state,
                      handleChange,
                      setDataOpen
                    })}
                    {typeof field.renderAfter === "function" ? (
                      field.renderAfter()
                    ) : (
                      <></>
                    )}
                    {typeof field.isValid === "function" && (
                      <ErrorMessage>
                        {field.isValid(state.data[field.name])}
                      </ErrorMessage>
                    )}
                  </FieldContainer>
                )
              )}
        </FieldsContainer>
      </Section>
    </React.Fragment>
  );
};

const Section = styled.div``;
const DefaultTitle = styled.p`
  font-size: 18px;
`;

const DefaultSubtitle = styled.p`
  color: #909090;
  font-size: 16px;
`;

const BaseFieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const BaseFieldContainer = styled.div`
  flex-basis: 30%;
`;

const CheckboxLabel = styled(Label)`
  margin-left: 10px !important;
  font-size: 12px;
`;

const ErrorMessage = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.danger};
`;

export default FormGenerator;
