import { getRequests } from "utils/requests";

const requests = getRequests("course");

const headers = {
  type: "CRUD",
  options: {
    name: "Cursos",
    route: "/course",
    requests,
    tableOptions: {
      isEditable: true,
      isDeletable: true,
      displayItemsPerPage: false,
      isSearchable: true,
    },
  },
  sections: [
    {
      fields: [
        { property: "name", label: "Nombre",
          tableOptions: {
              isSortable: true,
              sortProperty: "nameOrder"
          } 
        },
        { property: "description", label: "Descripción",
          tableOptions: {
              isSortable: true,
              sortProperty: "descriptionOrder"
          } 
        },
      ],
    },
  ],
};

export default headers;
