import { getRequests } from "utils/requests";
import { convertToSlug } from 'utils/string-utils';

const requests = getRequests("category/company");

const headers = {
  type: "CRUD",
  options: {
    name: "Categorías Empresa",
    route: { path: "/categoriescompany" },
    requests,
    tableOptions: {
      isEditable: true,
      isDeletable: true,
      displayItemsPerPage: false,
      isSearchable: true,
    },
    upsertOptions: {
      itemBeforeSave: (data) => {
        let itemData = {
          ...data,
          slug: data.slug == null || data.slug == '' ? convertToSlug(data.name || ''): data.slug,
        };
        debugger;
          return Promise.resolve({
            ...itemData,
          })
      },
    },
  },
  sections: [
    {
      fields: [
        {
          label: "Nombre",
          property: "name",
          tableOptions: {
              isSortable: true,
          }
        },
        {
          label: "URL",
          property: "slug",
          tableOptions: {
              isSortable: true,
          }
        },
      ],
    },
  ],
};

export default headers;