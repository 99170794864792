import styled from "styled-components";
import { defaultTheme, themable } from "../Theme/";

const List = styled.ul`
  ${({ theme, color }) => ` 
    color:  ${themable({
      theme,
      props: [color, "default"],
    })}; 
  `}
  padding: 10px 0;
  list-style: none;
`;

List.defaultProps = {
  theme: defaultTheme,
};

export default List;
