import { getRequests } from "utils/requests";
import { DateTime } from "luxon";
import SocialAccountBuilder from './SocialAccountBuilder'

const requests = getRequests("socialaccounts");


const delay = ms => new Promise(res => setTimeout(res, ms));

const renderButtonLogin = (state, refreshState,getData) => {
  return <SocialAccountBuilder state={state} refreshStateComponent={refreshState} getDataComponent={getData}/>
}

const headers = {
  type: "CRUD",
  options: {
    name: "Gestión de cuentas",
    route: "/socialaccounts",
    requests,
    tableOptions: {
      isEditable: false,
      isDeletable: true,
      isCreatable: false,
      displayItemsPerPage: false,
      children: renderButtonLogin
    },
  },
  sections: [
    {
      fields: [
        { property: "type", label: "tipo", tableOptions:{isSortable:true} },
        { property: "name", label: "Nombre", tableOptions:{isSortable:true} },
        { property: "date", label: "Caduca", tableOptions:{isSortable:true, sortProperty: "createDate", format: (field, state) => {  return field.finishDate ?  DateTime.fromISO(field.finishDate).toFormat("dd/MM/yyyy") : field.createDate? DateTime.fromISO(field.createDate).plus({ months: 2 }).toFormat("dd/MM/yyyy")  : "";  }} },
        { property: "apiKey", label: "Api Key", tableOptions:{show:false} },
      ],
    },
  ],
};

export default headers;
