import { getRequests } from "utils/requests";
import { TwitterCallback } from 'react-twitter-login-oauth2';

/*const options = [
  { value: "DULCESNOTICIAS", label: "Dulces Noticias" },
  { value: "GUIADULCESAPERITIVOS", label: "Guia dulces y aperitivos" },
];*/

const headers = {
  type: "Page",
  options: {
    name: "Twitter Success",
    route: {
      path: "/twitter",
      unAuth: false,
      auth: true,
      exact: true,
      component: (props) => <TwitterCallback redirect="/socialaccounts" />,
    },
  },
};

export default headers;
