import { getRequests } from "utils/requests";

const requests = getRequests("teamCategory");

const headers = {
  type: "CRUD",
  options: {
    name: "Departamentos",
    route: "/teamcategory",
    requests,
    tableOptions: {
      isSearchable: true,
      isEditable: true,
      isDeletable: true,
      displayItemsPerPage: false,
    },
  },
  sections: [
    {
      fields: [
        { property: "name", label: "Nombre",
        tableOptions: {
            isSortable: true,
        }  
      },
      { property: "order", label: "Orden",
        tableOptions: {
            isSortable: true,
        }  
      }
     ],
    },
  ],
};

export default headers;
