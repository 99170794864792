import styled from "styled-components";
import { getRequests,findAllRequest, findAllRequestOrderName, exportOrdersExcel } from "utils/requests";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { Table, Modal, Text, Button, Loader } from "../../bluejay-ui";
import { reduce } from "lodash";

const PanelModalBill = styled.div`
  display: flex;
  color: black;
  flex: 0 1 100%;
  min-height: 30px;
`;

const TitleColumn = styled.div`
  display: flex;
  color: black;
  text-align: left;
  flex: 0 1 30%;
  font-weight: bold;
`;

const ValueColumn = styled.div`
  display: flex;
  color: black;
`;


const requests = getRequests("contactuser");


const headers = {
  type: "CRUD",
  options: {
    name: "Formulario de contacto",
    route: "/contact",
    requests,
    tableOptions: {
      isEditable: false,
      isDeletable: true,
      displayItemsPerPage: false,
      isSearchable: false,
      isCreatable: false,
    },
    upsertOptions: {
      onMount: () =>
        Promise.all([
          findAllRequest('contactuser', true)(),
        ]).then(
          ([
            { data: contactuser },
          ]) => ({
            contactuser,
          }),
        )
      },
  },
  sections: [
    {
      fields: [
        { property: "createDate", label: "Fecha",tableOptions: { isSortable: false, }},
        { property: "topic", label: "Asunto",tableOptions: { isSortable: false, }},
        { property: "email", label: "Email",tableOptions: { isSortable: false, }},
        { property: "name", label: "Nombre",tableOptions: { isSortable: false, }},
        { property: "company", label: "Compañia",tableOptions: { isSortable: false, }},
        { property: "text", label: "Texto", tableOptions: { isSortable: false } },

      ],
    },
  ],
};

export default headers;
