import { DateTime } from "luxon";
import React from "react";
import { getUrlImage } from "./string-utils";

export const image = ({
  label = "Imagen",
  property = "imageURL",
  showInTable = true,
  tableOptions,
  type = "image",
  upsertOptions,
}) => ({
  label,
  property,
  type,
  tableOptions: {
    show: showInTable,
    format: (item, property) => {
      return typeof property !== "undefined" && property !== null ? (
        <img alt="" style={{ width: 200 }} src={getUrlImage(property)} />
      ) : (
        <></>
      );
    },
    ...tableOptions,
  },
  upsertOptions: {
    format: ({ state, field }) => {
      const srcImage =
        state?.data && typeof field === "object" && field?.property
          ? state?.data?.[field?.property] || ""
          : "";

      const isFile = srcImage?.size && srcImage?.name;
      return state.data[field?.property] && !isFile
        ? getUrlImage(srcImage)
        : srcImage;
    },
    beforeSave: ({ state, field }) => {
      return state[field?.property]?.name;
    },
    ...upsertOptions,
  },
});


export const imageLibrary = ({
  label = "Imagen",
  property = "imageURL",
  showInTable = true,
  tableOptions,
  type = "imagelibrary",
  upsertOptions,
}) => ({
  label,
  property,
  type,
  tableOptions: {
    show: showInTable,
    format: (item, property) => {
      return typeof property !== "undefined" && property !== null ? (
        <img alt="" style={{ width: 200 }} src={getUrlImage(property)} />
      ) : (
        <></>
      );
    },
    ...tableOptions,
  },
  upsertOptions: {
    format: ({ state, field }) => {
      const srcImage =
        state?.data && typeof field === "object" && field?.property
          ? state?.data?.[field?.property] || ""
          : "";

      const isFile = srcImage?.size && srcImage?.name;
      return state.data[field?.property] && !isFile
        ? getUrlImage(srcImage)
        : srcImage;
    },
    ...upsertOptions,
  },
});


export const imageSeo = {
  label: "Imagen SEO",
  property: "imageSeo",
  type: "image",
  tableOptions: {
    format: (item, property) => {
      console.log(process.env.REACT_APP_API_URL, property);
      return typeof property !== "undefined" ? (
        <img
          alt=""
          style={{ width: 200 }}
          src={`${process.env.REACT_APP_API_URL}image/${property}`}
        />
      ) : (
        <></>
      );
    },
  },
  upsertOptions: {
    format: ({ state, field }) =>
      state.data[field.property]
        ? `${process.env.REACT_APP_API_URL}image/${state.data[field.property]}`
        : "",
    beforeSave: ({ state, field }) => {
      return state[field.property]?.name;
    },
  },
};

export const categories = {
  label: "Categorías",
  property: "categories",
  type: "multiselect",
  tableOptions: { show: false },
  relation: {
    name: "categories",
    isMulti: true,
    sort: true,
    primaryKey: "id",
    nameProps: ["name"],
  },
};

export const magazines = {
  label: "Publicación",
  property: "magazines",
  type: "multiselect",
  tableOptions: { show: false },
  relation: {
    name: "magazines",
    isMulti: true,
    sort: true,
    primaryKey: "id",
    nameProps: ["title"],
  },
};

export const tags = {
  label: "Tags",
  property: "tags",
  type: "multiselect",
  tableOptions: { show: false },
  relation: {
    name: "tags",
    isMulti: true,
    sort: true,
    primaryKey: "id",
    nameProps: ["name"],
  },
};

export const gallery = {
  label: "Galería",
  property: "gallery",
  type: "multiselect",
  isClearable: true,
  tableOptions: { show: false },
  relation: {
    name: "gallery",
    isMulti: false,
    sort: true,
    forceSendItem: true,
    primaryKey: "id",
    nameProps: ["name"],
  },
};

export const dateCreate = {
  label: "Fecha de publicación",
  property: "dateCreate",
  type: "datetime-local",
  tableOptions: {
    show: true,
    isSortable: true,
    format: (field, state) => {
      return state
        ? DateTime.fromISO(state).toFormat("dd/MM/yyyy HH:mm")
        : "";
    },
  },
  upsertOptions: {
    value: DateTime.fromISO(DateTime.now().plus({ days: 1 })).toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss'Z"),
    beforeSave: ({ state, field }) => {
      return state?.dateCreate
        ? DateTime.fromISO(state?.dateCreate)
            .toUTC()
            .toFormat("yyyy-MM-dd'T'HH:mm:ss'Z")
        : "";
    },
  },
};
