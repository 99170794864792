import React, { useRef, useState } from "react";
import {
  MultiSelect,
  TextArea,
  Checkbox,
  Input,
  Label,
  Button,
} from "../../../../bluejay-ui";
import { Editor } from "@tinymce/tinymce-react";
import ImageUploader from "neoco/image-uploader";
import Imagelibrary from "neoco/form/utils/inputs/imagelibrary/imagelibrary";
import { multiselect, idDestino } from "./multi-select/multi-select";
import date from "./date/date";
import alertify from "alertifyjs";
import {
  AsyncPaginate as DefaultAsyncPaginate,
  withAsyncPaginate,
} from "react-select-async-paginate";
import Creatable from "react-select/creatable";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/fontawesome-free-solid'

const AsyncPaginate = withAsyncPaginate(Creatable);

function countWords(s) {
  return s.trim().split(/\s+/).length
}

export const InputMapper = ({ field, state, handleChange = () => { }, setDataOpen = () =>  {}}) => {
  const format = field.upsertOptions?.format || defaultFormat;
  const [typepass,setTypepass] = useState("password");
  const inputRef = useRef(null);
  const defaultHandleChange = ({ target: { name, value } }) => {
    handleChange({ [name]: value });
  };
  const fieldHandleChange = field.upsertOptions?.onChange
    ? ({ target: { name, value } }) => {
      Promise.resolve(field.upsertOptions?.onChange({ name, value })).then(
        handleChange
      );
    }
    : defaultHandleChange;

  switch (field.type) {
    case "textarea":
      return (
        <Input
          as={TextArea}
          {...field}
          onChange={fieldHandleChange}
          rows={5}
          disabled={state.disabled}
          value={format({ state, field })}
        />
      );

    case "checkbox":
      return (
        <Checkbox
          onChange={({ target }) => {
            fieldHandleChange({
              target: {
                name: field.name || field.property,
                value: target.checked,
              },
            });
          }}
          checked={format({ state, field })}
          disabled={state.disabled}
          label={field.label}
        />
      );

    case "privacy":
      return (
        <><Checkbox
          onChange={({ target }) => {
            fieldHandleChange({
              target: {
                name: field.name || field.property,
                value: target.checked,
              },
            });
          }}
          checked={format({ state, field })}
          disabled={state.disabled}
          label={""}/><a href={field.url} target="_blank">{field.label}</a></>
      );

    case "email":
      return (
        <Input
          {...field}
          onChange={fieldHandleChange}
          value={format({ state, field })}
          disabled={state.disabled}
          pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
        />
      );

    case "translate": {
      const urlbase = field.entity?field.entity:"news";
      return (
        <Button
          disabled={state.disabled}
          style={{ backgroundColor: "red" }}
          onClick={async function onClick(e) {
            e.preventDefault();
            var idOrigen = state.data.id;
            if (idOrigen) {
              const loginRequest = (credentials) =>
                request(`login`, {
                  method: "POST",
                  body: JSON.stringify({
                    email: credentials.email,
                    pass: credentials.password,
                  }),
                }).then((res) => {
                  if (res.user) {
                    return Promise.resolve(res);
                  }
                });

              const request = (url, options) =>
                fetch(`${process.env.REACT_APP_API_URL}${url}`, {
                  ...options,
                  headers: getHeaders(options.headers),
                }).then((res) => {
                  switch (res.status) {
                    case 200:
                      return res.json();
                    case 403:
                      localStorage.removeItem("token");
                      localStorage.setItem("isLoggedIn", false);
                      localStorage.setItem("user", {});
                      window.location = "/";
                      return;
                    default:
                      return res
                        .json()
                        .then((error) =>
                          Promise.reject({ status: res.status, error })
                        );
                  }
                });

              const getHeaders = (headers) => {
                const nextHeaders = {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                  ...headers,
                };

                return Object.keys(nextHeaders)
                  .filter((key) => typeof nextHeaders[key] !== "undefined")
                  .reduce(
                    (reducer, key) => ({ ...reducer, [key]: nextHeaders[key] }),
                    {}
                  );
              };

              if (window.confirm("Se va a traducir la noticia, cualquier cambio no guardado se perderá"))
                await loginRequest({
                  email: "vitar@ticrevolution.com",
                  password: "sweetadmin",
                }).then((x) => {
                  request(
                    `admin/${urlbase}/translate/${idOrigen}`,
                    {
                      method: "PUT",
                      headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `Bearer ${x.token}`,
                      },
                    }
                  ).then(x => {
                    alertify.notify(
                      "Se ha traducido la noticia con éxito",
                      "success"
                    );
                    window.location.reload();
                  }).catch(x => alertify.notify(
                    "Se ha producido un error en la traducción",
                    "error"
                  ));
                });
            }
          }}
        >
          Traducir noticia
        </Button>
      );
    }
    case "nad": {
      const urlbase = field.entity?field.entity:"news";
      return !process.env.REACT_APP_SUBWEB ? <></> : (
        <Button
          disabled={state.disabled}
          style={{ backgroundColor: "orange" }}
          onClick={async function onClick(e) {
            e.preventDefault();
            var idOrigen = state.data.id;
            if (idOrigen) {
              const loginRequest = (credentials) =>
                request(`login`, {
                  method: "POST",
                  body: JSON.stringify({
                    email: credentials.email,
                    pass: credentials.password,
                  }),
                }).then((res) => {
                  if (res.user) {
                    return Promise.resolve(res);
                  }
                });

              const request = (url, options) =>
                fetch(`${process.env.REACT_APP_API_URL}${url}`, {
                  ...options,
                  headers: getHeaders(options.headers),
                }).then((res) => {
                  switch (res.status) {
                    case 200:
                      return res.json();
                    case 403:
                      localStorage.removeItem("token");
                      localStorage.setItem("isLoggedIn", false);
                      localStorage.setItem("user", {});
                      window.location = "/";
                      return;
                    default:
                      return res
                        .json()
                        .then((error) =>
                          Promise.reject({ status: res.status, error })
                        );
                  }
                });

              const getHeaders = (headers) => {
                const nextHeaders = {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                  ...headers,
                };

                return Object.keys(nextHeaders)
                  .filter((key) => typeof nextHeaders[key] !== "undefined")
                  .reduce(
                    (reducer, key) => ({ ...reducer, [key]: nextHeaders[key] }),
                    {}
                  );
              };

              if (window.confirm("Se va a copiar a la seccion de Noticias al detalle. Recordar que debereis ir a la sección correspondiente en caso de ser necesario añadir las categorías"))
                await loginRequest({
                  email: "vitar@ticrevolution.com",
                  password: "sweetadmin",
                }).then((x) => {
                  request(
                    `admin/${urlbase}/nad/${idOrigen}`,
                    {
                      method: "PUT",
                      headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `Bearer ${x.token}`,
                      },
                    }
                  ).then(x => {
                    window.location.href = field.redirect+x.data.id;
                  }).catch(x => alertify.notify(
                    "Se ha producido un error al copiar",
                    "error"
                  ));
                });
            }
          }}
        >
          Copiar a Noticias al detalle
        </Button>
      );
    }
    case "fusion": {
      return (
        <Button
          style={{ backgroundColor: "red" }}
          onClick={async function onClick(e) {
            var idOrigen = state.data.id;
            if (idDestino && idOrigen) {
              const loginRequest = (credentials) =>
                request(`login`, {
                  method: "POST",
                  body: JSON.stringify({
                    email: credentials.email,
                    pass: credentials.password,
                  }),
                }).then((res) => {
                  if (res.user) {
                    return Promise.resolve(res);
                  }
                });

              const request = (url, options) =>
                fetch(`${process.env.REACT_APP_API_URL}${url}`, {
                  ...options,
                  headers: getHeaders(options.headers),
                }).then((res) => {
                  switch (res.status) {
                    case 200:
                      return res.json();
                    case 403:
                      localStorage.removeItem("token");
                      localStorage.setItem("isLoggedIn", false);
                      localStorage.setItem("user", {});
                      window.location = "/";
                      return;
                    default:
                      return res
                        .json()
                        .then((error) =>
                          Promise.reject({ status: res.status, error })
                        );
                  }
                });

              const getHeaders = (headers) => {
                const nextHeaders = {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                  ...headers,
                };

                return Object.keys(nextHeaders)
                  .filter((key) => typeof nextHeaders[key] !== "undefined")
                  .reduce(
                    (reducer, key) => ({ ...reducer, [key]: nextHeaders[key] }),
                    {}
                  );
              };

              alert("Se van a fusionar las etiquetas");
              await loginRequest({
                email: "vitar@ticrevolution.com",
                password: "sweetadmin",
              }).then((x) => {
                request(
                  `admin/tag/unificarTag?idOrigen=${idOrigen}&idDestino=${idDestino}`,
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Accept: "application/json",
                      Authorization: `Bearer ${x.token}`,
                    },
                  }
                ).then((x) => {
                  debugger;
                  if (x == true)
                    alertify.notify(
                      "Se han fusionado las etiquetas con éxito",
                      "success"
                    );
                  else
                    alertify.notify(
                      "Se ha producido un error en la fusión",
                      "warning"
                    );
                });
              });
            }
          }}
        >
          Fusionar etiquetas
        </Button>
      );
    }

    case "image2": {
      const data = format({ state, field });
      return (
        <>
          <ImageUploader
            onChange={(data) => {
              fieldHandleChange({
                target: {
                  name: field.name || field.property,
                  value: data,
                },
              });
            }}
            source={{
              ...(typeof data === "string" && {
                uri: data,
                name: state.data[field.property],
              }),
              ...(typeof data === "object" && {
                file: data,
                name: state.data[field.property],
              }),
            }}
            label={field.label}
          />
          {field.id && (
            <div style={{ textAlign: "center" }}>
              <div>
                <Label
                  style={{ width: "100%", fontSize: "16px" }}
                  id={`orden${field.id}`}
                >
                  {field.id && field.orden ? 'Orden: ' + field.orden.toString() : "Sin orden establecido aún"}
                </Label>
              </div>
              <div>
                <Label
                  style={{ width: "100%", fontSize: "16px" }}
                  id={`footer${field.id}`}
                >
                  {field.id && field.footer ? field.footer.toString() : ""}
                </Label>
              </div>
              <Button
                style={{ width: "100%", marginTop: "15px" }}
                onClick={async function onClick(e) {
                  const resultado = prompt("Introduzca el nuevo orden");
                  field.orden = resultado;
                  document.getElementById(
                    `orden${field.id}`
                  ).innerHTML = resultado ? 'Orden: ' + resultado : "Sin orden establecido aún";
                  e.preventDefault();

                  const loginRequest = (credentials) =>
                    request(`login`, {
                      method: "POST",
                      body: JSON.stringify({
                        email: credentials.email,
                        pass: credentials.password,
                      }),
                    }).then((res) => {
                      if (res.user) {
                        return Promise.resolve(res);
                      }
                    });

                  const request = (url, options) =>
                    fetch(`${process.env.REACT_APP_API_URL}${url}`, {
                      ...options,
                      headers: getHeaders(options.headers),
                    }).then((res) => {
                      switch (res.status) {
                        case 200:
                          return res.json();
                        case 403:
                          localStorage.removeItem("token");
                          localStorage.setItem("isLoggedIn", false);
                          localStorage.setItem("user", {});
                          window.location = "/";
                          return;
                        default:
                          return res
                            .json()
                            .then((error) =>
                              Promise.reject({ status: res.status, error })
                            );
                      }
                    });

                  const getHeaders = (headers) => {
                    const nextHeaders = {
                      "Content-Type": "application/json",
                      Accept: "application/json",
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                      ...headers,
                    };

                    return Object.keys(nextHeaders)
                      .filter((key) => typeof nextHeaders[key] !== "undefined")
                      .reduce(
                        (reducer, key) => ({
                          ...reducer,
                          [key]: nextHeaders[key],
                        }),
                        {}
                      );
                  };

                  await loginRequest({
                    email: "vitar@ticrevolution.com",
                    password: "sweetadmin",
                  }).then((x) => {
                    request(`admin/image/update/${field.id}`, {
                      method: "PUT",
                      headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `Bearer ${x.token}`,
                      },
                      body: JSON.stringify({
                        id: field.id,
                        name: field.nombre,
                        url: field.nombre,
                        footer: field.footer,
                        orden: resultado,
                        gallery: {
                          id: field.idGaleria
                        }
                      }),
                    }).then((x) => {
                      debugger;

                      if (x.code == 202)
                      window.location.reload();
                      else
                        alertify.notify(
                          "Se ha producido un error al actualizar el orden",
                          "warning"
                        );
                    });
                  });
                }}
              >
                Actualizar orden
              </Button>
              <Button
                style={{ width: "100%", marginTop: "15px" }}
                onClick={async function onClick(e) {
                  const resultado = prompt("Introduzca el nuevo pie de foto");
                  field.footer = resultado;
                  document.getElementById(
                    `footer${field.id}`
                  ).innerHTML = resultado;
                  e.preventDefault();

                  const loginRequest = (credentials) =>
                    request(`login`, {
                      method: "POST",
                      body: JSON.stringify({
                        email: credentials.email,
                        pass: credentials.password,
                      }),
                    }).then((res) => {
                      if (res.user) {
                        return Promise.resolve(res);
                      }
                    });

                  const request = (url, options) =>
                    fetch(`${process.env.REACT_APP_API_URL}${url}`, {
                      ...options,
                      headers: getHeaders(options.headers),
                    }).then((res) => {
                      switch (res.status) {
                        case 200:
                          return res.json();
                        case 403:
                          localStorage.removeItem("token");
                          localStorage.setItem("isLoggedIn", false);
                          localStorage.setItem("user", {});
                          window.location = "/";
                          return;
                        default:
                          return res
                            .json()
                            .then((error) =>
                              Promise.reject({ status: res.status, error })
                            );
                      }
                    });

                  const getHeaders = (headers) => {
                    const nextHeaders = {
                      "Content-Type": "application/json",
                      Accept: "application/json",
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                      ...headers,
                    };

                    return Object.keys(nextHeaders)
                      .filter((key) => typeof nextHeaders[key] !== "undefined")
                      .reduce(
                        (reducer, key) => ({
                          ...reducer,
                          [key]: nextHeaders[key],
                        }),
                        {}
                      );
                  };

                  await loginRequest({
                    email: "vitar@ticrevolution.com",
                    password: "sweetadmin",
                  }).then((x) => {
                    request(`admin/image/update/${field.id}`, {
                      method: "PUT",
                      headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `Bearer ${x.token}`,
                      },
                      body: JSON.stringify({
                        id: field.id,
                        name: field.nombre,
                        url: field.nombre,
                        orden: field.orden,
                        footer: resultado,
                        gallery: {
                          id: field.idGaleria,
                        },
                      }),
                    }).then((x) => {
                      debugger;

                      if (x.code == 202)
                        alertify.notify(
                          "El pie se ha actualizado correctamente",
                          "success"
                        );
                      else
                        alertify.notify(
                          "Se ha producido un error al actualizar el pie",
                          "warning"
                        );
                    });
                  });
                }}
              >
                Actualizar pie de foto
              </Button>
            </div>
          )}
        </>
      );
    }

    case "image":
      const data = format({ state, field });

      return (
        <>
          <Label>{field.label}</Label>
          <ImageUploader
            onChange={(data) => {
              fieldHandleChange({
                target: {
                  name: field.name || field.property,
                  value: data,
                },
              });
            }}
            source={{
              ...(typeof data === "string" && {
                uri: data,
                name: state.data[field.property],
              }),
              ...(typeof data === "object" && {
                file: data,
                name: state.data[field.property],
              }),
            }}
            label={field.label}
          />
        </>
      );

      case "imagelibrary": {
        const datalibrary = format({ state, field });

        return (
          <>
            <Label>{field.label}</Label>
            <Imagelibrary
              onChange={(data) => {
                fieldHandleChange({
                  target: {
                    name: field.name || field.property,
                    value: data,
                  },
                });
              }}
              source={{
                ...(typeof datalibrary === "string" && {
                  uri: datalibrary,
                  name: state.data[field.property],
                }),
                ...(typeof datalibrary === "object" && {
                  file: datalibrary,
                  name: state.data[field.property],
                }),
              }}
              label={field.label}
              setDataOpen={setDataOpen}
            />
          </>
        );
      }


    case "file":
    case "file-pdf":
      const currentFilename = format({ state, field });
      const thereAreFile =
        currentFilename?.length || inputRef?.current?.files.length;
      const icon = field?.icon;

      return (
        <>
          <Label>{field.label}</Label>
          <div style={{ marginTop: 6 }}>
            <label className="custom-file-upload">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  disabled={state.disabled}
                  onClick={(e) => {
                    e.preventDefault();
                    inputRef?.current?.click();
                  }}
                >
                  {icon ? icon : "UPLOAD"}
                </Button>
                {thereAreFile ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "red",
                        marginLeft: 4,
                        height: 24,
                        width: 2,
                        marginRight: 6,
                        borderRadius: 4,
                      }}
                      onClick={(e) => {
                        e.target.value = null;
                        e.preventDefault();

                        inputRef.current.value = "";

                        fieldHandleChange({
                          target: {
                            name: field.name || field.property,
                            value: null,
                          },
                        });
                      }}
                    >
                      X
                    </Button>
                    <i className="fa fa-cloud-upload">
                      {inputRef?.current?.files &&
                        inputRef?.current?.files.length
                        ? inputRef?.current?.files[0].name
                        : currentFilename
                          ? currentFilename
                          : ""}
                    </i>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <input
                ref={inputRef}
                type="file"
                style={{ width: 0, height: 0 }}
                accept={field.type === "file-pdf" ? "application/pdf" : "*"}
                onChange={({ target }) => {
                  var files = target.files;
                  var filesArr = Array.prototype.slice.call(files);
                  if (filesArr.length) {
                    fieldHandleChange({
                      target: {
                        name: field.name || field.property,
                        value: filesArr[0],
                      },
                    });
                  } else {
                    fieldHandleChange({
                      target: {
                        name: field.name || field.property,
                        value: null,
                      },
                    });
                  }
                }}
              />
            </label>
          </div>
        </>
      );

    case "date": {
      const props = date({
        field,
        state,
        handleChange: fieldHandleChange,
        format,
      });
      return <Input {...props} disabled={state.disabled}/>;
    }

    case "datetime-local": {
      const props = date({
        field,
        state,
        handleChange: fieldHandleChange,
        format,
        toFormat: "yyyy-MM-dd'T'HH:mm",
      });
      return <Input {...props} disabled={state.disabled}/>;
    }

    case "multiselect": {
      const props = multiselect({
        field,
        state,
        handleChange: fieldHandleChange,
      });

      return (
        <MultiSelect
          {...field}
          isSearchable={true}
          placeholder={"Sin asignar"}
          isDisabled={field.disabled || state.disabled}
          {...props}
        />
      );
    }

    case "multiselect-paginated": {
      const props = multiselect({
        field,
        state,
        handleChange: fieldHandleChange,
        paginated: true,
      });
      const PaginatedInput = field.isCreatable
        ? AsyncPaginate
        : DefaultAsyncPaginate;
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Label htmlFor={field.id}>{field.label}</Label>
          <PaginatedInput
            {...field}
            isSearchable={true}
            placeholder={"Sin asignar"}
            isDisabled={field.disabled || state.disabled}
            {...props}
          />
        </div>
      );
    }

    case "seo": {
      function stripHtml(html) {
        var temporalDivElement = document.createElement("div");
        temporalDivElement.innerHTML = html;
        return (
          temporalDivElement.textContent || temporalDivElement.innerText || ""
        );
      }

      var h1 = 0;
      var h2 = 0;
      var charContenido = 0;
      var img = 0;
      var href = 0;
      var mismoDominio = 0;
      try {
        h1 = state.data.content.match(/<h1>/g || []).length;
      } catch { }
      try {
        h2 = state.data.content.match(/<h2>/g || []).length;
      } catch { }
      try {
        img = state.data.content.match(/<img/g || []).length;
      } catch { }
      try {
        href = state.data.content.match(/href=/g || []).length;
      } catch { }
      try {
        mismoDominio = state.data.content.match(
          /href="https:\/\/www.sweetpress.com\//g || []
        ).length;
      } catch { }
      try {
        var contenttext = stripHtml(state.data.content);
        charContenido = countWords(contenttext);
      } catch { }

      if(state.data.title != undefined && state.data.title.length > 20 && state.data.title.length < 70){
        h1 += 1;
      }

      if(state.data.imageprin != undefined && state.data.imageprin.length > 2){
        img += 1;
      }

      var puntuacion = 0;
      if (state.data.titleSeo != undefined)
        if (state.data.titleSeo.length > 20 && state.data.titleSeo.length < 70) puntuacion++;
      if (state.data.descriptioSeo != undefined)
        if (state.data.descriptioSeo.length > 157) puntuacion++;
      if (h1 == 1) puntuacion++;
      if (h2 > 0) puntuacion++;
      if (charContenido > 800) puntuacion += 2;
      if (img > 0) puntuacion++;
      if (state.data.descriptioSeo != undefined)
        if (state.data.gallery) puntuacion++;
      if (mismoDominio > 0) puntuacion++;
      if (href - mismoDominio > 0) puntuacion++;

      var semaforo;
      if (puntuacion <= 2) semaforo = "red";
      if (puntuacion > 2 && puntuacion < 5) semaforo = "yellow";
      if (puntuacion >= 5) semaforo = "green";

      return (
        <>
          <Label>Puntuación SEO: {puntuacion}</Label>
          <div
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "25px",
              backgroundColor: semaforo,
              marginTop: "10px",
              marginBottom: "10px",
            }}
          ></div>

          <Input
            as={TextArea}
            {...field}
            onChange={fieldHandleChange}
            rows={9}
            disabled={"disabled"}
            value={
              (state.data.titleSeo
                ? `Título SEO (debe tener una longitud minima de 20 caracteres y maxima de 70): ${state.data.titleSeo.length} caracteres \n`
                : "Título SEO (debe tener una longitud minima de  20 caracteres y maxima de 70): 0 caracteres \n") +
              (state.data.descriptioSeo
                ? `Descripción SEO (debe tener una longitud minima de 157 caracteres): ${state.data.descriptioSeo.length} caracteres \n`
                : "Descripción SEO (debe tener una longitud minima de 157 caracteres): 0 caracteres \n") +
              (state.data.content
                ? `Cantidad de H1 (Titulo de la noticia debe tener una longitud minima de 20 caracteres y maxima de 70, y debe existir solo 1): ${h1} ocurrencias con longitud correcta \n`
                : "Cantidad de H1 (Titulo de la noticia debe tener una longitud minima de 20 caracteres y maxima de 70, y debe existir solo 1): 0 ocurrencias con longitud correcta \n") +
              (state.data.content
                ? `Cantidad de H2: ${h2} ocurrencias \n`
                : "Cantidad de H2: 0 ocurrencias \n") +
              (state.data.content
                ? `Palabras contenido (debe tener al menos 800 palabras): ${charContenido} palabras \n`
                : "Longitud contenido (debe tener al menos 800 palabras): 0 palabras \n") +
              (state.data.content
                ? `Cantidad de imágenes (debe tener al menos 1 imagen): ${img} imágenes \n`
                : "Cantidad de imágenes (debe tener al menos 1 imagen): 0 imágenes \n") +
              (state.data.content
                ? `Enlaces al mismo dominio: ${mismoDominio} enlaces \n`
                : "Enlaces al mismo dominio: 0 enlaces \n") +
              (state.data.content
                ? `Enlaces otros dominios: ${href - mismoDominio} enlaces \n`
                : "Enlaces otros dominios: 0 enlaces \n") +
              (state.data.gallery ? `Galería asociada` : "Sin galería asociada")
            }
          />
        </>
      );
    }

    case "html": {
      const value =
        typeof field.value === "function"
          ? field.value({ field, state })
          : state.data[field.property];

      const loginRequest = (credentials) =>
        request(`login`, {
          method: "POST",
          body: JSON.stringify({
            email: credentials.email,
            pass: credentials.password,
          }),
        }).then((res) => {
          if (res.user) {
            return Promise.resolve(res);
          }
        });

      const request = (url, options) =>
        fetch(`${process.env.REACT_APP_API_URL}${url}`, {
          ...options,
          headers: getHeaders(options.headers),
        }).then((res) => {
          switch (res.status) {
            case 200:
              return res.json();
            case 403:
              localStorage.removeItem("token");
              localStorage.setItem("isLoggedIn", false);
              localStorage.setItem("user", {});
              window.location = "/";
              return;
            default:
              return res
                .json()
                .then((error) => Promise.reject({ status: res.status, error }));
          }
        });

      const getHeaders = (headers) => {
        const nextHeaders = {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          ...headers,
        };

        return Object.keys(nextHeaders)
          .filter((key) => typeof nextHeaders[key] !== "undefined")
          .reduce(
            (reducer, key) => ({ ...reducer, [key]: nextHeaders[key] }),
            {}
          );
      };


      return (
        <>
          <Label>{field.label}</Label>
          <div style={{ marginTop: 10, marginBottom: 20 }}>
            <Editor
              //apiKey="n88s4udlac7cufxa0cy94rut0pr8v2osg97jfmc26xy682ie"
              apiKey="mf80in0vxae54flf5o51s2iybufpbzdxs34hz7gy7h0ejaoq"
              disabled={state.disabled}
              init={{
                language: "es",
                selector: "textarea#full-featured",
                plugins:
                  "print preview importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount textpattern noneditable help charmap quickbars emoticons code cita",
                menubar:
                  "file edit view insert format tools table tc help code",
                toolbar:
                  "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect btnCita| alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment",
                autosave_ask_before_unload: true,
                autosave_interval: "30s",
                autosave_prefix: "{path}{query}-{id}-",
                autosave_restore_when_empty: false,
                autosave_retention: "2m",
                image_advtab: true,
                images_upload_url: field.imageUploadURL,
                images_upload_handler: field.onImageUpload,
                importcss_append: true,
                height: 600,
                image_caption: true,
                quickbars_selection_toolbar:
                  "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                noneditable_noneditable_class: "mceNonEditable",
                toolbar_mode: "sliding",
                content_style: ".mymention{ color: gray; } figcaption{  position: absolute; bottom: 0; font-size: 0.8em;width: 100%; text-align: center !important;  padding: 0 10px;} figure{ position: relative; padding-bottom: 1em; }",
                contextmenu:
                  "link image imagetools table configurepermanentpen",
                a11y_advanced_options: true,
                image_advtab: true,
                external_plugins: {
                  cita: "/plugins/cita/plugin.min.js",
                },
                file_picker_callback: function (callback, value, meta) {
                  if (setDataOpen){
                      setDataOpen({
                        callback: callback,
                        value: value,
                        meta: meta,
                        open:true
                      })
                  }
                },
                ...field.controlProps?.init,
              }}
              value={value}
              onEditorChange={(content) =>
                fieldHandleChange({
                  target: {
                    name: field.name || field.property,
                    value: content,
                  },
                })
              }
            />
          </div>
        </>
      );
    }
    case "password":{
      return (
        <><Input
          {...field}
          onChange={fieldHandleChange}
          disabled={state.disabled}
          value={format({ state, field })}
          type={typepass}
        />
        <FontAwesomeIcon icon={faEye}  style={{width: "3em", display: "block",  marginLeft: "calc(100% - 3em)",  marginTop: "-2em",  fontSize: "1.2em", cursor: "pointer"}} onClick={() => {typepass == "password" ? setTypepass("text"):setTypepass("password")}}/>
        </>
        );
      }
    default:
      return (
        <Input
          {...field}
          onChange={fieldHandleChange}
          disabled={state.disabled}
          value={format({ state, field })}
        />
      );
  }
};

const defaultFormat = ({ state, field }) =>
  state.data[field.name || field.property];
