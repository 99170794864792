import { image, imageLibrary } from "utils/fields";
import {
  getRequests,
  findRequest,
  getCompanyWithSearch,
  getModelWithPaginationSearch,
  uploadFileRequest,
  findAllRequest,
  mediolibraryrequest,
} from "utils/requests";

const requests = getRequests("company");

const headers = {
  type: "CRUD",
  options: {
    name: "Empresas",
    route: { path: "/companies" },
    requests: {
      ...requests,
      findRequest: getCompanyWithSearch(),
      uploadFileRequest,
      mediolibraryrequest: mediolibraryrequest,
      medialibrary: getRequests("medialibrary"),
    },
    tableOptions: {
      isSearchable: true,
      isEditable: true,
      isDeletable: true,
      displayItemsPerPage: false,
    },
    upsertOptions: {
      onMount: () =>
        Promise.all([
          findAllRequest("category/company", true)(),
          findRequest("tag", true)(),
          findRequest("category", true)(),
        ]).then(
          ([
            { data: categories },
            { data: tags },
            { data: categoryCompany },
          ]) => ({
            categories,
            tags,
            categoryCompany,
          })
        ),
    },
  },
  sections: [
    {
      fields: [
        {
          label: "Título",
          property: "title",
          tableOptions: {
            isSortable: true,
          }
        },
        {
          label: "Empresa de pago",
          property: "isPayCompany",
          type: "checkbox",
          tableOptions: {
            isSortable: true,
            show: true,
            filter: {
              values: [
                { value: true, label: "Si" },
                { value: false, label: "No" },
              ],
            },
            format: (item, value) => (value ? "Si" : "No"),
          },
        },
        {
          label: "Dirección",
          property: "address",
          tableOptions: {
            isSortable: true,
          }
        },
        {
          label: "Teléfono",
          property: "phone",
          type: "phone",
          tableOptions: {
            isSortable: true,
          }
        },
        {
          label: "Email",
          property: "email",
          type: "email",
          tableOptions: {
            isSortable: true,
          }
        },
        {
          label: "Web",
          property: "web",
          tableOptions: {
            isSortable: true,
          }
        },
        {
          label: "Categorías Directorio",
          property: "categories",
          type: "multiselect",
          tableOptions: { show: false },
          relation: {
            name: "categories",
            isMulti: true,
            sort: true,
            primaryKey: "id",
            nameProps: ["name"],
          },
        },
        {
          label: "Tags",
          property: "tags",
          type: "multiselect-paginated",
          isClearable: true,
          isCreatable: true,
          fetchAction: ({ page, search }) =>
            getModelWithPaginationSearch("tag")({
              pagination: { page },
              filter: { searchValue: search },
            }).then((res) =>
              res?.data.map((item) => ({
                label: item.name,
                value: item.slug,
                ...item,
              }))
            ),
          tableOptions: { show: false },
          relation: {
            name: "tags",
            isMulti: true,
            sort: true,
            primaryKey: "id",
            nameProps: ["name"],
          },
        },
        imageLibrary({ property: "imageURL", showInTable: false }),
      ],
    },
  ],
};

export default headers;
