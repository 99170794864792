import React from "react";
import styled from "styled-components";
import { defaultTheme } from "../Theme/";
import Icon from "../Icon";
import Text from "../Text";

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const TooltipContent = styled(Text)`
  margin-bottom: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  transition: opacity 0.3s;
  width: max-content;
  max-width: 400px;
  right: 0;
  visibility: hidden;
  padding: 15px 20px;
  color: #4b4b4b;

  ${TooltipContainer}:hover & {
    visibility: visible;
    opacity: 1;
  }
`;

const Tooltip = (props) => {
  const {
    iconSrc,
    children,
    TooltipContainer: CustomTooltipContainer = TooltipContainer,
    iconProps = {},
    textProps = {},
  } = props;

  return (
    <CustomTooltipContainer {...props}>
      <Icon iconSrc={iconSrc} {...iconProps} />
      <TooltipContent {...textProps}>{children}</TooltipContent>
    </CustomTooltipContainer>
  );
};

Tooltip.defaultProps = {
  theme: defaultTheme,
};

export default Tooltip;
