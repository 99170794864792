import {
  getRequests,
  findRequest,
  findAllRequest,
  findAllRequestOrderTitle,
  findAllRequestOrderName,
  uploadFileRequest,
  getNewByType,
  controlNewsDefaultProps,
  deleteRequest,
  upsertRequest,
  getModelWithPaginationSearch,
  loginRequest,
  mediolibraryrequest,
  lockNew,
  unlockNew,
  testuser,
} from 'utils/requests';

import {
  categories,
  magazines,
  gallery,
  image,
  imageLibrary,
  dateCreate,
} from 'utils/fields';
import { convertToSlug } from 'utils/string-utils';
import { DateTime } from 'luxon';
import SocialAccountsTable from '../../compontents/SocialAccountsTable';
var Promise = require('bluebird');

const requests = getRequests('news');

var neccesaryUnlock = false;

const headers = {
  type: 'CRUD',
  options: {
    name: 'Noticias al detalle',
    route: { path: '/subnews', home: true },
    mount: (id) => {
      console.log("Entra al abrir => " + id)
    },
    unmount: (id) => {
      if(id){
        unlockNew()(id).then(()=>{});
      }
    },
    allowautosave: () => {
      return neccesaryUnlock;
    },
    requests: {
      ...requests,
      uploadFileRequest,
      findRequest: getNewByType({ type: 'SUBWEBNOTICIA' }),
      upsertRequest: controlNewsDefaultProps(requests),
      mediolibraryrequest: mediolibraryrequest,
      medialibrary: getRequests("medialibrary"),
    },
    tableOptions: {
      isEditable: true,
      isDeletable: true,
      displayItemsPerPage: false,
      isSearchable: true,
      isFilterable: true,
    },
    upsertOptions: {
      onMount: () =>
        Promise.all([
          findAllRequestOrderName('categorysubnews', true)(),
          findAllRequestOrderTitle('magazine', true)(),
          findAllRequestOrderTitle('magazine', true)(),
          findRequest('tag', true)(),
          findRequest('tag', true)(),
          findAllRequestOrderName('gallery', true)(),
          findAllRequestOrderName('priceType', true)(),
        ]).then(
          ([
            { data: categories },
            { data: magazines },
            { data: inMagazine },
            { data: tags },
            { data: relatedTags },
            { data: gallery },
            { data: priceTypes },
          ]) => ({
            categories,
            magazines,
            inMagazine,
            tags,
            relatedTags,
            gallery,
            priceTypes,
          }),
        ),
      itemBeforeSave: (data) => {
        let itemData = {
          ...data,
          state: data?.state?.value ? data.state.value : data.state,
          canonicalLink: data?.canonicalLink?.value || data.canonicalLink,
          filemakerSeccion: data?.filemakerSeccion?.value || data?.filemakerSeccion
        };
        return Promise.mapSeries(data?.newsSocialPublications?.delete, (item) =>
          deleteRequest(
            'socialnews',
            true,
          )({
            id: item,
          }),
        )
          .then(() => {
            return Promise.mapSeries(
              data?.newsSocialPublications?.new,
              (item) => {
                var data = item;
                delete data.id;

                return upsertRequest(
                  'socialnews',
                  true,
                )({
                  ...data,
                  dateToPublish: data?.dateToPublish && DateTime.fromISO(item?.dateToPublish) ? DateTime.fromISO(data?.dateToPublish, {zone : 'local'}).toUTC().toFormat(`yyyy-MM-dd'T'HH:mm:00'Z'`):null,
                  dateToPublishTwo: data?.dateToPublishTwo && DateTime.fromISO(item?.dateToPublishTwo)? DateTime.fromISO(data?.dateToPublishTwo, {zone : 'local'}).toUTC().toFormat(`yyyy-MM-dd'T'HH:mm:00'Z'`):null,
                  news: { id: itemData.id },
                });
              },
            ).then(() => {
              return Promise.mapSeries(
                data?.newsSocialPublications?.modify,
                (item) => {
                  return upsertRequest(
                    'socialnews',
                    true,
                  )({
                    ...item,
                    dateToPublish: item?.dateToPublish && DateTime.fromISO(item?.dateToPublish) ? DateTime.fromISO(item?.dateToPublish, {zone : 'local'}).toUTC().toFormat(`yyyy-MM-dd'T'HH:mm:00'Z'`) : null,
                    dateToPublishTwo: item?.dateToPublishTwo && DateTime.fromISO(item?.dateToPublishTwo)? DateTime.fromISO(item?.dateToPublishTwo, {zone : 'local'}).toUTC().toFormat(`yyyy-MM-dd'T'HH:mm:00'Z'`): null,
                    news: { id: itemData.id },
                  });
                },
              ).then(() =>
                
                Promise.resolve({
                  ...itemData,
                  slug: itemData.slug == null || itemData.slug == '' ? convertToSlug(itemData.title || ''): itemData.slug,
                  urlSeo: itemData.slug == null || itemData.slug == '' ? convertToSlug(itemData.titleSeo || ''): itemData.slug,
                }),
              );
            });
          })
          .catch(() => {
            return Promise.resolve({
              ...itemData,
              slug: convertToSlug(itemData.title || ''),
              urlSeo: convertToSlug(itemData.titleSeo || ''),
            });
          });
      },
      afterSave: ({ data, state, updateState }) => {
        updateState({
          ...state,
          data: {
            ...data,
            newsSocialPublications: { new: [], delete: [], modify: [] },
          },
        });
      },
    },
  },
  autosave:true,
  controluser:true,
  testcontrol:(data) => {
    const myname = JSON.parse(localStorage.getItem("user"))['name'];
      if(data.inEdit && data.userEditable && data.userEditable != myname){
        return true;
      } else {
        lockNew()(data['id']).then(()=>{});
      }
  },
  lockData: (id) => {
    lockNew()(id).then(()=>{});
  },
  testuser:testuser,
  sections: [
    {
      fields: [
        {
          label: 'Visible',
          property: 'isVisible',
          type: 'checkbox',
          tableOptions: { show: false },
          upsertOptions: {
            value: true,
            show: false,
          },
        },
        {
          label: 'Estado noticia',
          property: 'state',
          type: 'multiselect',
          showInTable: true,
          isClearable: false,
          upsertOptions: {
            value: "BORRADOR",
          },
          relation: {
            forceSendItem: true,
            name: 'state',
            isMulti: false,
            sort: true,
            options: [
              { value: 'PUBLICADO', label: 'PUBLICADO' },
              { value: 'BORRADOR', label: 'BORRADOR' },
            ],
          },
          tableOptions: {
            isSortable: true
          }
        },
        {
          label: 'Título',
          property: 'title',
          required: true,
          tableOptions: {
            isSortable: true,
            sortProperty: "titleOrder"
          }
        },
        {
          label: 'Visitas',
          property: 'visitCounter',
          type: 'number',
          upsertOptions: {
            show: false,
          },
          tableOptions: {
            isSortable: true
          }
        },
        {
          label: 'Título SEO',
          property: 'titleSeo',
          tableOptions: {
            show: false,
          },
        },
        {
          label: 'URL: ' + process.env.REACT_APP_FRONT_URL + "noticiasaldetalle/noticia/",
          property: 'slug',
          tableOptions: {
            show: false,
          },
        },
        {
          label: 'Link Canónico',
          property: 'canonicalLink',
          type: 'multiselect',
          tableOptions: {
            show: false,
          },
          relation: {
            forceSendItem: true,
            name: 'canonicalLink',
            isMulti: false,
            sort: true,
            isCreatable: true,
            options: [
              { value: 'sweetpress', label: 'SweetPress' },
              { value: 'techpress', label: 'TechPress' },
            ],
          },
        },
        {
          label: 'Palabras clave SEO (separ las palabras con , )',
          property: 'keywords',
          tableOptions: {
            show: false,
          },
        },
        {
          label: 'Descripción SEO',
          property: 'descriptioSeo',
          type: 'textarea',
          style: {
            flexBasis: '100%',
            maxWidth: '100%',
          },
          tableOptions: {
            show: false,
          },
        },
        {
          type: 'seo',
          tableOptions: {
            show: false,
          },
          style: {
            flexBasis: '100%',
            maxWidth: '100%',
          },
        },
        imageLibrary({ label: 'Imagen', property: 'imageprin' }),
        imageLibrary({
          label: 'Imagen SEO',
          property: 'imageSeo',
          showInTable: false,
        }),
        {
          label: 'Noticia Principal Home',
          property: 'homePrin',
          type: 'checkbox',
          tableOptions: { format: (item, value) => (value ? 'Si' : 'No'), isSortable: true },
        },
        {
          label: 'Segunda Noticia Principal Home',
          property: 'homePrin2',
          type: 'checkbox',
          tableOptions: { format: (item, value) => (value ? 'Si' : 'No'), isSortable: true },
        },
        {
          label: 'Finalización segunda Noticia Principal',
          property: 'endHome2',
          type: 'datetime-local',
          tableOptions: {
            show: false,
          },
          upsertOptions: {
            beforeSave: ({ state, field }) => {
              return state?.endHome2
                ?  DateTime.fromISO(state?.endHome2)
                    .toUTC()
                    .toFormat("yyyy-MM-dd'T'HH:mm:ss'Z")
                : "";
            },
          },
        },
        {
          label: 'Noticia Patrocinada',
          property: 'sponsoring',
          type: 'checkbox',
          tableOptions: {
            show: true,
            isSortable: true,
            filter: {
              values: [
                { value: true, label: 'Si' },
                { value: false, label: 'No' },
              ],
            },
            format: (item, value) => (value ? 'Si' : 'No'),
          },
        },
        {
          label: 'Publi Noticia',
          property: 'publiNoticia',
          type: 'checkbox',
          tableOptions: { show: false },
        },
        {
          label: 'Finalización de publinoticia',
          property: 'endPubliNoticia',
          type: 'datetime-local',
          tableOptions: {
            show: false,
          },
          upsertOptions: {
            beforeSave: ({ state, field }) => {
              return state?.endPubliNoticia
                ?  DateTime.fromISO(state?.endPubliNoticia)
                    .toUTC()
                    .toFormat("yyyy-MM-dd'T'HH:mm:ss'Z")
                : "";
            },
          },
        },
        {
          label: 'Videoreportaje',
          property: 'videoReportaje',
          type: 'checkbox',
          tableOptions: { show: false },
        },
        {
          label: 'Ocultar en home',
          property: 'hiddenInHome',
          type: 'checkbox',
          tableOptions: { show: false },
        },
        {
          label: 'Finalización del patrocinado',
          property: 'endSponsoring',
          type: 'datetime-local',
          tableOptions: {
            show: false,
          },
          upsertOptions: {
            beforeSave: ({ state, field }) => {
              return state?.endSponsoring
                ?  DateTime.fromISO(state?.endSponsoring)
                    .toUTC()
                    .toFormat("yyyy-MM-dd'T'HH:mm:ss'Z")
                : "";
            },
          },
        },
        {
          label: 'Noticia Privada',
          property: 'isPrivate',
          type: 'checkbox',
          tableOptions: { format: (item, value) => (value ? 'Si' : 'No'), isSortable: true },
        },
        {
          label: 'En directo',
          property: 'isLive',
          type: 'checkbox',
          tableOptions: {
            show: true,
            isSortable: true,
            filter: {
              values: [
                { value: true, label: 'Si' },
                { value: false, label: 'No' },
              ],
            },
            format: (item, value) => (value ? 'Si' : 'No'),
          },
        },
        {
          label: 'Finalización del en directo',
          property: 'dateFinishLive',
          type: 'datetime-local',
          tableOptions: {
            show: false,
          },
          upsertOptions: {
            beforeSave: ({ state, field }) => {
              return state?.dateFinishLive
                ? DateTime.fromISO(state?.dateFinishLive)
                    .toUTC()
                    .toFormat("yyyy-MM-dd'T'HH:mm:ss'Z")
                : "";
            },
          },
        },
        {
          label: 'Visible en coneqtia',
          property: 'coneqtia',
          type: 'checkbox',
          tableOptions: { show: false },
        },
        {
          label: 'Url SEO',
          property: 'urlSeo',
          tableOptions: {
            show: false,
          },
          upsertOptions: {
            show: false,
          },
        },
        {
          label: 'Categorías',
          property: 'categories',
          type: 'multiselect',
          tableOptions: { show: false },
          relation: {
            name: 'categories',
            isMulti: true,
            sort: true,
            primaryKey: 'id',
            nameProps: ['name'],
          },
        },
        magazines,
        {
        label: "Aparece en:",
        property: "moreIn",
        tableOptions: {
          show: false,
        },
        },
        gallery,
        {
          label: 'Tipos',
          property: 'type',
          tableOptions: { show: false },
          upsertOptions: {
            value: 'SUBWEBNOTICIA',
            show: false,
          },
        },
        {
          label: 'Etiquetas',
          property: 'tags',
          type: 'multiselect-paginated',
          isClearable: true,
          isCreatable: true,
          fetchAction: ({ page, search }) => {
            return getModelWithPaginationSearch('tag')({
              pagination: { page },
              filter: { searchValue: search },
            }).then((res) =>
              res?.data.map((item) => ({
                label: item.name,
                value: item.slug,
                ...item,
              })),
            );
          },
          tableOptions: { show: false },
          relation: {
            name: 'tags',
            isMulti: true,
            sort: true,
            primaryKey: 'id',
            nameProps: ['name'],
          },
        },
        {
          label: 'Etiquetas relacionados',
          property: 'relatedTags',
          type: 'multiselect-paginated',
          fetchAction: ({ page, search }) =>
            getModelWithPaginationSearch('tag')({
              pagination: { page },
              filter: { searchValue: search },
            }).then((res) =>
              res?.data.map((item) => ({
                label: item.name,
                value: item.slug,
                ...item,
              })),
            ),
          tableOptions: { show: false },
          relation: {
            name: 'relatedTags',
            isMulti: true,
            primaryKey: 'id',
            nameProps: ['name'],
          },
        },
        {
          label: 'Tipo de noticia',
          property: 'priceType',
          type: 'multiselect',
          isClearable: true,
          tableOptions: { show: false },
          relation: {
            name: 'priceTypes',
            isMulti: false,
            sort: true,
            primaryKey: 'id',
            nameProps: ['name'],
            forceSendItem: true,
          },
        },
        dateCreate,
        {
          label: 'Autor',
          property: 'author',
          tableOptions: { show: false },
        },
        {
          label: 'FileMaker Empresa',
          property: 'filemakerEmpresa',
          tableOptions: { show: false },
        },
        {
          label: 'FileMaker Publicación',
          property: 'filemakerPublicacion',
          tableOptions: { show: false },
        },
        {
          label: 'FileMaker Sección',
          property: 'filemakerSeccion',
          type: 'multiselect',
          isClearable: true,
          tableOptions: { show: false },
          upsertOptions: {
            value: "",
          },
          relation: {
            forceSendItem: true,
            name: "filemakerSeccion",
            isMulti: false,
            isCreatable: false,
            options: [
              { value: 'Actualidad', label: 'Actualidad' },
              { value: 'Aperitivos', label: 'Aperitivos' },
              { value: 'Caja destacada Informe', label: 'Caja destacada Informe' },
              { value: 'Canal Impulso', label: 'Canal Impulso' },
              { value: 'Carta de la Directora', label: 'Carta de la Directora' },
              { value: 'Entrevista', label: 'Entrevista' },
              { value: 'Estudio de Mercado en el Punto de Venta', label: 'Estudio de Mercado en el Punto de Venta' },
              { value: 'Ferias', label: 'Ferias' },
              { value: 'Informe', label: 'Informe' },
              { value: 'Moderna y Conveniencia', label: 'Moderna y Conveniencia' },
              { value: 'Novedades', label: 'Novedades' },
              { value: 'Novedades con Licencia', label: 'Novedades con Licencia' },
              { value: 'Proveedores', label: 'Proveedores' },
              { value: 'Publinoticia', label: 'Publinoticia' },
              { value: 'Publireportaje', label: 'Publireportaje' },
              { value: 'Reportaje', label: 'Reportaje' },
            ],
          },
        },
        
        {
          label: 'Descripción Corta',
          property: 'shortDescription',
          mediolibraryrequest:mediolibraryrequest,
          type: 'html',
          // value: ({ state }) => state?.data?.shortDescription,
          style: {
            flexBasis: '100%',
            zIndex: 0,
          },
          tableOptions: {
            show: false,
          },
          controlProps: {
            init: {
              image_caption: true,
            },
          },
        },
        {
          label: 'Content',
          property: 'content',
          type: 'html',
          value: ({ state }) => state?.data?.content,
          style: {
            flexBasis: '100%',
            zIndex: 0,
          },
          tableOptions: {
            show: false,
          },
          controlProps: {
            init: {
              image_caption: true,
              images_upload_handler: (blobData, success, failure) =>
                uploadFileRequest({ file: blobData.blob() })
                  .then((response) =>
                    success(
                      process.env.REACT_APP_API_URL + 'image/' + response,
                    ),
                  )
                  .catch(failure),
              width: 665,
            },
          },
        },
        {
          tableOptions: { show: false },
          label: 'translate',
          type: 'translate',
        },
      ],
    },
    {
      render(props) {
        const { state, updateState } = props
          ? props
          : {
              state: {},
              updateState: () => {},
            };
        return (
          <SocialAccountsTable
            newId={state?.data?.id}
            initialText={
              state?.data?.title
                ? `${state?.data?.title || ''}`
                : ''
            }
            onChange={(newsSocialPublications) => {
              updateState({
                ...state,
                data: { ...state.data, newsSocialPublications },
              });
            }}
          />
        );
      },
      fields: [],
    },
    {
      render(props) {
        const { state, updateState } = props
          ? props
          : {
              state: {},
              updateState: () => {},
            };
        return (<p><a href={process.env.REACT_APP_FRONT_URL+"noticiasaldetalle/noticias/"+state?.data?.slug+"?token="+localStorage.getItem("token")} target="_blank" style={{background:"green", display:"inline-block", padding:"10px 20px", color:"white", textDecoration:"none"}}>Vista previa</a> <br/><small>Guarde antes los cambios para ver las modificaciones</small></p>);
      },
      fields: [],
    }
  ],
};

export default headers;
