import defaultTheme from "./theme";
import { getObjectProps } from "../utils";

export const themable = ({ theme = defaultTheme, props = [] }) => {
  const pointSeparatedWords = /([^\d\s]+\.)+[^\d\s]+/;
  const defaultValue = props[props.length - 1];

  const isObject = (prop) => typeof prop === "object";
  const arePointSeparatedWords = (prop) =>
    prop !== undefined && prop.match(pointSeparatedWords) === null;
  const getThemeProp = (prop) => getObjectProps({ data: theme, prop });

  const getRawPropFromList = (propsList) => {
    return propsList.find((prop) =>
      isObject(prop) || arePointSeparatedWords(prop) ? prop : getThemeProp(prop)
    );
  };

  const firstValidProp =
    props.length > 1 ? getRawPropFromList(props) : getThemeProp(props[0]);
  const themeProp = getThemeProp(firstValidProp);
  const propVal =
    isObject(firstValidProp) || !themeProp ? firstValidProp : themeProp;

  return propVal || defaultValue;
};
