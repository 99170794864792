import { dateCreate, image, imageLibrary } from "utils/fields";
import {findRequest,
  controlNewsDefaultProps, findAllRequest,findAllRequestOrderName,findAllRequestOrderTitle,
  getNewByType, getRequests, uploadFileRequest,
  mediolibraryrequest,getModelWithPaginationSearch
} from "utils/requests";
import { convertToSlug } from "utils/string-utils";

const requests = getRequests("news");

const headers = {
  type: "CRUD",
  options: {
    name: "Noticias Breves",
    route: { path: "/shortNews" },
    requests: {
      ...requests,
      uploadFileRequest,
      findRequest: getNewByType({ type: "NOTICIASCORTAS" }),
      upsertRequest: controlNewsDefaultProps(requests),
      mediolibraryrequest: mediolibraryrequest,
      medialibrary: getRequests("medialibrary"),
    },
    tableOptions: {
      isEditable: true,
      isDeletable: true,
      displayItemsPerPage: false,
      isSearchable: true,
    },
    upsertOptions: {
      onMount: () =>
        Promise.all([
          findAllRequestOrderTitle("magazine", true)(),
          findAllRequestOrderName("gallery", true)(),
          findAllRequestOrderName("priceType", true)(),
          findAllRequestOrderName('category', true)(),
          findRequest('tag', true)(),
          findRequest('tag', true)(),
        ]).then(
          ([
            { data: magazines },
            { data: galleries },
            { data: priceTypes },
            { data: categories },
            { data: tags },
            { data: relatedTags },
          ]) => ({
            magazines,
            galleries,
            priceTypes,
            categories,
            tags,
            relatedTags
          })
        ),
      itemBeforeSave: (data) => {
        let itemData = {
          ...data,
          state: data?.state?.value ? data.state.value : data.state,
          slug: data.slug == null || data.slug == '' ? convertToSlug(data.title || ''): data.slug,
          urlSeo: data.slug == null || data.slug == '' ? convertToSlug(data.titleSeo || ''): data.slug,
        };
        return Promise.resolve(itemData);
      },
    },
  },
  sections: [
    {
      fields: [
        {
          label: "Visible",
          property: "isVisible",
          type: "checkbox",
          tableOptions: { show: false },
          upsertOptions: {
            value: true,
            show: false,
          },
        },
        {
          label: "Título",
          property: "title",
          required: true,
          tableOptions: {
            isSortable: true,
            sortProperty: "titleOrder"
          }
        },
        {
          label: "Visitas",
          property: "visitCounter",
          type: "number",
          tableOptions: {
            isSortable: true,
          },
          upsertOptions: {
            show: false,
          },
        },
        {
          label: "Título SEO",
          property: "titleSeo",
          tableOptions: {
            show: false,
          },
        },

        {
          label: "Descripción SEO",
          property: "descriptioSeo",
          tableOptions: {
            show: false,
          },
        },
        {
          label: 'Palabras clave SEO (separ las palabras con , )',
          property: 'keywords',
          tableOptions: {
            show: false,
          },
        },
        dateCreate,
        {
          label: "Estado noticia",
          property: "state",
          type: "multiselect",
          showInTable: true,
          isClearable: false,
          tableOptions: {
            isSortable: true,
          },
          relation: {
            forceSendItem: true,
            name: "state",
            isMulti: false,
            sort: true,
            options: [
              { value: "PUBLICADO", label: "PUBLICADO" },
              { value: "BORRADOR", label: "BORRADOR" },
            ],
          },
        },
        {
          label: 'URL: ' + process.env.REACT_APP_FRONT_URL + "noticias-breves/",
          property: 'slug',
          tableOptions: {
            show: false,
          },
        },
        {
          label: "Url SEO",
          property: "urlSeo",
          tableOptions: {
            show: false,
          },
          upsertOptions: {
            show: false,
          },
        },
        {
          label: "Tipos",
          property: "type",
          tableOptions: { show: false },
          upsertOptions: {
            value: "NOTICIASCORTAS",
            show: false,
          },
        },
        {
          label: 'Categorías',
          property: 'categories',
          type: 'multiselect-paginated',
          fetchAction: ({ page, search }) =>
            getModelWithPaginationSearch('category')({
              pagination: { page },
              filter: { searchValue: search, filters: { isCompany: false } },
            }).then((res) =>
              res?.data.map((item) => ({
                label: item.name,
                value: item.slug,
                ...item,
              })),
            ),
          tableOptions: { show: false },
          relation: {
            name: 'categories',
            isMulti: true,
            sort: true,
            primaryKey: 'id',
            nameProps: ['name'],
          },
        },
        {
          label: 'Etiquetas',
          property: 'tags',
          type: 'multiselect-paginated',
          isClearable: true,
          isCreatable: true,
          fetchAction: ({ page, search }) => {
            return getModelWithPaginationSearch('tag')({
              pagination: { page },
              filter: { searchValue: search },
            }).then((res) =>
              res?.data.map((item) => ({
                label: item.name,
                value: item.slug,
                ...item,
              })),
            );
          },
          tableOptions: { show: false },
          relation: {
            name: 'tags',
            isMulti: true,
            sort: true,
            primaryKey: 'id',
            nameProps: ['name'],
          },
        },
        imageLibrary({ label: "Imagen", property: "imageprin" }),
        imageLibrary({
          label: 'Imagen SEO',
          property: 'imageSeo',
          showInTable: false,
        }),
        {
          label: "Contenido",
          property: "content",
          type: "html",
          value: ({ state }) => state?.data?.content,
          style: {
            flexBasis: "100%",
            zIndex: 0,
          },
          tableOptions: {
            show: false,
          },
          controlProps: {
            init: {
              image_caption: true,
              images_upload_handler: (blobData, success, failure) =>
                uploadFileRequest({ file: blobData.blob() })
                  .then((response) =>
                    success(process.env.REACT_APP_API_URL + "image/" + response)
                  )
                  .catch(failure),
            },
          },
        },
        {
          tableOptions: { show: false },
          label: 'translate',
          type: 'translate',
        },
        {
          tableOptions: { show: false },
          label: 'nad',
          type: 'nad',
          redirect:'/subshortNews/'
        },
        // { titleSeo: null },
        // { updateDate: "2021-01-05T17:45:03Z" },
        // { updateUser: "admin" },
        // { urlSeo: null },
        // { content: null },
        // { createDate: "2021-01-05T17:45:03Z" },
        // { createUser: "admin" },
        // { dateCreate: "2021-01-05T17:45:03Z" },
        // { deleteDate: null },
        // { deleteUser: null },
        // { descriptioSeo: null },
        // { endFeatured: null },
        // { endSponsoring: null },
        // { featured: null },
        // { homePrin: null },
        // { imageprin: null },
        // { sponsoring: null },
        // { tags: null },
        // { push: null },
      ],
    },
  ],
};

export default headers;
