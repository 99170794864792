import React from "react";
import styled from "styled-components";
import { defaultTheme, themable } from "../Theme/";

const StyledLabel = styled.label`
  ${({ theme, color }) => `
    color: ${themable({
      theme,
      props: ["label.color", `button.${color}.text`, color, "default"],
    })};
    font-size: ${themable({
      theme,
      props: ["label.fontSize", "16px"],
    })};
    font-family: ${themable({
      theme,
      props: ["label.fontFamily", "fontFamily"],
    })}; 
   `}
  font-weight: 500;
  margin-bottom: 10px;
`;

const Label = ({ children, ...props }) => (
  <StyledLabel {...props}> {children} </StyledLabel>
);

StyledLabel.defaultProps = {
  theme: defaultTheme,
};

export default Label;
