import React, { useRef } from "react";
import styled from "styled-components";
import { defaultTheme, themable } from "../Theme/";
import Card from "../Card";
import Text from "../Text";
import { useOnClickOutside } from "../utils/hooks";

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 100;
`;

const Blur = styled(Container)`
  ${({ theme }) => `
    background-color: ${themable({
      theme,
      props: [`modal.blur.backgroundColor`, "secondary"],
    })}; 
    backdrop-filter: ${themable({
      theme,
      props: [`modal.backdropFilter`, "blur(10px)"],
    })}; 
    opacity: ${themable({
      theme,
      props: [`modal.opacity`, "0.6"],
    })};  
  `}
  z-index: 90;
`;

const CustomText = styled(Text)`
  text-align: center;
  font-weight: 600;
  margin-bottom: 40px;
`;

const ModalVerticalAlign = styled.div`
  display: flex;
  align-items: center;
  min-height: calc(100% - (1.75rem * 2));
  position: relative;
  width: auto;
  pointer-events: none;
`;

const ModalContent = styled(Card)`
  ${({ theme }) => `
    background-color: ${themable({
      theme,
      props: [`modal.backgroundColor`, "secondary"],
    })};
    border-radius: ${themable({
      theme,
      props: [`modal.borderRadius`, "10px"],
    })};
    font-family: ${themable({
      theme,
      props: [`modal.fontFamily`, "fontFamily"],
    })}; 
    padding: ${themable({
      theme,
      props: [`modal.padding`, "10px 16px"],
    })};  
    max-width: ${themable({
      theme,
      props: [`modal.maxWidth`, "500px"],
    })}; 
  `}

  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  outline: 0;
  margin: 1.75rem auto;
`;

const Modal = ({
  Title: CustomTitle = CustomText,
  title,
  onCloseClick = () => {},
  children,
  ...props
}) => {
  const ref = useRef(null);
  useOnClickOutside(ref, () => onCloseClick());

  const { containerProps, modalProps, backgroundProps, ...otherProps } = props;

  return (
    <>
      <Container {...(containerProps ? containerProps : otherProps)}>
        <ModalVerticalAlign ref={ref}>
          <ModalContent {...modalProps}>
            {title && <CustomTitle as="p">{title}</CustomTitle>}
            {children}
          </ModalContent>
        </ModalVerticalAlign>
      </Container>
      <Blur {...backgroundProps} />
    </>
  );
};

Blur.defaultProps = {
  theme: defaultTheme,
};

ModalContent.defaultProps = {
  theme: defaultTheme,
};

export default Modal;
