import { useEffect, useState } from "react";
import styled from "styled-components";
import { Label, Input } from "../../bluejay-ui";

const FilterInputs = ({ updateFilter = () => {} }) => {
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    updateFilter(filters);
  }, [filters]);

  const handleOnChange = (event) => {
    const { target } = event;
    const newFilter = {
      key: target?.name,
      value:
        (target?.value || "2021-01-01") +
        (target?.name === "desde" ? "T00:00:00Z" : "T23:59:59Z"),
    };
    setFilters([
      ...filters.filter(({ key }) => key !== target?.name),
      newFilter,
    ]);
  };

  return (
    <Container>
      <DateInput name="desde" onChange={handleOnChange} />
      <DateInput name="hasta" onChange={handleOnChange} />
    </Container>
  );
};

const DateInput = (props) => {
  const { name } = props;
  return (
    <div>
      <Label for={name} style={{ textTransform: "capitalize" }}>
        {name}
      </Label>
      <Input name="desde" type="date" {...props} style={{ marginTop: 2 }} />
    </div>
  );
};
const Container = styled.div`
  width: 30%;
  display: flex;
  justify-content: space-between;
`;

export default FilterInputs;
