import React, { useEffect, useState } from "react";
import { Table, Modal, Text, Button, Loader, Input } from "../../bluejay-ui";
import styled from "styled-components";
import {TwitterLogin} from "react-twitter-login-oauth2"
import singintwitter from "react-twitter-login-oauth2/assets/singintwitter.png"
import faceSinIn from "./facebook-sign-in-button.png"
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { LinkedIn } from 'react-linkedin-login-oauth2';
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';
import jwt from 'jwt-decode'
import { getRequests,owner as ownerRequest } from "utils/requests";

const requests = getRequests("socialaccounts");



const PanelButtons = styled.div`
  display: flex;
  color: black;
  flex: 0 1 100%;
  margin-top:2em;

`;



const SocialAccountBuilder = (props) => {

  const [refreshStateComponent, setRefreshStateComponent] = useState(props.refreshStateComponent ? props.refreshStateComponen:null);
  const [getDataComponent, seGetDataComponent] = useState(props.getDataComponent ? props.getDataComponent:null);
  const [showmodalsave, setShowmodalsave] = useState(false);
  const [showmodalsavePage, setShowmodalsavePage] = useState(false);
  const [pagesInfo, setPagesInfo] = useState([]);
  const [itemToSave, setItemToSave] = useState({
    name:"",
    apiKey:"",
    type:"",
    twitterchallenge:"",
  });
  const [idSocial, setIdSocial] = useState(null);
  const [owner, setOwner] = useState("");





const buttonLinkedin = () => {
    return <LinkedIn
         clientId={process.env.REACT_APP_LINKEDIN_APPID}
         redirectUri={`${window.location.origin}/linkedin`}
         scope={"r_liteprofile r_emailaddress w_member_social w_organization_social rw_organization_admin"}
         onSuccess={(code) => {
           itemToSave.name = "";
           itemToSave.apiKey = code;
           itemToSave.type = "LINKEDIN";
           setItemToSave(itemToSave)
           setShowmodalsave(true);

           if (refreshStateComponent && typeof refreshStateComponent === 'function'){
             refreshStateComponent();
           }
         }}
         onError={(error) => {
           console.log(error);
         }}
       >
         {({ linkedInLogin }) => (
           <img
             onClick={linkedInLogin}
             src={linkedin}
             alt="Sign in with Linked In"
             style={{ maxWidth: '180px', cursor: 'pointer', maxHeight: "40px", marginRight:"1em" }}
           />
         )}
     </LinkedIn>;
  }
  
  
  const buttonFacebook = () => {
    return <FacebookLogin
            appId={process.env.REACT_APP_FACEBOO_APPID}
            autoLoad={false}
            fields="name,email,picture"
            scope="public_profile,pages_manage_posts"
            callback={(response) => {
              if (response.accessToken){
                itemToSave.name = response.name;
                itemToSave.apiKey = response.accessToken;
                itemToSave.type = "FACEBOOK";
                itemToSave.login = response.userID;
                setItemToSave(itemToSave)
                setShowmodalsave(true);

                console.log(response);
              }
            }}
            render={renderProps => (
              <img
                onClick={renderProps.onClick}
                src={faceSinIn}
                alt="Sign in with Twitter"
                style={{ maxWidth: '180px', cursor: 'pointer', maxHeight: "40px", marginRight:"1em" }}
              />
            )} />
  }
  const buttonTwitter = () => {
    return <TwitterLogin
         clientId={process.env.REACT_APP_TWITTER_APPID}
         redirectUri={`${window.location.origin}/twitter`}
         onSuccess={(code,challenge) => {
           itemToSave.name = "";
           itemToSave.apiKey = code;
           itemToSave.type = "TWITTER";
           itemToSave.twitterchallenge = challenge;
           setItemToSave(itemToSave)
           setShowmodalsave(true);
         }}
         onError={(error) => {
           debugger;
           console.log(error);
         }}
       >
         {({ twitterLogin }) => (
           <img
             onClick={twitterLogin}
             src={singintwitter}
             alt="Sign in with Twitter"
             style={{ maxWidth: '180px', cursor: 'pointer', maxHeight: "40px", marginRight:"1em" }}
           />
         )}
     </TwitterLogin>
  }

  

const clickCloseModalSHowBill = (evt) => {
    if (itemToSave != ""){
      requests.upsertRequest(itemToSave).then((data) => {
       setShowmodalsave(false);
       if(data.selectedPage && data.selectedPage.length > 0){
        pagesInfo.push(...data.selectedPage);
        setIdSocial(data.id);
        setPagesInfo(pagesInfo);
         setShowmodalsavePage(true);
       } else {
        window.location.reload();
       }
      });
      
    }
 
 }
 
    const clickCloseModalSelect = async (evt) => {
      debugger;
      if((owner == null || owner != "") && idSocial){
        ownerRequest()(idSocial,owner == null?"-1":owner).then(()=>{
           window.location.reload();
        });
      }
    
    }
    
    const onInputChange = (evt) => {
      itemToSave.name = evt.target.value
      setItemToSave(itemToSave);
    }
    
    
    const authHandlerTwitter = (err, data) => {
        console.log(err, data);
    };

   const modalPage = () =>{
        return <Modal title={"¿Con que nombre guardamos esta cuenta? "}>
                    <div>
                      {pagesInfo.map((item) => {
                        return <p><input  type={"radio"}  value={item["owner"]}  checked={owner === item["owner"]} onChange={() => setOwner(item["owner"])} /> <span>{item['name']}</span></p>
                      })}
                    </div>
                    <div style={{textAlign: "center"}}>
                        <Button color="danger" onClick={clickCloseModalSelect} style={{display:"inline-block"}}>
                        Seleccionar
                        </Button>
                    </div>
                </Modal>
   }
   
  return (
    <PanelButtons> 
          {buttonLinkedin()}
          {buttonTwitter()}
          {buttonFacebook()}
            {showmodalsave &&
                <Modal title={"¿Con que nombre guardamos esta cuenta?"}>
                    <Input key={"name"}
                    Label={"name"}
                    label={"Te servira para identificar la cuenta"}
                    type={"text"}
                    onInput={onInputChange}
                    />
                <div style={{textAlign: "center"}}>
                    <Button color="danger" onClick={clickCloseModalSHowBill} style={{display:"inline-block"}}>
                    Guardar
                    </Button>
                </div>
                </Modal>
            }  
            {showmodalsavePage &&  modalPage()}
    </PanelButtons>
  );
};


export default SocialAccountBuilder;
