import React, { useState } from "react";
import styled from "styled-components";
import { defaultTheme, themable } from "../Theme/";
import Label from "../Label";
import { ContentWithIcon } from "../Icon";

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled.input`
  ${({ theme, disabled }) => {
    const state = disabled ? "disabled" : "active";
    return `
    border: ${themable({
      theme,
      props: [`input.${state}.borderWidth`, "1px"],
    })} solid ${themable({
      theme,
      props: [`input.${state}.borderColor`, "default"],
    })};

    background-color: ${themable({
      theme,
      props: [`input.${state}.backgroundColor`, "secondary"],
    })};

    color: ${themable({
      theme,
      props: [`input.${state}.color`, "default"],
    })};

    font-size: ${themable({
      theme,
      props: [`input.fontSize`, "16px"],
    })};

    border-radius: ${themable({
      theme,
      props: [`input.borderRadius`, "borderRadius", "10px"],
    })};

    font-family: ${themable({
      theme,
      props: [`input.fontFamily`, "fontFamily"],
    })};

    padding: ${themable({
      theme,
      props: [`input.padding`, "10px 20px"],
    })}; 

    ::placeholder {
      color: ${themable({
        theme,
        props: [`input.${state}.placeholderColor`, "default"],
      })};
      font-size: ${themable({
        theme,
        props: [`input.placeholderFontSize`, "16px"],
      })}; 
    }
    :focus {
      border-color: ${themable({
        theme,
        props: [`input.borderFocusColor`, "primary"],
      })}; 
    }
  `;
  }}

  margin-bottom: 10px;
  outline: none;
`;

const debounceFn = {};

const Input = React.forwardRef(
  (
    {
      icon,
      iconPosition,
      debounce = 0,
      onIconClick,
      containerStyle = {},
      ...props
    },
    ref
  ) => {
    const {
      id,
      required,
      label = props.property,
      name = props.property,
      showLabel = true,
      Label: CustomLabel = Label,
      onChange = () => {},
    } = props;

    const [value, setValue] = useState(props.value);

    const shouldTheStateBeLocal = () =>
      typeof props.value === "undefined" || hasDebounce();

    const hasDebounce = () =>
      typeof debounce !== "undefined" && debounce && debounce !== 0;

    const debounceOnChange = (event) => {
      event.persist();
      const { name, value: nextValue } = event.target;
      setValue(nextValue);

      if (debounceFn[name]) {
        clearTimeout(debounceFn[name]);
      }

      if (nextValue !== "") {
        debounceFn[name] = setTimeout(() => onChange(event), debounce);
      } else {
        onChange(event);
      }
    };

    return (
      <InputContainer style={containerStyle}>
        {showLabel && label && (
          <CustomLabel htmlFor={id}>{`${label}${
            required ? "*" : ""
          }`}</CustomLabel>
        )}
        <ContentWithIcon
          icon={icon}
          iconPosition={iconPosition}
          onIconClick={onIconClick}
        >
          <StyledInput
            {...props}
            value={shouldTheStateBeLocal() ? value : props.value}
            onChange={shouldTheStateBeLocal() ? debounceOnChange : onChange}
            name={name}
            ref={ref}
          />
        </ContentWithIcon>
      </InputContainer>
    );
  }
);

StyledInput.defaultProps = {
  theme: defaultTheme,
  step: "any",
};

export default Input;
