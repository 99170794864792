import { getRequests } from "utils/requests";

const requests = getRequests("priceType");

const headers = {
  type: "CRUD",
  options: {
    name: "Tipo noticias",
    route: "/pricetype",
    requests: {
      ...requests,
    },
    tableOptions: {
      isEditable: true,
      isDeletable: true,
      displayItemsPerPage: false,
      isSearchable: true,
    },
  },
  sections: [
    {
      fields: [
        { property: "name", label: "Nombre", tableOptions: {isSortable: true} },
        { property: "price", label: "Precio", type: "number", tableOptions: {isSortable: true} },
      ],
    },
  ],
};

export default headers;
