export const isOdd = (val) => parseInt(val) % 2 === 0;

export const getRecalculatedWidth = ({
  value,
  operation = "sub",
  increment = 1,
}) =>
  operation === "sub"
    ? `${parseInt(value) - increment}px`
    : `${parseInt(value) + increment}px`;
