import React, { useState } from "react";
import styled from "styled-components";
import { defaultTheme, themable } from "../Theme/";
import { ReactComponent as OpenIcon } from "../utils/images/burger.svg";
import { ReactComponent as CloseIcon } from "../utils/images/cross.svg";

const StyledSidebar = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme, open }) => `  
  background-color: ${themable({
    theme,
    props: ["sidebar.backgroundColor", "secondary"],
  })};  
  width: ${open ? "224px" : "73px"};
  `}
  padding: 10px;
  height: calc(100vh - 20px);
  position: relative;
  overflow-x: hidden;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
`;

const Spacer = styled.div`
  height: 64px;
`;

const StyledIcon = styled.svg`
  ${({ theme, color }) => `  
  path {
    fill: ${themable({
      theme,
      props: [`button.${color}.background`, "sidebar.icon.fill", color],
    })}; 
  }
  `}
  cursor: pointer;
  width: auto;
  height: 18px;
`;

const ToggleIcon = styled.span`
  position: absolute;
  right: 35px;
  top: 40px;
`;

function Sidebar(props) {
  const {
    children,
    permanent = false,
    defaultOpen = true,
    buttonColor,
  } = props;

  const [open, setOpen] = useState(defaultOpen);

  return (
    <StyledSidebar {...props} open={open}>
      <Spacer />
      {!permanent && (
        <ToggleIcon onClick={() => setOpen(!open)}>
          <StyledIcon as={open ? CloseIcon : OpenIcon} color={buttonColor} />
        </ToggleIcon>
      )}
      {children}
    </StyledSidebar>
  );
}

StyledIcon.defaultProps = {
  theme: defaultTheme,
};

StyledSidebar.defaultProps = {
  theme: defaultTheme,
};

export default Sidebar;
