import React from "react";
import styled from "styled-components";
import { defaultTheme, themable } from "../Theme/";

const StyledPaginationItem = styled.button`
  ${({ theme, activeItem }) => {
    const activeClass = activeItem ? "active" : "default";
    const itemOptions = activeItem
      ? { background: "primary", text: "secondary" }
      : { background: "secondary", text: "default" };
    return `  
      background-color: ${themable({
        theme,
        props: [`pagination.${activeClass}.background`, itemOptions.background],
      })};

      color: ${themable({
        theme,
        props: [`pagination.${activeClass}.text`, itemOptions.text],
      })};

      border: ${themable({
        theme,
        props: [`pagination.borderWidth`, "1px"],
      })} solid ${themable({
      theme,
      props: [`pagination.borderColor`, "default"],
    })};

      border-radius: ${themable({
        theme,
        props: [`pagination.borderRadius`, "borderRadius", "10px"],
      })}; 

      font-family: ${themable({
        theme,
        props: [`pagination.fontFamily`, "fontFamily"],
      })};

      padding: ${themable({
        theme,
        props: [`pagination.padding`, "8px"],
      })}; 
    `;
  }}

  text-transform: uppercase;
  outline: none;
  min-width: 32px;
  cursor: pointer;
  margin: 0 5px;
`;

const PaginationItem = ({ children, ...props }) => (
  <StyledPaginationItem {...props}>{children}</StyledPaginationItem>
);

StyledPaginationItem.defaultProps = {
  theme: defaultTheme,
};

export default PaginationItem;
