import React from "react";
import styled, { css } from "styled-components";
import { defaultTheme, themable } from "../Theme/";
import { ContentWithIcon } from "../Icon";

const roundedStyle = css`
  width: 50px;
  height: 50px;
  padding: 0;
  border-radius: 50%;

  span {
    margin: 0;
    width: 25px;
    height: 25px;
    box-shadow: none;
  }
`;

const disabledStyle = css`
  ${({ theme }) => {
    const disabled = themable({
      theme,
      props: ["disabled", "#9B9B9B"],
    });

    return `
    color: ${disabled};
    border: 1px solid ${disabled};`;
  }}
  background-color: white;
  cursor: initial;
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  text-transform: uppercase;
  outline: none;
  text-decoration: none;

  ${({ theme, color }) => `  
    background-color: ${themable({
      theme,
      props: [`button.${color}.background`, color, "default"],
    })}; 
    color: ${themable({
      theme,
      props: [`button.${color}.text`, color, "white"],
    })}; 
    border-radius: ${themable({
      theme,
      props: ["button.borderRadius", "borderRadius"],
    })};
    font-family: ${themable({
      theme,
      props: ["button.fontFamily", "fontFamily"],
    })};
    padding:  ${themable({
      theme,
      props: ["button.padding", "10px 16px"],
    })};
  `}

  ${({ rounded }) => (rounded ? roundedStyle : "")}
  ${({ disabled }) => (disabled ? disabledStyle : "")}
`;

const Button = ({ style, onIconClick, onClick, ...props }) => {
  return (
    <StyledButton style={style} onClick={onClick} {...props}>
      <ContentWithIcon {...props} />
    </StyledButton>
  );
};

StyledButton.defaultProps = {
  theme: defaultTheme,
};

Button.defaultProps = {
  theme: defaultTheme,
};

export default Button;
