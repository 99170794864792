import React from "react";
import styled from "styled-components";
import { defaultTheme, themable } from "../Theme/";
import PaginationItem from "./PaginationItem";
import responsive from "./../utils/responsive";
import { ReactComponent as Arrow } from "../utils/images/arrow.svg";
import { ReactComponent as DoubleArrow } from "../utils/images/doubleArrow.svg";

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;
  ${({ isMobile }) => (isMobile ? `justify-content: space-between;` : ``)}
`;

const ArrowSVG = styled.svg`
  width: 1em;
  height: 1em;
  cursor: pointer;

  ${({ theme, isMobile, direction }) => `
    ${
      isMobile
        ? ` 
      border: ${themable({
        theme,
        props: [`pagination.mobile.borderWidth`, "1px"],
      })};
      solid ${themable({
        theme,
        props: [`pagination.mobile.borderColor`, "default"],
      })};
      border-radius: ${themable({
        theme,
        props: [`pagination.mobile.borderRadius`, "10px"],
      })};
      padding: ${themable({
        theme,
        props: [`pagination.mobile.padding`, "8px 13px"],
      })};
    `
        : ``
    }
    
    ${direction === "right" ? `transform: scaleX(-1);` : ``}
  
    path {
      fill: ${themable({
        theme,
        props: [`pagination.arrows.fillColor`, "borderRadius"],
      })};
    }
  `}
`;

const buildButtonsRange = ({ page, pages, pageBound }) => {
  const onePage = (pages) => pages === 1;
  const theArePage = (page) => page && page !== 0;
  const isFirstPage = (page, pages) => page === 1;
  const isLastPage = (page, pages) => page === pages;

  const buildRange = (firstValue, items) => {
    let array = [];
    for (let i = 0; i < items; i++) {
      array.push(firstValue - (items - i));
    }
    return array;
  };

  return onePage(pages)
    ? [page - 1]
    : [
        ...(theArePage(page)
          ? isLastPage(page, pages)
            ? buildRange(page, pageBound)
            : isFirstPage(page)
            ? buildRange(page + pageBound - 1, pageBound)
            : buildRange(page + pageBound - 2, pageBound)
          : []),
      ];
};

const Pagination = ({
  pageBound = 3,
  page = 1,
  pages = 1,
  goToPage = () => {},
  ...props
}) => {
  const { useMedia, breakpoints } = responsive;
  const isMobile = !useMedia([breakpoints.TABLET], [true], false);

  function handleClick(value) {
    goToPage(value);
    updateBounds(value);
  }

  const updateBounds = () => {};

  const paginationButtons =
    pages === 1
      ? [page - 1]
      : [
          ...(page && page !== 0
            ? page && page === pages
              ? pageBound > 2
                ? [page - 3, page - 2, page - 1]
                : [page - 2, page - 1]
              : page > 1
              ? [page - 2, page - 1, page]
              : pageBound > 2
              ? [page - 1, page, page + 1]
              : [page - 1, page]
            : Array(pageBound).keys()),
        ];
  return (
    <PaginationContainer isMobile={isMobile} {...props}>
      {renderArrows({
        ...props,
        pageNum: page,
        onClick: () => handleClick(1),
        direction: "left",
        arrowType: "doubleArrow",
        style: isMobile ? { display: "none" } : {},
      })}
      {renderArrows({
        ...props,
        pageNum: page,
        onClick: () => handleClick(page ? page - 1 : 1),
        direction: "left",
        isMobile,
      })}

      {paginationButtons.map((pageIndex) => {
        const itemPageNumber = pageIndex + 1;
        const pageNumber = pageIndex;
        const activeItem = page - 1 === itemPageNumber;

        return (
          <PaginationItem
            key={itemPageNumber}
            onClick={() => handleClick(itemPageNumber)}
            {...props}
            activeItem={pageIndex + 1 === page}
          >
            {/* {pageIndex + 1} */}
            {pageNumber + 1}
          </PaginationItem>
        );
      })}
      {renderArrows({
        ...props,
        pageNum: page,
        onClick: () => page < pages && handleClick(page + 1),
        isMobile,
      })}
      {renderArrows({
        ...props,
        pageNum: page,
        onClick: () => handleClick(pages),
        arrowType: "doubleArrow",
        style: isMobile ? { display: "none" } : {},
      })}
    </PaginationContainer>
  );
};

const renderArrows = ({
  pageNum,
  hideNavigationArrows = false,
  hideNextAndPrevArrowButton = false,
  hideFirstAndLastArrowButton = false,
  direction = "right",
  arrowType = "singleArrow",
  ...props
}) =>
  !hideNavigationArrows && (
    <ArrowSVG
      direction={direction}
      {...props}
      as={arrowType === "singleArrow" ? Arrow : DoubleArrow}
    />
  );

ArrowSVG.defaultProps = {
  theme: defaultTheme,
};

export default Pagination;
