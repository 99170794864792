import { findAllRequest, findAllRequestOrderTitle, findAllPositionNad, getRequests, uploadFileRequest, mediolibraryrequest } from "utils/requests";
import { DateTime } from "luxon";
import { image, imageLibrary } from "utils/fields";

const requests = getRequests("adsnad");

const optionsTranslation = (key) => {
  switch (key) {
    case "noticiasaldetalle":
      return "Noticias al detalle - Inicio";
    case "noticiasnad":
      return "Noticias de Noticias al detalle";
    case "noticias-al-detalle-aperitivos-y-frutos-secos":
      return "Noticias al detalle: Frutos secos";
    case "noticias-al-detalle-bebidas":
      return "Noticias al detalle: Bebidas";
    case "noticias-al-detalle-caramelos-y-chicles":
      return "Noticias al detalle: Caramélos y chicles";
    case "noticias-al-detalle-chocolates":
      return "Noticias al detalle: Chocolates";
    case "noticias-al-detalle-como-se-fabrica":
      return "Noticias al detalle: Como se fabrica";
    case "noticias-al-detalle-dulces-de-navidad":
      return "Noticias al detalle: Dulces de navidad";
    case "noticias-al-detalle-galletas":
      return "Noticias al detalle: Galletas";
    case "noticias-al-detalle-helados-y-polos":
      return "Noticias al detalle: Helados y polos";
    case "noticias-al-detalle-pasteleria":
      return "Noticias al detalle: Pastelería";
    case "noticias-al-detalle-recetas":
      return "Noticias al detalle: Recetas";
    case "noticias-al-detalle-sabias-que-":
      return "Noticias al detalle: Sabias que";
    case "noticias-al-detalle-tiendas":
      return "Noticias al detalle: Tiendas";
    case "noticias-al-detalle":
      return "Noticias al detalle en papel";
    case "dulcesyaperitivos-tv":
      return "Dulces y aperitivos TV";
    case "todos-nad":
      return "Todos Noticias al detalle";
    default:
      return key;
  }


};

const adsTypesOptions = [
  { value: "ADSHOMENAD", label: "Mediabanner home noticias al detalle" },
  { value: "DERCHLATNAD", label: "Lateral derecho noticias al detalle" },
  { value: "IZQLATNAD", label: "Lateral izquierdo noticias al detalle" },
  { value: "MEGABANNERNAD", label: "Megabanner noticias al detalle" },
  { value: "HOMENOTICIASALDETALLE", label: "Sidebar Home noticias al detalle" },
  { value: "NEWSNOTICIASALDETALLE", label: "Sidedar Noticias de Noticias al detalle" },
  { value: "SECCIONNOTICIASALDETALLE", label: "Sidebar Secciones de Noticias al detalle" },
  { value: "SIDEBARNOTICIASDETALLESNAD", label: "Sidebar noticias al detalle en papel"}
];

const headers = {
  type: "CRUD",
  options: {
    name: "Anuncios Noticias al detalle",
    route: { path: "/adsnad" },
    requests: {
      ...requests,
      uploadFileRequest,
      mediolibraryrequest: mediolibraryrequest,
      medialibrary: getRequests("medialibrary"),
    },
    tableOptions: {
      isEditable: true,
      isDeletable: true,
      displayItemsPerPage: false,
      isSearchable: true,
    },
    upsertOptions: {
      onLoadMapping: (data) =>
        Promise.resolve({
          ...data,
          position: data.position.map((item) => ({
            ...item,
            label: optionsTranslation(item.name),
          })),
          type: adsTypesOptions.find(({ value }) => data.type === value),
        }),
      onMount: () =>
        Promise.all([findAllPositionNad ()()]).then(
          ([{ data: position }]) => ({
            position: position
              .map((item) => ({
                ...item,
                label: optionsTranslation(item.name),
              }))
              .sort((a, b) =>
                a.label > b.label ? 1 : b.label > a.label ? -1 : 0
              ),
          })
        ),
      itemBeforeSave: (data) => {
        let item = {
          ...data,
        };
        if (data?.image?.size) {
          const blob = new Blob([data.image]);

          return uploadFileRequest({
            file: blob,
          }).then((imageName) => {
            if (data?.imageMovil?.size) {
              const blob = new Blob([data.imageMovil]);
              return uploadFileRequest({
                file: blob,
              }).then((imageMovilName) => {
                return Promise.resolve({
                  ...item,
                  image: imageName,
                  imageMovil: imageMovilName,
                });
              });
            } else {
              return Promise.resolve({ ...item, image: imageName });
            }
          });
        } else if (data.imageMovil?.size) {
          const blob = new Blob([data.imageMovil]);
          return uploadFileRequest({
            file: blob,
          }).then((imageMovilName) => {
            return Promise.resolve({ ...item, imageMovil: imageMovilName });
          });
        } else {
          return Promise.resolve(item);
        }
      },
    },
  },
  sections: [
    {
      fields: [
        {
          label: "Título",
          property: "title",
          tableOptions: {
            isSortable: true
          }
        },
        {
          label: "URL",
          property: "url",
          tableOptions: {
            isSortable: true
          }
        },
        imageLibrary({
          label: "Imagen",
          property: "image",
          showInTable: false,
        }),
        imageLibrary({
          label: "Imagen Móvil",
          property: "imageMovil",
          showInTable: false,
        }),
        {
          label: 'Noticias al detalle',
          property: 'nad',
          tableOptions: { show: false },
          upsertOptions: {
            value: true,
            show: false,
          },
        },
        {
          label: "Tipo",
          property: "type",
          type: "multiselect",
          isClearable: false,
          tableOptions: {   
            show: true,
            format:(field,state) => {
              var result = "";
              if (state){
                adsTypesOptions.forEach(element => {
                  if (element.value == state){
                    result  =  element.label;
                  }
                });

              } 
              return result;
              
            }
          },
          relation: {
            sort: true,
            // name: "type",
            isMulti: false,
            options: adsTypesOptions,
          },
        },
        {
          label: "Posición",
          property: "position",
          type: "multiselect",
          isClearable: true,
          isCreatable: false,
          tableOptions: { show: false, },
          relation: {
            name: "position",
            isMulti: true,
            sort: true,
            // forceSendItem: true,
            primaryKey: "id",
            nameProps: ["label"],
          },
        },
        {
          label: "Orden",
          property: "order",
          type:"number",
          tableOptions: {
            isSortable: true,
          }
        },
        {
          label: "Fecha de inicio",
          property: "startDate",
          type: "date",
          tableOptions: {
            isSortable: true,
            format: (field, state) => {
              return state
                ? DateTime.fromISO(state).toFormat("dd-MM-yyyy")
                : "";
            },
          },
          upsertOptions: {
            beforeSave: ({ state, field }) => {
              return state?.startDate
                ? DateTime.fromISO(state?.startDate).toFormat(
                    "yyyy-MM-dd'T'00:00:00'Z"
                  )
                : "";
            },
          },
        },
        {
          label: "Fecha de fin",
          property: "endDate",
          type: "date",
          tableOptions: {
            isSortable: true,
            format: (field, state) => {
              return state
                ? DateTime.fromISO(state).toFormat("dd-MM-yyyy")
                : "";
            },
          },
          upsertOptions: {
            beforeSave: ({ state, field }) => {
              return state?.endDate
                ? DateTime.fromISO(state?.endDate).toFormat(
                    "yyyy-MM-dd'T'00:00:00'Z"
                  )
                : "";
            },
          },
        },
        {
          label: "Abrir en la misma pestaña",
          property: "openSamePage",
          tableOptions: { show: false },
          type:"checkbox"
        },
        {
          label: "Html",
          property: "html",
          type: "html",
          value: ({ state }) => state?.data?.html,
          style: {
            flexBasis: "100%",
            zIndex: 0,
          },
          tableOptions: {
            show: false,
          },
          controlProps: {
            init: {
              image_caption: true,
            },
          },
        },
      ],
    },
  ],
};

export default headers;
