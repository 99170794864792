import {
    getRequests,
    uploadFileRequest,
} from "utils/requests";
import { image } from "utils/fields";

const requests = getRequests("buyGuideCategory");

const headers = {
    type: "CRUD",
    options: {
        name: "Categorías Business Meeting Point",
        route: { path: "/buyguidecategories" },
        requests: {
            ...requests,
            uploadFileRequest,
        },
        tableOptions: {
            isEditable: true,
            isDeletable: true,
            displayItemsPerPage: false,
            isSearchable: true,
        },
    },
    sections: [
        {
            fields: [
                {
                    label: "Nombre",
                    property: "name",
                    tableOptions: {
                        isSortable: true,
                    }
                },
                image({
                    label: "Imagen",
                    property: "link",
                }),
            ],
        },
    ],
};

export default headers;