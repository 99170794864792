import React from "react";
import styled from "styled-components";
import { ReactComponent as Arrow } from "../utils/images/arrow.svg";
import { ReactComponent as ArrowTopDown } from "../utils/images/orders.svg";
import { defaultTheme, themable } from "../Theme";

const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;

  & .sort {
    cursor: pointer;
  }
`;

export const StyledThHead = styled.thead`
  border-bottom: 2px solid
    ${({ theme, color }) =>
      themable({
        theme,
        props: [color, "default"],
      })};
`;

StyledThHead.defaultProps = {
  theme: defaultTheme,
};

const SortArrow = styled.svg`
  width: 1em;
  height: 1em;
  margin-left: 10px;

  ${({ theme, arrowDirection }) => `  
    ${
      arrowDirection === "desc"
        ? `transform: rotate(90deg);`
        : `transform: rotate(-90deg);`
    }
    
    path {
      fill: ${themable({
        theme,
        props: [`pagination.arrows.fillColor`, "default"],
      })};
    }
  `}
`;

export const StyledTrHead = styled.tr`
  cursor: context-menu;
`;

StyledTrHead.defaultProps = {
  theme: defaultTheme,
};

export const StyledTh = styled.th`
  font-size: 16px;
  color: ${({ theme, color }) =>
    themable({
      theme,
      props: [color, "default"],
    })};
  text-align: center;
  padding-bottom: 15px;
`;

StyledTh.defaultProps = {
  theme: defaultTheme,
};

const StyledTbody = styled.tbody``;

export const StyledTd = styled.td`
  font-size: 16px;
  color: ${({ theme, color }) =>
    themable({
      theme,
      props: [color, "default"],
    })};
  text-align: center;
  padding: 15px 0;
`;

StyledTd.defaultProps = {
  theme: defaultTheme,
};

export const StyledTr = styled.tr`
  background: white;
  :nth-child(even) {
    background: #edf0f4;
  }
`;

const SortContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
`;

const Table = ({
  headers = [],
  onHeaderClick = () => {},
  onRowClick = false,
  renderCustomHeader = () => {},
  renderCustomCell = () => {},
  data = [],
  emptyTableText = "No hay resultados",
  ThHead: CustomThHead = StyledThHead,
  TrHead: CustomTrHead = StyledTrHead,
  Th: CustomTh = StyledTh,
  Td: CustomTd = StyledTd,
  Tr: CustomTr = StyledTr,
  className,
  filtersSort,
  selectedColumnSort,
}) => {
  return (
    <StyledTable className={className}>
      <CustomThHead>
        <CustomTrHead>
        
          {headers.map((header, key) => {
            const {
              tableOptions: { isSortable = false, sortProperty = "" } = {},
            } = header;
            return (
              <CustomTh
                {...(isSortable ? { className: "sort" } : {})}
                key={key}
                onClick={isSortable ? () => onHeaderClick(header) : () => {}}
              >
                <SortContainer>
                  {header.name || header.label}
                  {isSortable &&
                    (selectedColumnSort === header.property ||
                      selectedColumnSort === sortProperty) && (
                      <SortArrow arrowDirection={filtersSort} as={Arrow} />
                    )}
                  {isSortable &&
                      !(selectedColumnSort === header.property ||
                        selectedColumnSort === sortProperty) && 
                      <SortArrow as={ArrowTopDown} />}
                </SortContainer>
              </CustomTh>
            );
          })}
          <CustomTh>{renderCustomHeader()}</CustomTh>
        </CustomTrHead>
      </CustomThHead>
      <StyledTbody>
        {data.length > 0 ? (
          data.map((dataRow, key) => (
            <CustomTr key={key} {...(onRowClick ? { className: "sort" } : {})}>
              {headers.map((header, key) => (
                <CustomTd
                  data-testid={`table-row-${key}`}
                  key={key}
                  onClick={onRowClick ? () => onRowClick(dataRow) : () => {}}
                >
                  {getRowData(header, dataRow)}
                </CustomTd>
              ))}
              <td>{renderCustomCell(dataRow, key)}</td>
            </CustomTr>
          ))
        ) : (
          <CustomTr>
            <CustomTd colSpan={headers.length + 1}>{emptyTableText}</CustomTd>
          </CustomTr>
        )}
      </StyledTbody>
    </StyledTable>
  );
};

function getRowData(header, dataRow) {
  return header.tableOptions && typeof header.tableOptions.format === "function"
    ? header.tableOptions.format(dataRow, dataRow[header.property])
    : dataRow[header.property];
}

SortArrow.defaultProps = {
  theme: defaultTheme,
};

export default Table;
