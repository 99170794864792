import React from "react";
import styled from "styled-components";
import CustomSpinner from "./Spinner";

const LoaderContainer = styled.div`
  position: relative;

  ${({ isLoading }) => (isLoading ? "pointer-events: none;" : "")}
`;

const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: white;
  opacity: 0.5;
  z-index: 100;
`;

export default ({
  children,
  isLoading,
  containerStyle,
  style,
  Spinner = CustomSpinner,
}) => (
  <LoaderContainer style={containerStyle} isLoading={isLoading}>
    {isLoading && (
      <>
        <Loader style={style} isLoading={isLoading} />
        <Spinner />
      </>
    )}
    {children}
  </LoaderContainer>
);
