import styled from "styled-components";
import { defaultTheme, themable } from "../Theme";

const Text = styled.p`
  ${({ theme, color }) => ` 
    color: ${themable({
      theme,
      props: [color, "text.color", "primary"],
    })}; 
    margin: ${themable({
      theme,
      props: ["text.padding", "0px 0px 10px 0px"],
    })}; 
  `}
`;

Text.defaultProps = {
  theme: defaultTheme,
};

export default Text;
