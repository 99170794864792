import { getRequests } from "utils/requests";
import { DateTime } from "luxon";

const requests = getRequests("newsletter");

const headers = {
  type: "CRUD",
  options: {
    name: "Newsletter",
    route: "/newsletter",
    requests,
    tableOptions: {
      isEditable: true,
      isDeletable: true,
      displayItemsPerPage: false,
      isSearchable: true,
    },
  },
  sections: [
    {
      fields: [
        { property: "title", label: "Nombre", tableOptions:{isSortable: true, sortProperty: "titleOrder"} },
        { property: "url",
         label: "Url",
          tableOptions: {
            show: false
          },
        },
        {
          label: "Fecha",
          property: "datePublic",
          type: "datetime-local",
          tableOptions: {
            isSortable: true,
            show: true,
            format: (field, state) => {
              return state
                ? DateTime.fromISO(state).toFormat("dd/MM/yyyy HH:mm")
                : "";
            },
          },
          upsertOptions: {
            value: DateTime.fromISO(DateTime.now()).toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss'Z"),
            beforeSave: ({ state, field }) => {
              return state?.datePublic
                ? DateTime.fromISO(state?.datePublic)
                    .toUTC()
                    .toFormat("yyyy-MM-dd'T'HH:mm:ss'Z")
                : "";
            },
          },
        }
      ],
    },
  ],
};

export default headers;
