import React, { useEffect, useState } from "react";
import { List, ListItem, Text, Modal, Button } from "../../bluejay-ui";
import { findOneRequest, saveStructurePageRequest } from "utils/requests";
import { removeIndexFromArray, updateItemOnIndex } from "./utils";
import components from "./components.json";
import { ReactComponent as Add } from "../../images/plus.svg";
import { ReactComponent as Clear } from "../../images/minus.svg";
import { ReactComponent as Edit } from "../../images/pencil.svg";

const initialColumn = {};
const initalRow = { cells: [initialColumn] };

const InvoiceGenerator = () => {
  const [state, setState] = useState([]);
  const [showNoveltyWeeks, setShowNoveltyWeeks] = useState(false);
  const [itemToBeAdded, setItemToBeAdded] = useState();

  useEffect(() => {
    findOneRequest(
      "structurepage",
      true
    )({ id: 1 }).then((response) => {
      setShowNoveltyWeeks(response.showNoveltyWeeks);
      setState(
        response.rows.map(({ id, ...rest }) => {
          rest.cells = rest.cells.map(({ id, ...rest }) => rest);
          return rest;
        })
      );
    });
  }, []);

  const onSaveClick = () => {
    saveStructurePageRequest({
      code: "home",
      id: 1,
      rows: state,
      showNoveltyWeeks,
    });
  };

  const onAddColumnClick = (index) => (e) => {
    e.stopPropagation();

    setState(
      updateItemOnIndex({
        array: state,
        index,
        update: (item) => ({
          ...item,
          cells: [...item.cells, {}],
        }),
      })
    );
  };

  const onRemoveRowClick = (index) => (e) => {
    e.stopPropagation();

    setState(removeIndexFromArray({ array: state, index }));
  };

  const onRemoveColumnClick = (rowIndex) => (columnIndex) => (e) => {
    e.stopPropagation();

    setState(
      updateItemOnIndex({
        array: state,
        index: rowIndex,
        update: (item) => ({
          ...item,
          cells: removeIndexFromArray({
            array: item.cells,
            index: columnIndex,
          }),
        }),
      })
    );
  };

  const onEmptyRowAddColumnClick = (e) => {
    e.stopPropagation();

    setState([...state, initalRow]);
  };

  const onEditColumnClick = (rowIndex) => (columnIndex) => (e) => {
    e.stopPropagation();

    setItemToBeAdded({ rowIndex, columnIndex });
  };

  const onComponentClick = (component) => (e) => {
    e.stopPropagation();

    setState(
      updateItemOnIndex({
        array: state,
        index: itemToBeAdded.rowIndex,
        update: (item) => ({
          ...item,
          cells: updateItemOnIndex({
            array: item.cells,
            index: itemToBeAdded.columnIndex,
            update: (item) => ({ ...item, ...component }),
          }),
        }),
      })
    );

    setItemToBeAdded();
  };

  return (
    <div style={styles.container}>
      <Button color="primary" onClick={onSaveClick}>
        Guardar
      </Button>
      <label
        style={{
          color: "#616161",
          "font-size": "14px",
          "font-family": "Assistant,sans-serif",
          "font-weight": "500",
          "margin-bottom": "10px",
        }}
      >
        Mostrar NOVEDADES DE LA SEMANA
        <input
          type="checkbox"
          id="showNoveltyWeeks"
          name="showNoveltyWeeks"
          style={{ backgroundColor: "white", border: "1px solid #BFBFBF" }}
          checked={showNoveltyWeeks || ""}
          onChange={(event) => {
            setShowNoveltyWeeks(!showNoveltyWeeks);
          }}
          style={{ width: "23%", paddingLeft: 8 }}
        />
      </label>
      <div style={styles.wrapper}>
        {state.map((row, index) => (
          <Row
            key={index}
            cells={row.cells}
            onAddClick={onAddColumnClick(index)}
            onRemoveClick={onRemoveRowClick(index)}
            onRemoveColumnClick={onRemoveColumnClick(index)}
            onEditColumnClick={onEditColumnClick(index)}
          />
        ))}
        <Row isEmpty={true} onAddClick={onEmptyRowAddColumnClick} />
      </div>
      {itemToBeAdded && (
        <Modal onCloseClick={() => setItemToBeAdded()}>
          <List style={styles.componentsContainer}>
            {components.map((component, index) => (
              <InvoiceComponent
                key={index}
                {...component}
                onClick={onComponentClick(component)}
              />
            ))}
          </List>
        </Modal>
      )}
    </div>
  );
};

const Row = ({
  isEmpty,
  cells = [],
  onAddClick,
  onRemoveClick,
  onRemoveColumnClick,
  onEditColumnClick,
}) => {
  return (
    <div style={styles.emptyRowContainer}>
      <RowActions
        isEmpty={isEmpty}
        onAddClick={onAddClick}
        onRemoveClick={onRemoveClick}
      />
      <div style={styles.rowcellsContainer}>
        {cells.map((column, index) => (
          <Column
            key={index}
            {...column}
            onRemoveClick={onRemoveColumnClick(index)}
            onEditClick={onEditColumnClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

const RowActions = ({ isEmpty = false, onAddClick, onRemoveClick }) => {
  return (
    <div style={styles.rowActionsContainer}>
      <div style={styles.rowActionsWrapper}>
        <Add
          style={{ color: "white", cursor: "pointer" }}
          onClick={onAddClick}
        />
        <Clear
          style={{
            color: isEmpty ? "rgba(0,0,0,0.5)" : "white",
            cursor: "pointer",
            marginTop: 10,
          }}
          onClick={onRemoveClick}
        />
      </div>
    </div>
  );
};

const Column = ({ name, onRemoveClick, onEditClick = () => {} }) => {
  return (
    <div style={styles.columnContainer}>
      <ColumnActions onRemoveClick={onRemoveClick} onEditClick={onEditClick} />
      <div style={styles.columnContentContainer}>
        <p>{name}</p>
      </div>
    </div>
  );
};

const ColumnActions = ({ onRemoveClick, onEditClick }) => {
  return (
    <div style={styles.columnActionsContainer}>
      <div style={styles.columnActionsWrapper}>
        <Edit
          style={{
            width: "0.8em",
            height: "0.8em",
            color: "white",
            cursor: "pointer",
          }}
          onClick={onEditClick}
        />
        <Clear
          style={{
            color: "white",
            cursor: "pointer",
          }}
          onClick={onRemoveClick}
        />
      </div>
    </div>
  );
};

const InvoiceComponent = ({ name, data = [], onClick }) => {
  return (
    <div>
      <ListItem button style={styles.componentContainer} onClick={onClick}>
        <Text color="primary" style={styles.componentName}>
          {name}
        </Text>
        <div style={styles.dataContainer}>
          {data.map((field, index) => (
            <Text key={index} primary={`[${field}]`} />
          ))}
        </div>
      </ListItem>
    </div>
  );
};

const emptyRowContainer = {
  display: "flex",
  width: "100%",
  height: 200,
  backgroundColor: "#ECECEC",
};

const styles = {
  container: {
    width: "80%",
    margin: "0 auto",
    padding: "0 50px",
  },
  wrapper: {
    width: "100%",
    height: "100%",
  },
  rowActionsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 40,
    height: "100%",
    backgroundColor: "white",
  },
  rowActionsWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    backgroundColor: "#757575",
  },
  emptyRowContainer,
  rowContainer: {
    ...emptyRowContainer,
  },
  rowcellsContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
  },
  columnContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "auto",
    backgroundColor: "#BCBCBC",
    margin: 20,
    padding: 5,
  },
  columnContentContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#D0D0D0",
  },
  columnActionsContainer: {},
  columnActionsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  componentsContainer: {
    width: "100%",
    cursor: "pointer",
  },
  componentContainer: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#D8D8D8",
    marginBottom: 10,
  },
  componentName: {
    width: "40%",
  },
  dataContainer: {
    width: "50%",
  },
};

export default InvoiceGenerator;
