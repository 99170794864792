import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Form from "neoco/form";
import { H1, Card } from "../../ui";
import { beforeSave, filterHeaders } from "./utils";
import { getRoutePath } from "../../utils/routes";
import { isEmptyObject } from "../../utils/object";
import alertify from "alertifyjs";
import { Button } from "../../../../bluejay-ui";

const getInitialState = (sections) =>
  sections.reduce(
    (reducer, { fields }) => ({
      ...reducer,
      ...fields.reduce(
        (fieldsReducer, { property, upsertOptions }) => ({
          ...fieldsReducer,
          ...(upsertOptions?.value ? { [property]: upsertOptions.value } : {}),
        }),
        {}
      ),
    }),
    {}
  );

const ModelUpsert = ({ header, children }) => {
  const [state, setState] = useState({
    data: getInitialState(header.sections),
    controluser:header.controluser?header.controluser:false,
    testcontrol:header.testcontrol?header.testcontrol:null,
    lockData:header.lockData?header.lockData:null,
    testuser:header.testuser?header.testuser:null,
    aux: {},
    openmodaluser:false,
    openmodalreturnuser:false,
    disabled:false,

  });
  const mountf = header.options.mount?header.options.mount:null;
  const unmountf = header.options.unmount?header.options.unmount:null;

  const history = useHistory();
  const params = useParams();
  const { t } = useTranslation();
  const interval  = useRef({ interval: null});

  const {
    findOneRequest = () => Promise.resolve(),
    upsertRequest = () => Promise.resolve(),
  } = header.options?.requests || {};

  const {
    upsertOptions: {
      onMount = () => Promise.resolve(),
      renderAfter = () => <></>,
      onLoadMapping = (data) => Promise.resolve(data),
      itemBeforeSave = (data) => Promise.resolve(data),
      afterSave = (data) => Promise.resolve(data),
    } = {},
  } = header.options;

  const { id } = params;
  const isCreating = typeof id === "undefined";
  const path = getRoutePath(header.options.route);
  const renderChildren = children || header.options?.upsertOptions?.children;
  const allFields = header.sections.reduce(
    (reducer, section) => [...reducer, ...section.fields],
    []
  );
  const updateState = (nextState) =>
    setState((currentState) => ({ ...currentState, ...nextState }));

  useEffect(() => {
    if (!isCreating) {
      findOneRequest({ id }).then((data) =>
        onLoadMapping(data).then((data) => { updateState({ data }); 
            if (state.controluser && state.testcontrol) {
              const showMessage =   state.testcontrol(data);
              if(showMessage){
                var editable = [];
                editable["openmodaluser"]= true;
                editable["disabled"]= true;
                setState(currentState => ({...currentState, ...editable}));
              } else {
                initTestUser();
              }
            }
         }
        )
      );
    }

    onMount().then((aux) => updateState({ aux }));
  }, [params.id]);

  const onSubmit = () => {
    return beforeSave({ header, state })
      .then(itemBeforeSave)
      .then(upsertRequest)
      .then((res) => {
        alertify.notify("Datos guardados correctamente", "success");
        if (!id) history.replace(`${path}/${res.id}`);
        return res;
      })
      .then((data) => afterSave({ data, state, updateState }));
  };

  const handleChange = (data) => {
    updateState({ data: { ...state.data, ...data } });
  };

  useEffect(() => {
    return () => {
      debugger;
      if (interval.current.interval) {
        clearInterval(interval.current.interval);
      }
      //UNMOUNT
      if (unmountf) {
        unmountf(id);
      }
  }
  }, [])

  window.onbeforeunload = (event) => {
    if (unmountf) {
      unmountf(id);
    }
  };

  const setEditable = () => {
    if (state.lockData){
      state.lockData(id);
    }
    var editable = [];
    editable["openmodaluser"]= false;
    editable["disabled"]= false;
    setState(currentState => ({...currentState, ...editable}));
    initTestUser();
  }

  const setReturnEditable = () => {
    if (state.lockData){
      state.lockData(id);
    }
    var editable = [];
    editable["openmodalreturnuser"]= false;
    editable["disabled"]= false;
    setState(currentState => ({...currentState, ...editable}));
    initTestUser();
  }

  const initTestUser = () => {
    if (state.testuser && id){
      if (interval.current.interval) {
        clearInterval(interval.current.interval);
      }
      var intervalAux = setInterval(() => {
        state.testuser()(id).then((data)=>{
          if(!data.data){
            clearInterval(interval.current.interval);
            var editable = [];
            editable["openmodalreturnuser"]= true;
            editable["disabled"]= true;
            setState(currentState => ({...currentState, ...editable}));
          }
        });
      }, 5000);

      interval.current.interval = intervalAux;
    }
  }

  return (
    <>
      <H1 style={{ marginTop: 10 }}>
        <ActionSpan>{t(`general.${id ? "edit" : "create"}`)}</ActionSpan>{" "}
        {t("general.one" + header.options?.name)}
      </H1>
      <Card>
        {isCreating || (!isCreating && !isEmptyObject(state.data)) ? (
          <><Form
            headers={filterHeaders(header, t)}
            onSubmit={onSubmit}
            state={state}
            updateState={updateState}
            handleChange={handleChange}
            children={renderChildren}
          />

          {state.openmodaluser && <ModalBack>

              <Modal>
                  <h4>Hay otro usuario editando la noticia</h4>
                  <ModalButton>
                    <Button onClick={() => setState(currentState => ({...currentState, ["openmodaluser"]:  false}))} style={{background:"red",marginRight:"0.5em", color:"white"}}>Cancelar</Button>
                    <Button onClick={setEditable} style={{background:"darkgreen",marginLeft:"0.5em", color:"white"}}>Tomar posesión</Button>
                  </ModalButton>
              </Modal>

              </ModalBack>}
              {state.openmodalreturnuser && <ModalBack>
                <Modal>
                    <h4>Un usuario acaba de coger posesión de la noticia</h4>
                    <ModalButton>
                      <Button onClick={() => setState(currentState => ({...currentState, ["openmodaluser"]:  false}))} style={{background:"red",marginRight:"0.5em", color:"white"}}>Cancelar</Button>
                      <Button onClick={setReturnEditable} style={{background:"darkgreen",marginLeft:"0.5em", color:"white"}}>Tomar posesión</Button>
                    </ModalButton>
                </Modal>

                </ModalBack>
              }

          </>
        ) : (
          <></>
        )}
        {typeof renderAfter === "function"
          ? renderAfter({ state })
          : renderAfter}
      </Card>
    </>
  );
};

const ModalBack = styled.div`
    display:flex;
    position:fixed;
    width:100%;
    height:100vh;
    background:rgba(0,0,0,0.5);
    top: 0;
    left: 0;
    z-index: 1000;
`;

const Modal =  styled.div`
    display:flex;
    width:600px;
    background:white;
    height: fit-content;
    flex-direction: column;
    margin: auto;
    border-radius: 4px;
    padding: 1em;
    text-align: center;
`;

const ModalButton =  styled.div`
    display:flex;
    flex-direction: row;
    justify-content: center;
`;

const ActionSpan = styled.span`
  color: #9da1a7;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 400;
`;

export default ModelUpsert;
