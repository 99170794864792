export const convertToSlug = (value) =>
  value
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\s+/g, " ")
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");

export const injectUrlSeo = ({ name, value }) => ({
  [name]: value,
  urlSeo: convertToSlug(value),
});

export const injectSlug = ({ name, value }) => ({
  [name]: value,
  slug: convertToSlug(value),
});

export const getUrlImage = (value) => {
  const hasImage = value?.includes("image");
  var imageUrl =  `${process.env.REACT_APP_API_URL}${hasImage ? "" : "image/"}${value}`;
  return imageUrl? imageUrl.replace('\/\/image\/','\/image\/'):imageUrl;
};
