import { getRequests, uploadFileRequest, mediolibraryrequest } from "utils/requests";
import { image, imageLibrary } from "utils/fields";
import { DateTime } from "luxon";

const requests = getRequests("event");

const headers = {
  type: "CRUD",
  options: {
    name: "Agenda",
    route: "/events",
    requests: {
      ...requests,
      uploadFileRequest,
      mediolibraryrequest: mediolibraryrequest,
      medialibrary: getRequests("medialibrary"),
    },
    tableOptions: {
      isEditable: true,
      isDeletable: true,
      displayItemsPerPage: false,
      isSearchable: true
    },
  },
  sections: [
    {
      fields: [
        { property: "title", label: "Título", 
          tableOptions: {
            isSortable: true,
            sortProperty: "titleOrder",
          } 
        },
        imageLibrary({ label: "Imagen", property: "urlImage" }),
        {
          property: "startDate",
          label: "Fecha de inicio",
          type: "date",
          upsertOptions: {
            beforeSave: ({ state, field }) => {
              return state?.startDate
                ? DateTime.fromISO(state?.startDate).toFormat(
                    `yyyy-MM-dd'T'00:00:00'Z'`
                  )
                : "";
            },
          },
          tableOptions: {
            isSortable: true,
            format: (field, state) => {
              return state
                ? DateTime.fromISO(state).toFormat("dd/MM/yyyy")
                : "";
            },
          },
        },
        {
          property: "endDate",
          label: "Fecha de fin",
          type: "date",
          upsertOptions: {
            beforeSave: ({ state, field }) => {
              return state?.endDate
                ? DateTime.fromISO(state?.endDate).toFormat(
                    `yyyy-MM-dd'T'00:00:00'Z'`
                  )
                : "";
            },
          },
          tableOptions: {
            isSortable: true,
            format: (field, state) => {
              return state
                ? DateTime.fromISO(state).toFormat("dd/MM/yyyy")
                : "";
            },
          },
        },
        { property: "location", label: "Localización", 
          tableOptions: {
            isSortable: true,
            sortProperty: "locationOrder",
          }  
        },
        { property: "url", label: "URL", 
          tableOptions: {
            isSortable: true
          }  
        },
        { property: "description", label: "Descripción", 
          tableOptions: {
            isSortable: true,
            format: (field, state) => {  return state? state.replace(/<\/?[^>]+(>|$)/g, "")  : "";  } 
          }  
        },
      ],
    },
  ],
};

export default headers;
