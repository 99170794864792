import {uploadFileRequest,getRequests } from "utils/requests";
import { image } from 'utils/fields';

const requests = getRequests("logo");

const headers = {
  type: "CRUD",
  options: {
    name: "Logo",
    route: "/logo",
    requests,
    tableOptions: {
      isEditable: true,
      isDeletable: false,
      displayItemsPerPage: false,
      isSearchable: false,
      isCreatable:false
    },
  },
  sections: [
    {
      fields: [
        image({
          label: 'Imagen',
          property: 'urlImage',
          type: 'file',
          upsertOptions: {
            beforeSave: ({ state, field }) => {
              if (state.urlImage?.name?.length) {
                const blob = new Blob([state.urlImage]);
                const filename = `${(+new Date()).toString(36)}${
                  state.urlImage?.name
                }`;

                uploadFileRequest({
                  file: blob,
                  filename,
                });
                return filename;
              } else {
                return state?.urlImage;
              }
            },
          },
        }),
        { property: "name", label: "Nombre" },
        { property: "link", label: "Link" },
      ],
    },
  ],
};

export default headers;
