import {
  getRequests,
  getNewByType,
  uploadFileRequest,
  controlNewsDefaultProps,
  mediolibraryrequest
} from "utils/requests";
import { convertToSlug } from "utils/string-utils";
import { image, dateCreate, imageLibrary } from "utils/fields";

const requests = getRequests("news");

const headers = {
  type: "CRUD",
  options: {
    name: "Dulces & Aperitivos",
    route: "/sweetandsnack",
    requests: {
      ...requests,
      uploadFileRequest,
      findRequest: getNewByType({ type: "CONTENIDOS" }),
      upsertRequest: controlNewsDefaultProps(requests),
      mediolibraryrequest: mediolibraryrequest,
      medialibrary: getRequests("medialibrary"),
    },
    tableOptions: {
      isEditable: true,
      isDeletable: true,
      displayItemsPerPage: false,
      isSearchable: true,
    },
    upsertOptions: {
      itemBeforeSave: (data) => {
        let item = {
          ...data,
          slug: convertToSlug(data.title || ''),
        };
        delete item.file;
        return Promise.resolve(item);
      },
    },
  },
  sections: [
    {
      fields: [
        {
          label: "Título",
          property: "title",
          required: true,
          tableOptions: {
            isSortable: true,
          }
        },
        {
          label: "Año",
          property: "year",
          required: true,
          type: 'number',
          tableOptions: {
            show: false,
          },
        },
        dateCreate,
        {
          label: "URL",
          property: "slug",
          required: true,
          tableOptions: {
            show: false,
          },
          upsertOptions: {
            show: false,
          },
        },
        imageLibrary({ label: "Imagen", property: "imageprin" }),
        {
          label: "Tipos",
          property: "type",
          tableOptions: { show: false },
          upsertOptions: {
            value: "CONTENIDOS",
            show: false,
          },
        },
        {
          label: "Descripción Corta",
          property: "shortDescription",
          type: "html",
          value: ({ state }) => state?.data?.shortDescription,
          style: {
            flexBasis: "100%",
            zIndex: 0,
          },
          tableOptions: {
            show: false,
          },
          controlProps: {
            init: {
              image_caption: true,
            },
          },
        },
        {
          label: "Content",
          property: "content",
          type: "html",
          value: ({ state }) => state?.data?.content,
          style: {
            flexBasis: "100%",
            zIndex: 0,
          },
          tableOptions: {
            show: false,
          },
          controlProps: {
            init: {
              image_caption: true,
            },
          },
        },
        {
          tableOptions: { show: false },
          label: 'translate',
          type: 'translate'
        },
      ],
    },
  ],
};

export default headers;
