import React, { useContext } from "react";
import CreatableSelect from "react-select/creatable";
import { defaultTheme, themable } from "../Theme/";
import Select from "react-select";
import styled from "styled-components";
import Label from "../Label";
import { ThemeContext } from "styled-components";

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const getRecalculatedPadding = (val = "") => {
  const sizes = val.split(" ");
  return (
    sizes
      .map((size, index) => {
        const currentNum = parseInt(size.replace("px", ""));
        const result = (index + 1) % 2 === 0 ? currentNum - 3 : currentNum - 5;
        return result >= 0 ? result : 0;
      })
      .join("px ") + "px"
  );
};

const customStyles = ({ theme, disabled }) => {
  const state = disabled ? "disabled" : "active";
  const commonStyles = {
    fontSize: themable({
      theme,
      props: [`input.fontSize`, "16px"],
    }),
    fontFamily: themable({
      theme,
      props: [`input.fontFamily`, "fontFamily"],
    }),
    color: themable({
      theme,
      props: [`input.${state}.color`, "default"],
    }),
  };

  return {
    option: (provided) => ({
      ...provided,
      ...commonStyles,
    }),
    placeholder: (provided) => ({
      ...provided,
      ...commonStyles,
      color: themable({
        theme,
        props: [`input.${state}.placeholderColor`, "default"],
      }),
      fontSize: themable({
        theme,
        props: [`input.placeholderFontSize`, "16px"],
      }),
    }),
    menu: (provided) => ({
      ...provided,
      ...commonStyles,
      borderRadius: themable({
        theme,
        props: [`input.borderRadius`, "borderRadius", "10px"],
      }),
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: getRecalculatedPadding(
        themable({
          theme,
          props: [`input.padding`, "10px 20px"],
        })
      ),
    }),
    menuList: (provided) => ({
      ...provided,
      ...commonStyles,
    }),
    control: (provided, selectState) => {
      const focus = selectState.isFocused
        ? "borderFocusColor"
        : `${state}.borderColor`;
      return {
        ...provided,
        ...commonStyles,
        border: `${themable({
          theme,
          props: [`input.${state}.borderWidth`, "1px"],
        })} solid ${themable({
          theme,
          props: [focus, "default"],
        })}`,
        backgroundColor: themable({
          theme,
          props: [`input.${state}.backgroundColor`, "secondary"],
        }),
        borderRadius: themable({
          theme,
          props: [`input.${state}.borderRadius`, "10px"],
        }),
        boxShadow: "none",
        "&:hover": {
          borderColor: "none",
        },
      };
    },
    singleValue: (provided, selectState) => ({
      ...provided,
      top: selectState.isFocused ? 2 : 12,
    }),
  };
};

const MultiSelect = (props) => {
  const theme = useContext(ThemeContext);
  const {
    id,
    label,
    showLabel = true,
    Label: CustomLabel = Label,
    isCreatable = true,
    disabled = false,
  } = props;

  return (
    <InputContainer>
      {showLabel && label && <CustomLabel htmlFor={id}>{label}</CustomLabel>}
      {isCreatable ? (
        <CreatableSelect
          isDisabled={disabled}
          styles={customStyles({ theme, disabled })}
          {...props}
        />
      ) : (
        <Select
          isDisabled={disabled}
          styles={customStyles({ theme, disabled })}
          {...props}
        />
      )}
    </InputContainer>
  );
};

MultiSelect.defaultProps = {
  theme: defaultTheme,
};

export default MultiSelect;
