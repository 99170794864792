import styled from "styled-components";
import { Text } from "../../../bluejay-ui";

const H1 = styled(Text)`
  color: black;
  font-size: 36px;
  font-weight: 600;
`;

export default H1;
