const primary = "#19C3FC";
const secondary = "#fff";
const _default = "#9DA1A7";
const black = "#4B4B4B";
const disabled = "#9B9B9B";
const fontFamily = "'Roboto', sans-serif";
const borderRadius = "10px";
const padding = "10px 16px";
const fontSize = "14px";
const width = "15px";

const buttonsColors = {
  primary: {
    background: primary,
    text: secondary,
  },
  secondary: {
    background: secondary,
    text: "#247BC4",
  },
  default: {
    background: _default,
    text: "white",
  },
};

const paginationColors = {
  active: {
    ...buttonsColors.primary,
  },
  default: {
    background: secondary,
    text: _default,
  },
  arrows: {
    fillColor: _default,
  },
};

export const colors = {
  primary,
  secondary,
  default: _default,
  black,
  disabled,
};

const theme = {
  fontFamily,
  borderRadius,
  ...colors,
  button: {
    fontFamily,
    borderRadius,
    padding,
    ...buttonsColors,
  },
  pagination: {
    fontFamily,
    borderRadius,
    borderColor: _default,
    borderWidth: "1px",
    padding: "8px",
    ...paginationColors,
    mobile: {
      borderRadius,
      borderColor: _default,
      borderWidth: "1px",
      padding: "8px 13px",
    },
  },
  link: {
    fontFamily,
    fontSize,
    padding,
    ...buttonsColors,
    default: {
      text: _default,
    },
  },
  input: {
    fontFamily,
    fontSize: fontSize,
    placeholderFontSize: fontSize,
    borderFocusColor: primary,
    borderRadius,
    padding: "10px 20px",
    active: {
      borderColor: _default,
      backgroundColor: "transparent",
      color: _default,
      placeholderColor: _default,
      borderWidth: "1px",
    },
    disabled: {
      borderColor: "hsl(0,0%,90%)",
      backgroundColor: "hsl(0,0%,95%)",
      color: "hsl(0,0%,60%)",
      placeholderColor: _default,
      borderWidth: "1px",
    },
    checkbox: {
      width,
      borderWidth: "2px",
      borderRadius: "6px",
    },
    radio: {
      width,
    },
  },
  label: {
    fontFamily,
    color: _default,
    fontSize,
  },
  text: {
    color: primary,
    padding: "0px 0px 10px 0px",
  },
  modal: {
    fontFamily,
    borderRadius,
    padding: "10px 15px",
    maxWidth: "500px",
    blur: {
      backgroundColor: secondary,
    },
    backgroundColor: secondary,
    backdropFilter: "blur(10px)",
    opacity: "0.6",
  },
  card: {
    backgroundColor: secondary,
    borderRadius,
    padding: "10px 15px",
    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
  },
  sidebar: {
    backgroundColor: secondary,
    icon: {
      fill: _default,
    },
  },
};

export default theme;
