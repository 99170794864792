import { Input, Label, Button } from "../../bluejay-ui";
import { useEffect, useState } from "react";
import {
  findOneRequest,
  uploadMultipleFileRequest,
  updateImageGallery,
  upsertRequest,
  mediolibraryrequest,
  uploadFileRequest,
  getRequests
} from "utils/requests";
import ImageUploader from "neoco/image-uploader";
import Imagelibrary from "neoco/form/utils/inputs/imagelibrary/imagelibrary";
import MediaLibrary from "neoco/form/utils/inputs/mediolibrary/MediaLibrary";



const UpsertGallery = ({ history, match }) => {
  const [state, setState] = useState({ data: { name: "", images: [] } });
  const [uploader, setUploader] = useState(false);
  const [buttonText, setButton] = useState("Guardar");
  const [dataOpen, setDataOpen] = useState({
    callback: null,
    value: null,
    meta: null,
    open:false
  });

  useEffect(() => {
    if (match.params.id)
      findOneRequest(
        "gallery",
        true
      )({ id: match.params.id }).then((response) => {
        response.images.sort(function (a, b) {
          if (a.orden > b.orden) return 1;
          if (a.orden < b.orden) return -1;
          return 0;
        });
        setState({ data: response });
      })
    else
      upsertRequest("gallery", true)({
        name: '',
        images: []
      }).then(response => setState({ data: response }))
  }, [match.params.id]);

  const onSubmit = (e) => {
    e.preventDefault()
    setButton("Guardando...")
    return upsertRequest("gallery", true)({ ...state.data }).then(() => {
      var i = 1;
      if (state.data.images.length == 0) history.push("/gallery")
      else
        for (let index = 0; index < state.data.images.length; index++) {
          state.data.images[index].gallery = { id: state.data.id }
          updateImageGallery(state.data.images[index]).then(() => {
            if (i == state.data.images.length) history.push("/gallery")
            i++
            setButton("Guardado")
          })
        }
    }).catch(err => setButton("Se ha producido un error"))
  };

  const handleChangeImage = x => {
    var a = state.data.images
    for (const key in x) {
      var newimg = {
        name: x[key],
        url: x[key],
        orden: a.length + 1,
        footer: "Pie de foto"
      }
      a.push(newimg)
    }
    debugger;
    state.data.images = a;
    setState(state)
    setUploader(!uploader)
  }
  const handleChange = data => {
    const { name, ...image } = data;
    if (data instanceof File) {
      uploadMultipleFileRequest({ files: [data] }).then(x => {
        var a = state.data.images
        a.push(...x)
        a[a.length - 1].orden = a.length
        a[a.length - 1].footer = "Pie de página"
        setState((currentState) => ({
          data: { ...currentState.data, images: a },
        }))
        setUploader(!uploader)
        return;
      })
    }
    if (data.nombre)
      setState((currentState) => ({
        data: { ...currentState.data, name },
      }))
    else {
      const nextImages = state.data.images;
      const currentImageName = Object.keys(image)?.[0];
      nextImages[currentImageName?.split("-")?.[1]] = image?.[currentImageName];
      setState((currentState) => ({
        data: { ...currentState.data, images: nextImages },
      }));
    }
  };

  const deleteImage = (x) => {
    var img = state.data.images.filter(function(image) {
        return image !== x
    });
    setState((currentState) => ({
      data: { ...currentState.data, images: img },
    }))

  }

  return (
    <div style={{ padding: '20px 50px', margin: '0 auto' }}>
      <form onSubmit={onSubmit}>
        <Label>Nombre de la galería</Label>
        <Input required style={{ backgroundColor: 'white' }} value={state.data.name} onChange={x => handleChange({ name: x.target.value, nombre: true })} />
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
          <div style={{ padding: '10px' }} key={uploader}><Imagelibrary onChange={data => handleChangeImage(data)} setDataOpen={setDataOpen} multiple={true}/></div>
          {state.data.images.map(x =>
            <div style={{ padding: '10px' }} key={x.name}>
              <Imagelibrary
                source={{
                  uri: `${process.env.REACT_APP_API_URL}image/${x.name}`,
                  name: x.name,
                }}
                label={`${process.env.REACT_APP_API_URL}image/${x.name}`}
                setDataOpen={setDataOpen}
              />
              <Label>Orden</Label>
              <Input style={{ backgroundColor: 'white' }} value={x.orden} type="number" onChange={y => { x.orden = Number(y.target.value); handleChange(x) }} />
              <Label>Pie de foto </Label>
              <Input style={{ backgroundColor: 'white' }} value={x.footer} onChange={y => { x.footer = y.target.value; handleChange(x) }} />
              <span style={{backgroundColor: 'red', border:'none', borderRadius:'6px', color:"white", padding: "0.5em 1em", cursor:"pointer"}} onClick={() => deleteImage(x)}>Borrar</span>
            </div>
          )}
        </div>
        <Button style={{ marginTop: 20 }} color="primary">
          {buttonText}
        </Button>
      </form>
      <MediaLibrary mediolibraryrequest={mediolibraryrequest} dataOpen={dataOpen} uploadmedia={getRequests("medialibrary")} uploadFileRequest={uploadFileRequest}/>
    </div>
  );
};

export default UpsertGallery;
