import styled from "styled-components";
import { defaultTheme, themable } from "../Theme";

const Card = styled.div`
  ${({ theme, color }) => `  
    border-radius: ${themable({
      theme,
      props: [`card.borderRadius`, `borderRadius`, "10px"],
    })};
    background-color: ${themable({
      theme,
      props: [`card.${color}.background`, color, "secondary"],
    })};
    padding: ${themable({
      theme,
      props: [`card.padding`, "10px 15px"],
    })};
    background-color: ${themable({
      theme,
      props: [`card.boxShadow`, "0px 0px 15px rgba(0, 0, 0, 0.2)"],
    })};
  `}
`;

Card.defaultProps = {
  theme: defaultTheme,
};

export default Card;
