import { getRequests, getTagsWithSearch, findAllRequest } from "utils/requests";

const requests = getRequests("tag");

const headers = {
  type: "CRUD",
  options: {
    name: "Etiquetas",
    route: "/tags",
    requests: {
      ...requests,
      findRequest: getTagsWithSearch(),
    },
    tableOptions: {
      isSearchable: true,
      isEditable: true,
      isDeletable: true,
      displayItemsPerPage: false,
    },
    upsertOptions: {
      onMount: () =>
        Promise.all([
          findAllRequest("tag", true)(),
        ]).then(
          ([
            { data: tags },
          ]) => ({
            tags,
          })
        ),
    },
  },
  sections: [
    {
      fields: [
        {
          property: "name",
          label: "Nombre",
          style: {
            flexBasis: "48%",
          },
          tableOptions: {
            isSortable: true,
            sortProperty :"nameOrder"
          }
        },
        {
          label: "Fusionar con etiqueta",
          type: "multiselect",
          accion: "fusion",
          tableOptions: { show: false },
          style: {
            flexBasis: "48%",
          },
          relation: {
            name: "tags",
            isMulti: false,
            sort: true,
            primaryKey: "id",
            nameProps: ["name"],
          },
        },
        {
          tableOptions: { show: false },
          label: "fusion",
          type: "fusion",
        }
        // { property: "idWordpress", label: "ID WordPress", type: "number" },
      ],
    },
  ],
};

export default headers;
