import request from "./request";
import requestMultipart from "./requestmultipart";
import alertify from "alertifyjs";

const isAdmin = (admin) => (admin ? "admin/" : "");

export const loginRequest = (credentials) =>
  request(`login`, {
    method: "POST",
    body: JSON.stringify({
      email: credentials.email,
      pass: credentials.password,
    }),
  }).then((res) => {
    if (res?.user) {
      window.location = "/news";
      return Promise.resolve(res);
    }
  });

export const findAllRequest = (endpoint, admin) => () => {
  return request(`${isAdmin(admin)}${endpoint}/all`);
};

export const findAllRequestOrderName = (endpoint, admin) => () => {
  return request(`${isAdmin(admin)}${endpoint}/combo/ordername/all`);
};


export const findAllPositionNad = () => () => {
  return request(`admin/positionads/nad/combo/ordername/all`);
};

export const findAllPositionNormal = () => () => {
  return request(`admin/positionads/normal/combo/ordername/all`);
};

export const findAllRequestOrderTitle = (endpoint, admin) => () => {
  return request(`${isAdmin(admin)}${endpoint}/combo/ordertitle/all`);
};


export const findRequest =

  (endpoint, admin) =>
    ({ pagination: { page }, filter } = { pagination: { page: 1 } }) => {
      if (filter?.searchValue || filter?.filtersSort) {
        return request(`${isAdmin(admin)}${endpoint}/search/${page - 1}`, {
          method: "POST",
          body: JSON.stringify({ text: filter.searchValue, filtersSort: filter.filtersSort,  selectedColumnSort: filter.selectedColumnSort}),
        });
      } else if (filter?.length === 2) {
        return request(`${isAdmin(admin)}${endpoint}/search/${page - 1}`, {
          method: "POST",
          body: JSON.stringify(
            filter
              .map((item) => ({ [item.key]: item.value }))
              .reduce((output, filter) => {
                return { ...output, ...filter };
              }, {})
          ),
        });
      } else {
        return request(`${isAdmin(admin)}${endpoint}/page/${page - 1}`);
      }
    };

export const findOneRequest = (endpoint, admin) => (data) => {
  const { id } = data;
  return request(`${isAdmin(admin)}${endpoint}/one/${id}`).then(
    (res) => res.data
  );
};

export const upsertRequest = (endpoint, admin) => async (data) => {
  debugger;
  const { id } = data;
  return await request(`${isAdmin(admin)}${endpoint}/${id && id != "undefined" ? id : ""}`, {
    method: id && id != "undefined"  ? "PUT" : "POST",
    body: JSON.stringify(data),
  })
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.error));
};

export const deleteRequest =
  (endpoint, admin) =>
    ({ id }) =>
      request(`${isAdmin(admin)}${endpoint}/${id}/logic`, {
        method: "DELETE",
      });

export const countRequest =
  (endpoint) =>
    ({ data }) => {
      return Promise.resolve({ count: data.total, numPages: data.numPage });
    };

export const uploadGalleryRequest = ({ file }) => {
  const formData = new FormData();
  formData.append("file", file);

  return request(`admin/gallery`, {
    method: "POST",
    headers: {
      "Content-Type": undefined,
    },
    body: formData,
  }).then((response) => response.data);
};

export const uploadFileRequest = ({ file, filename, extension = ".jpg" }) => {
  const formData = new FormData();

  const name = filename
    ? filename
    : `${new Date().getTime()}-${file?.name || extension}`
      .replace(/\s/g, "")
      .replace(/\//g, "");
  formData.append("file", file, name);

  return request(`admin/upload`, {
    method: "POST",
    headers: {
      "Content-Type": undefined,
    },
    body: formData,
  }).then((response) => response.data);
};

export const uploadMultipleFileRequest = ({ files }) => {
  const formData = new FormData();
  files
    .filter((file) => !!file)
    .forEach((file) => {
      const name = `${new Date().toLocaleString()}-${file?.name || ".jpg"}`
        .replace(/\s/g, "")
        .replace(/\//g, "");
      formData.append("files", file, name);
    });

  return request(`admin/upload/multiple`, {
    method: "POST",
    headers: {
      "Content-Type": undefined,
    },
    body: formData,
  }).then((response) => response.data);
};

export const updateImageGallery = (data) => {
  return request(`admin/image/update/${data.id}`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
}

export const saveStructurePageRequest = (data) => {
  return request("admin/structurepage/"+data.id, {
    method: "PUT",
    body: JSON.stringify(data),
  }).then(x => {
    if (x.code == 202) alertify.success('¡Se ha modificado el home con éxito!')
    else alertify.error('Se ha producido un error. Inténtelo de nuevo')
  });
};

export const getNewByType = ({ type }) => (
  { pagination: { page }, filter } = { pagination: { page: 1 }, filter: null }
) => {
  const searchValue = filter?.searchValue;
  const filtersSort = filter.filtersSort;
  const selectedColumnSort = filter?.selectedColumnSort;

  const isLive = filter?.filters?.filter((f) => f.value === "isLive")[0]
    ?.children.value;
  const sponsoring = filter?.filters?.filter((f) => f.value === "sponsoring")[0]
    ?.children.value;
  // const state = filter?.filters?.filter((f) => f.value === "state")[0]?.children.value;
  // console.log(state);
  return request(`admin/news/search/${page - 1}`, {
    method: "POST",
    body: JSON.stringify({
      newsType: type,
      // ...(searchValue !== undefined ? { state: "BORRADOR" } : null),
      ...(searchValue !== undefined ? { title: searchValue } : null),
      ...(isLive !== undefined ? { isLive: isLive } : {}),
      ...(sponsoring !== undefined ? { sponsoring: sponsoring } : {}),
      ...(filtersSort != undefined ? {filtersSort: filtersSort}: {}),
      ...(selectedColumnSort != undefined ? {selectedColumnSort: selectedColumnSort}: {}),
      // ...(state !== undefined ? { state: state } : {}),
    }),
  });
};

export const getAllBuyGuideCategorieRequest = () =>
  request(`admin/buyGuideCategory/all`);

export const getBMPWithSearch = () => (
  { pagination: { page }, filter } = { pagination: { page: 1 }, filter: null }
) => {
  const searchValue = filter?.searchValue;
  return request(`admin/businessMeetingPoint/page/${page - 1}`, {
    method: "POST",
    body: JSON.stringify({
      name: searchValue, filtersSort: filter.filtersSort,  selectedColumnSort: filter.selectedColumnSort
    }),
  });
};
export const getBMPNadWithSearch = () => (
  { pagination: { page }, filter } = { pagination: { page: 1 }, filter: null }
) => {
  const searchValue = filter?.searchValue;
  return request(`admin/businessMeetingPointnad/page/${page - 1}`, {
    method: "POST",
    body: JSON.stringify({
      name: searchValue, filtersSort: filter.filtersSort,  selectedColumnSort: filter.selectedColumnSort
    }),
  });
};

export const getIMPWithSearch = () => (
  { pagination: { page }, filter } = { pagination: { page: 1 }, filter: null }
) => {
  const searchValue = filter?.searchValue;
  return request(`admin/innovationMeetingPoint/page/${page - 1}`, {
    method: "POST",
    body: JSON.stringify({
      name: searchValue, filtersSort: filter.filtersSort,  selectedColumnSort: filter.selectedColumnSort
    }),
  });
};

export const getModelWithPaginationSearch = (endpoint) => (
  { pagination: { page }, filter } = { pagination: { page: 1 }, filter: null }
) => {
  const searchValue = filter?.searchValue;

  return request(`admin/${endpoint}/search/${page - 1}`, {
    method: "POST",
    body: JSON.stringify({
      name: searchValue,
      ...filter?.filters,
    }),
  });
};

export const getModelWithPaginationSearchText = (endpoint) => (
  { pagination: { page }, filter } = { pagination: { page: 1 }, filter: null }
) => {
  const searchValue = filter?.searchValue;

  return request(`admin/${endpoint}/search/${page - 1}`, {
    method: "POST",
    body: JSON.stringify({
      text: searchValue,
      ...filter?.filters,
    }),
  });
};

export const getTagsWithSearch = () => (
  { pagination: { page }, filter } = { pagination: { page: 1 }, filter: null }
) => {
  const searchValue = filter?.searchValue;
  return request(`admin/tag/search/${page - 1}`, {
    method: "POST",
    body: JSON.stringify({
      name: searchValue, filtersSort: filter.filtersSort,  selectedColumnSort: filter.selectedColumnSort
    }),
  });
};

export const getNewsWithSearch = () => (
  { pagination: { page }, filter } = { pagination: { page: 1 }, filter: null }
) => {
  const searchValue = filter?.searchValue;
  return request(`admin/news/search/${page - 1}`, {
    method: "POST",
    body: JSON.stringify({
      title: searchValue, filtersSort: filter.filtersSort,  selectedColumnSort: filter.selectedColumnSort
    }),
  });
};

export const getNewsWithSubSearch = () => (
  { pagination: { page }, filter } = { pagination: { page: 1 }, filter: null }
) => {
  const searchValue = filter?.searchValue;
  return request(`admin/subnews/search/${page - 1}`, {
    method: "POST",
    body: JSON.stringify({
      title: searchValue, filtersSort: filter.filtersSort,  selectedColumnSort: filter.selectedColumnSort
    }),
  });
};

export const getCompanyWithSearch = () => (
  { pagination: { page }, filter } = { pagination: { page: 1 }, filter: null }
) => {
  const searchValue = filter?.searchValue;
  return request(`admin/company/search/${page - 1}`, {
    method: "POST",
    body: JSON.stringify({
      title: searchValue, filtersSort: filter.filtersSort,  selectedColumnSort: filter.selectedColumnSort
    }),
  });
};

export const mediolibraryrequest = () => ({ pagination: { page }, filter } = { pagination: { page: 1 }, filter: {} }
) => {
  const searchValue = filter?.searchValue;
  return request(`admin/medialibrary/search/${page - 1}`, {
    method: "POST",
    body: JSON.stringify(filter),
  });
};

export const lockNew = () => (id) => {
  return request(`admin/news/lock/${id}`, {
    method: "PUT",
    body: JSON.stringify({}),
  });
};

export const unlockNew = () => (id) => {
  return request(`admin/news/unlock/${id}`, {
    method: "PUT",
    body: JSON.stringify({}),
  });
};


export const testuser = () => (id) => {
  return request(`admin/news/testuser/${id}`, {
    method: "PUT",
    body: JSON.stringify({}),
  });
};

export const owner = () => (id,owner) => {
  return request(`admin/socialaccounts/updateowner/${id}/${owner}`, {
    method: "GET",
  });
};

export const exportOrdersExcel = () => (filter) => {
  return requestMultipart(`admin/orders/excel`, {
    method: "POST",
    body: JSON.stringify(filter),
  });
};



export const changePassword = (data) => {
  const { id, oldpass, newpass } = data;
  const body = {
    oldpass: oldpass,
    newpass: newpass,
  };
  return request(`admin/user/changepass/${id}`, {
    method: "POST",
    body: JSON.stringify(body),
  });
};

export const controlNewsDefaultProps = (requests) => (data) => {
  const res = requests.upsertRequest({ homePrin: false, ...data });
  if (res["error"]) {
    return Promise.reject(res.error);
  }
  return Promise.resolve(res);
};



export const getRequests = (endpoint, admin = true) => ({
  findRequest: findRequest(endpoint, admin),
  findAllRequest: findAllRequest(endpoint, admin),
  mapFindResponse: ({ data }) => data,
  findOneRequest: findOneRequest(endpoint, admin),
  upsertRequest: upsertRequest(endpoint, admin),
  deleteRequest: deleteRequest(endpoint, admin),
  countRequest: countRequest(endpoint, admin),
});
