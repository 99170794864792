import { getRequests } from "utils/requests";

const requests = getRequests("positionads");

const headers = {
  type: "CRUD",
  options: {
    name: "Posición de anuncios",
    route: "/adsposition",
    requests: {
      ...requests,
    },
    tableOptions: {
      isSearchable: true,
      isEditable: true,
      isDeletable: true,
      displayItemsPerPage: false,
    },
  },
  sections: [
    {
      fields: [
        {
          property: "name",
          label: "Nombre",
          style: {
            flexBasis: "48%",
          },
          tableOptions: {isSortable:true}
        },
        {
          label: 'Noticias al detalle',
          property: 'nad',
          type: 'checkbox',
          tableOptions: { show: true,
            isSortable: false,
            filter: {
              values: [
                { value: true, label: 'Si' },
                { value: false, label: 'No' },
              ],
            },
            format: (item, value) => (value ? 'Si' : 'No'), },
        },
      ],
    },
  ],
};

export default headers;
