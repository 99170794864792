import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import ImageUploader from "neoco/image-uploader";
import { Button } from "../../../../../bluejay-ui";
import {  Input, Label } from "../../../../../bluejay-ui";



const MediaLibraryBodyPanel = styled.div`
  height: calc(100% - 100px - 2.2em );
  overflow-y: auto;
  overflow-x: hidden;

`;

const ImageLib = styled.img`
  width:calc(33% - 20px);
  margin: 10px;
`;


const MediaLibraryBack = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background: rgba(255,255,255,0.6);
    z-index:10000;

`;

const MediaLibraryBody = styled.div`
    display: flex;
    flex-direction: column;
    margin:auto;
    width: 800px;
    max-width:90%;
    height:800px;
    max-height:80%;
    background: white;
    padding:1em;
    border: 1px solid #ccc;
    box-shadow: 0 16px 16px -10px rgba(34,47,62,.15),0 0 40px 1px rgba(34,47,62,.15);

`;

const MediaLibraryH1 = styled.h4`
    height:20px;
    margin:0px;

`;

const MediaLibraryBtn = styled.span`
    display: inline-block;
    cursor: pointer;
    border: none;
    text-transform: uppercase;
    outline: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: #0151A7;
    color: #ffffff;
    border-radius: 0px;
    font-family: 'Assistant',sans-serif;
    padding: 10px 16px;
`;

const MediaLibraryBtnLoadMore = styled.span`
    display: inline-block;
    cursor: pointer;
    border: none;
    text-transform: uppercase;
    outline: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: #f0f0f0;
    background-image: none;
    background-position: 0 0;
    background-repeat: repeat;
    border-color: #f0f0f0;
    color: #000;
    border-radius: 0px;
    font-family: 'Assistant',sans-serif;
    padding: 10px 16px;
`;


const TabBtn = styled.span`
    display: inline-block;
    cursor: pointer;
    border: none;
    text-transform: uppercase;
    outline: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: #0151A7;
    color: #ffffff;
    border-radius: 0px;
    font-family: 'Assistant',sans-serif;
    padding: 10px 16px;
`;

const TabBtnSelected = styled.span`
    display: inline-block;
    cursor: pointer;
    border: none;
    text-transform: uppercase;
    outline: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: #ffffff;
    color: #0151A7;
    border-radius: 0px;
    font-family: 'Assistant',sans-serif;
    padding: 10px 16px;
`;


export default function MediaLibrary(props) {

    const [dataOpen,setDataOpen] = useState(props.dataOpen)
    const [category,setCategory] = useState([])
    const [images, setImages] = useState([])
    const [page, setPage] = useState(1)
    const [selected, setSelected] = useState([])
    const [selectedName, setSelectedName] = useState([])
    const [text,setText] =  useState("")
    const [tab,setTab] =  useState(0)
    const [nImage, setNImage] = useState({name:"",category:"",url:""});
    const [multiple,setMultiple] = useState(props.multiple?props.multiple:(dataOpen.multiple?dataOpen.multiple:false));


    const nameRef = useRef();
    const catRef = useRef();
    const inputRef = useRef();

    const requestmedia = props.uploadmedia;

    const searchImages = (page,text)  => {
      props.mediolibraryrequest()({ pagination: { page: page }, filter:{text: text} }).then((x) => {
          if (x.data && x.data.length > 0 ){
            if (page == 1){
              setImages(x.data)
            } else {
              var ima = [...images, ...x.data]
              setImages(ima)
            }

          }


      });
    }

    useEffect(() => {
      if(props.dataOpen.open) {
        setCategory([]);
        setImages([]);
        setPage(1);
        setSelected([]);
        setSelectedName([]);
        setText("");
        searchImages(1,"");
        setMultiple(props.dataOpen.multiple?props.dataOpen.multiple:multiple)
      }
      setDataOpen(props.dataOpen)
    }, [props.dataOpen]);


    const getImageUrl = (urlimage) => {
        const url = process.env.REACT_APP_API_URL + "image/" + urlimage;
        return url;
    }

    const setImageSelected = () =>{
      if (selected && selected.length > 0){
        if(!multiple){
          dataOpen.callback(process.env.REACT_APP_API_URL +  "image/" +   selectedName[0]  );
          setDataOpen(currentOptions => ({...currentOptions, open:false }));
        } else {
          var urlImages = [];
          selectedName.forEach(element => {
            urlImages.push(process.env.REACT_APP_API_URL +  "image/" + element);
          });
          dataOpen.callback(urlImages);
          setDataOpen(currentOptions => ({...currentOptions, open:false }));
        }
        
      } else {
        alert("Debe seleccionar primero una imagen");
      }
    }

    const searchMore = () => {
    searchImages(page + 1, text);
      setPage(page + 1);

    }

    const handleChange = (e) => {
      setText(e.target.value);
      setImages([]);
      setPage(1);
      setSelected(-1);
      setSelectedName("");
      searchImages(1,e.target.value);

    }



    const onInputChangeNImage = ( name, value ) => {
      setNImage({ ...nImage, [name]: value });
    };

    const uploadMedia = () => {
        if (!nImage["name"] || nImage["name"] == "") {
          alert("Debe indicar primero el nombre");
        } else if (!nImage["url"] || nImage["url"] == "") {
          alert("Debe seleccionar primero una imagen");
        } else {
          requestmedia.upsertRequest(nImage).then((data) => {
              if (data) {
                setNImage({});
                dataOpen.callback(process.env.REACT_APP_API_URL +  "image/" +   data.url  );
                setDataOpen(currentOptions => ({...currentOptions, open:false }));
              }
          })
        }

    }

    const imageOrName = (file) => {
       var name = file.name;
       if (name.toLowerCase().includes(".gif") || name.toLowerCase().includes(".jpg") || name.toLowerCase().includes(".png") || name.toLowerCase().includes(".jpeg")){
          return <img src={URL.createObjectURL(file)} style={{width:"150px"}}/>
       } else {
         return name;
       }

    }

    const clickImage = function(id,name){
        var found = false;
        var selectAux = [];
        var selectAuxName = [];

        for (const key in selected) {
          if (selected[key] == id){
            found = true;
          } else {
            selectAux.push(selected[key]);
            selectAuxName.push(selectedName[key]);
          }
        }

        if(found){
          setSelected(selectAux);
          setSelectedName(selectAuxName);
        } else {
          if(multiple){
            setSelected([...selected,id]);
            setSelectedName([...selectedName,name]);
          } else {
            setSelected([id]);
            setSelectedName([name]);
          }
        }
    }

    const isSelected = function (id){
      var found = false;

      for (const key in selected) {
        if (selected[key] == id){
          found = true;
        }
      }

      return found;
    }


    return (
      <>
      {dataOpen.open && <MediaLibraryBack>
          <MediaLibraryBody>
              <MediaLibraryH1>Galería de medios</MediaLibraryH1>
              <hr style={{width:"100%"}}/>
              { tab == 0 && <div style={{borderBottom:"1px solid #0151A7", marginBottom:"5px", paddingBottom:"0px"}}>
                <TabBtn onClick={() => setTab(0)}>Imagenes</TabBtn>
                <TabBtnSelected onClick={() => setTab(1)}>Nueva Imagen</TabBtnSelected>
                <TabBtnSelected  onClick={() => setTab(2)}>Nuevo GIFT</TabBtnSelected>
              </div>}
              { tab == 1 && <div style={{borderBottom:"1px solid #0151A7", marginBottom:"5px", paddingBottom:"0px"}}>
                <TabBtnSelected  onClick={() => setTab(0)}>Imagenes</TabBtnSelected>
                <TabBtn  onClick={() => setTab(1)}>Nueva Imagen</TabBtn>
                <TabBtnSelected  onClick={() => setTab(2)}>Nuevo GIFT</TabBtnSelected>
              </div>}
              { tab == 2 && <div style={{borderBottom:"1px solid #0151A7", marginBottom:"5px", paddingBottom:"0px"}}>
                <TabBtnSelected  onClick={() => setTab(0)}>Imagenes</TabBtnSelected>
                <TabBtnSelected  onClick={() => setTab(1)}>Nueva Imagen</TabBtnSelected>
                <TabBtn  onClick={() => setTab(2)}>Nuevo GIFT</TabBtn>
              </div>}
              { tab == 0 && <><MediaLibraryBodyPanel>
                    <div style={{height:"65px", textAlign: "center"}}>
                          <label>Buscar: </label> <input type="text" value={text} placeholder="..." onChange={(e) => {handleChange(e)}} style={{lineHeight:"25px"}}/>
                    </div>
                    {images.map((item) => (
                        <ImageLib key={item.id} src={getImageUrl(item.url)} alt={item.name} style={{border: isSelected(item.id) ? "4px solid lightgreen":"none",width: isSelected(item.id) ? "calc(33% - 28px)":"calc(33% - 20px)" }} onClick={() => {clickImage(item.id,item.url);}}/>
                   ))}
                   <div style={{height:"60px", textAlign: "center"}}>
                         <MediaLibraryBtnLoadMore onClick={() => searchMore()}>Cargar Más</MediaLibraryBtnLoadMore>
                   </div>
              </MediaLibraryBodyPanel>
              <div style={{height:"60px", textAlign: "right", marginTop:"10px"}}>
                    <MediaLibraryBtnLoadMore style={{marginRight:"5px"}} onClick={() => setDataOpen(currentOptions => ({...currentOptions, open:false }))}>Cancelar</MediaLibraryBtnLoadMore>
                    <MediaLibraryBtn onClick={() => setImageSelected()}>Seleccionar</MediaLibraryBtn>
              </div></>
             }
             { tab == 1 && <>
             <div style={{height:"60px", marginTop:"10px"}}>
                    <div>
                        <Input name="name" label="Nombre" value={nImage['name']} onChange={(e) => onInputChangeNImage(e.target.name,e.target.value)}/>
                        <Input name="category" label="Categoría" value={nImage['category']} onChange={(e) => onInputChangeNImage(e.target.name,e.target.value)}/>
                        <Label>Imagen</Label>
                        <div style={{maxWidth:"150px"}}>
                            <ImageUploader   onChange={(data) => {
                                props.uploadFileRequest({file: data}).then((response) =>
                                    onInputChangeNImage( "url", response)
                                );
                              }}
                              label={"Imagen"}
                            />
                        </div>
                    </div>
                    <div style={{height:"60px", textAlign: "right", marginTop:"10px"}}>
                          <MediaLibraryBtnLoadMore style={{marginRight:"5px"}} onClick={() => setDataOpen(currentOptions => ({...currentOptions, open:false }))}>Cancelar</MediaLibraryBtnLoadMore>
                          <MediaLibraryBtn onClick={() => uploadMedia()}>Subir</MediaLibraryBtn>
                    </div>
             </div></>
            }
            { tab == 2 && <>
            <div style={{height:"60px", marginTop:"10px"}}>
                   <div>
                       <Input name="name" label="Nombre" value={nImage['name']} onChange={(e) => onInputChangeNImage(e.target.name,e.target.value)}/>
                       <Input name="category" label="Categoría" value={nImage['category']} onChange={(e) => onInputChangeNImage(e.target.name,e.target.value)}/>
                       <Label>Gift</Label>
                       <div style={{maxWidth:"300px"}}>
                                   <div style={{ marginTop: 6 }}>
                                     <label className="custom-file-upload">
                                       <div
                                         style={{
                                           display: "flex",
                                           flexDirection: "row",
                                           justifyContent: "space-between",
                                           alignItems: "center",
                                         }}  >
                                         <Button
                                           onClick={(e) => {
                                             e.preventDefault();
                                             inputRef?.current?.click();
                                           }}
                                         >
                                           Seleccionar Gift
                                         </Button>
                                         {nImage["url"] &&  nImage["url"] != ""? (
                                           <div
                                             style={{
                                               display: "flex",
                                               flexDirection: "row",
                                               justifyContent: "flex-start",
                                               alignItems: "center",
                                             }}
                                           >
                                             <Button
                                               style={{
                                                 backgroundColor: "red",
                                                 marginLeft: 4,
                                                 height: 24,
                                                 width: 2,
                                                 marginRight: 6,
                                                 borderRadius: 4,
                                               }}
                                               onClick={(e) => {
                                                 e.target.value = null;
                                                 e.preventDefault();

                                                 inputRef.current.value = "";
                                                 onInputChangeNImage( "url", null)
                                               }}
                                             >
                                               X
                                             </Button>
                                             <i className="fa fa-cloud-upload">
                                               {inputRef?.current?.files &&
                                                 inputRef?.current?.files.length
                                                 ? imageOrName(inputRef?.current?.files[0])
                                                 : ""}
                                             </i>
                                           </div>
                                         ) : (
                                           <></>
                                         )}
                                       </div>
                                       <input
                                         ref={inputRef}
                                         type="file"
                                         style={{ width: 0, height: 0 }}
                                         accept={"*"}
                                         onChange={({ target }) => {
                                           var files = target.files;
                                           var filesArr = Array.prototype.slice.call(files);
                                           if (filesArr.length) {
                                             props.uploadFileRequest({file: filesArr[0]}).then((response) =>
                                                 onInputChangeNImage( "url", response)
                                             );
                                           } else {
                                             onInputChangeNImage( "url", null)
                                           }
                                         }}
                                       />
                                     </label>
                                   </div>
                           </div>
                           <div style={{height:"60px", textAlign: "right", marginTop:"10px"}}>
                                 <MediaLibraryBtnLoadMore style={{marginRight:"5px"}} onClick={() => setDataOpen(currentOptions => ({...currentOptions, open:false }))}>Cancelar</MediaLibraryBtnLoadMore>
                                 <MediaLibraryBtn onClick={() => uploadMedia()}>Subir</MediaLibraryBtn>
                           </div>
                    </div>
             </div></>
           }
          </MediaLibraryBody>
      </MediaLibraryBack> }
      </>

    )
};
