import HomeBuilder from "./HomeBuilder";

const headers = {
  type: "Page",
  options: {
    name: "Home",
    route: {
      to: "/home",
      path: "/home",
      unAuth: false,
      auth: true,
      exact: true,
      component: (props) => <HomeBuilder {...props} />,
    },
  },
};

export default headers;
