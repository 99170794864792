import { getRequests, findAllRequestOrderName } from "utils/requests";
import { convertToSlug } from 'utils/string-utils';

const requests = getRequests("categorynews");

const readCategories = (parents) => {
  var parent = '';
  debugger;
  if (parents && parents.length) {
    parents.forEach((element) => {
       parent = parent + (parent.length > 0? ", ":"") + element.name;
    });
  }

  return parent;
}

const headers = {
  type: "CRUD",
  options: {
    name: "Categorías Noticias",
    route: { path: "/categories" },
    requests,
    tableOptions: {
      isEditable: true,
      isDeletable: true,
      displayItemsPerPage: false,
      isSearchable: true,
    },
    upsertOptions: {
      onMount: () =>
        Promise.all([
          findAllRequestOrderName('categorynews', true)(),
        ]).then(
          ([
            { data: parents },
          ]) => ({
            parents
          }),
        ),
      itemBeforeSave: (data) => {
        let itemData = {
          ...data,
          slug: data.slug == null || data.slug == '' ? convertToSlug(data.name || ''): data.slug,
        };
        debugger;
          return Promise.resolve({
            ...itemData,
          })
      },
    },
  },
  sections: [
    {
      fields: [
        {
          label: "Nombre",
          property: "name",
          tableOptions: {
              isSortable: true,
          }
        },
        {
          label: "URL",
          property: "slug",
          tableOptions: {
              isSortable: true,
          }
        },
        {
          label: "Categoría padre",
          property: "parents",
          type: 'multiselect',
          relation: {
            name: 'parents',
            isMulti: true,
            sort: true,
            primaryKey: 'id',
            nameProps: ['name'],
          },
          tableOptions: {   
            show: true,
            format: (item, value) => readCategories(value),
          },
        },
      ],
    },
  ],
};

export default headers;