export const getObjectProps = ({ data, prop }, innerProperty) => {
  const isUndefined = prop === undefined;
  const innerProp = innerProperty || data;

  if (!isUndefined) {
    const cleanProp = prop.replace(/\[(\w+)\]/g, ".$1").replace(/^\./, "");
    const propLevels = cleanProp.split(".");
    const currentProp = propLevels[0];
    const currentData =
      innerProp && innerProp[currentProp] ? innerProp[currentProp] : undefined;

    const nextProp = propLevels.slice(1, propLevels.length);
    const isLastProp = nextProp.length === 0;

    const getData = isLastProp
      ? currentData
      : getObjectProps({ data, prop: nextProp.join(".") }, currentData);

    return innerProp && innerProp.hasOwnProperty(currentProp)
      ? getData
      : undefined;
  }

  return undefined;
};
